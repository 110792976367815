import React, { Component } from "react";
import CategoriesBlock from "../../blocks/CategoriesBlock";
import TopBrands from "../../blocks/TopBrands";
import GirlsCategories from "./GirlCategories";
import HomeCarousel from "./HomeCarousel";
import HomeCategories from "./HomeCategories";
import BigAds from "./BigAds";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import DailyDeal from "./DailyDeal";
import { Col, Container, Row } from "react-bootstrap";
import { homeService } from "../../../services/homeService";
import { generalServices } from "../../../services/GeneralService";
import { apiService } from "../../../services/ApiService";
import Skeleton from "react-loading-skeleton";
import { phoneScreen, tabletScreen } from "../../../utils/misc";

class Home extends Component {
  state = {
    brands: [],
    categories: [],
    daily_deals: [],
    recommended_products: [],
    sub_categories: [],
    trending_products: [],
    adsData: [],
    images: [],
    sliders: [],
    adsLoader: true,
    girlsCategories: [],
    categoriesWithProducts: [],
    categoriesLoader: true,
    sliderLoader: true,
    collection: {},
  };

  componentDidMount() {
    Promise.all([
      this.getSlider(),
      this.getHomeCategoriesWithProducts(),
      this.getHomeTDRDeals(1),
      this.getHomeTDRDeals(2),
      this.getHomeTDRDeals(3),
      this.getBrands(10),
      this.getHomeAds(),
      this.getCollections(0),
      this.getCategories(0, 0, 0, 0, 1),
      this.getCategories(0, 1, 0, 0, 1),
    ]);
  }

  getHomeTDRDeals = async (type) => {
    const { data, success } = await homeService.getHomeTDRDeals(type);

    let name =
      type === 1
        ? "trending_products"
        : type === 2
        ? "recommended_products"
        : "daily_deals";
    if (!success) return;
    this.setState({
      [name]: data,
    });
  };

  getCollections = async (position) => {
    const { data, success } = await homeService.getCollections(position);

    if (!success) return;
    console.log(data, "data");
    this.setState({
      collection: data,
    });
  };

  getCategories = async (parentId, isFeatured, topBar, showBrands, showSub) => {
    const { data, success } = await generalServices.getCategories(
      parentId,
      isFeatured,
      topBar,
      showBrands,
      showSub,
      this.props.country.id
    );

    if (!success) return;
    let name = isFeatured === 0 ? "categories" : "girlsCategories";

    this.setState({
      [name]: data,
      categoriesLoader: false,
      girlsCategoriesLoaded: true,
      // topCategory: data[0].sub_category,
      // topBrand: data[0].brands,
    });
  };

  getSlider = async () => {
    const { data, success } = await generalServices.getSlider(0);

    if (!success) return;
    this.setState({
      images: data.map(({ image }) => apiService.imageLink + image),
      sliders: data,
      sliderLoader: false,
    });
  };

  getHomeCategoriesWithProducts = async () => {
    const { data, success } = await homeService.getHomeCategoriesWithProducts();

    if (!success) return;
    this.setState({
      categoriesWithProducts: data,
    });
  };

  getBrands = async (limit) => {
    const { data, success } = await generalServices.getBrands(limit);

    if (!success) return;
    this.setState({
      brands: data,
      brandLoaded: true,
    });
  };

  getHomeAds = async () => {
    const { data, success } = await generalServices.getHomeAds(
      this.props.country.id
    );

    if (!success) return;
    this.setState({
      adsData: data,
      adsLoader: false,
    });
  };
  render() {
    const {
      i18n: { TrendingProducts, RecommendedForYou },
    } = this.props;

    const {
      adsData,
      brands,
      categories,
      daily_deals,
      collection,
      sliderLoader,
      recommended_products,
      adsLoader,
      categoriesWithProducts,
      girlsCategories,
      trending_products,
      sliders,
      categoriesLoader
    } = this.state;

    return (
      <div className="home">
        <HomeCarousel sliders={sliders} sliderLoader={sliderLoader} />
        {categories && (
          <Container>
            <HomeCategories data={categories} categoriesLoader={categoriesLoader} />
          </Container>
        )}

        {/* <ads section start  */}
        <Container>
          <div className="Ads-section">
            {adsLoader ? (
              <Row className="g-3">
                <Col sm={4} xs={6}>
                  <Skeleton height={tabletScreen ? 110 : 200} />
                </Col>
                <Col sm={4} xs={6}>
                  <Skeleton height={tabletScreen ? 110 : 200} />
                </Col>
                <Col sm={4}>
                  <Skeleton height={tabletScreen ? 110 : 200} />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={3} md={4} sm={4} xs={6}>
                  {adsData?.home_under_categories_block_left_space
                    ?.ad_image && (
                    <div
                      onClick={() => {
                        if (
                          adsData?.home_under_categories_block_left_space
                            ?.can_click
                        ) {
                          this.visitAdsLink(
                            adsData?.home_under_categories_block_left_space
                              ?.link,
                            adsData?.home_under_categories_block_left_space?.id
                          );
                        }
                        console.log(
                          adsData?.home_under_categories_block_left_space
                            ?.can_click,
                          "adsData?.home_under_categories_block_left_space?.can_click"
                        );
                      }}
                    >
                      <img
                        src={
                          apiService.imageLink +
                          adsData?.home_under_categories_block_left_space
                            ?.ad_image
                        }
                        alt="l"
                      />
                    </div>
                  )}
                </Col>

                <Col lg={3} md={4} sm={4} xs={6}>
                  {adsData?.home_under_categories_block_center_space
                    ?.ad_image && (
                    <div
                      onClick={() => {
                        if (
                          adsData?.home_under_categories_block_center_space
                            ?.can_click
                        ) {
                          this.visitAdsLink(
                            adsData?.home_under_categories_block_center_space
                              ?.link,
                            adsData?.home_under_categories_block_center_space
                              ?.id
                          );
                        }
                      }}
                    >
                      <img
                        src={
                          apiService.imageLink +
                          adsData?.home_under_categories_block_center_space
                            ?.ad_image
                        }
                        alt="l"
                      />
                    </div>
                  )}
                </Col>
                {!phoneScreen && (
                  <>
                    <Col lg={3} md={4} sm={4} xs={6}>
                      {adsData?.home_under_categories_block_center_right_space
                        ?.ad_image && (
                        <div
                          onClick={() => {
                            if (
                              adsData
                                ?.home_under_categories_block_center_right_space
                                ?.can_click
                            ) {
                              this.visitAdsLink(
                                adsData
                                  ?.home_under_categories_block_center_right_space
                                  ?.link,
                                adsData
                                  ?.home_under_categories_block_center_right_space
                                  ?.id
                              );
                            }
                          }}
                        >
                          <img
                            src={
                              apiService.imageLink +
                              adsData
                                ?.home_under_categories_block_center_right_space
                                ?.ad_image
                            }
                            alt="l"
                          />
                        </div>
                      )}
                    </Col>

                    <Col lg={3} md={4} sm={4} xs={12}>
                      {adsData?.home_under_categories_block_right_space
                        ?.ad_image && (
                        <div
                          onClick={() => {
                            if (
                              adsData?.home_under_categories_block_right_space
                                ?.can_click
                            ) {
                              this.visitAdsLink(
                                adsData?.home_under_categories_block_right_space
                                  ?.link,
                                adsData?.home_under_categories_block_right_space
                                  ?.id
                              );
                            }
                          }}
                        >
                          <img
                            src={
                              apiService.imageLink +
                              adsData?.home_under_categories_block_right_space
                                ?.ad_image
                            }
                            alt="l"
                          />
                        </div>
                      )}
                    </Col>
                  </>
                )}
              </Row>
            )}
          </div>
        </Container>
        {/* <ads section end  */}
        {/* recommended_products start  */}
        {recommended_products.length > 0 && (
          <CategoriesBlock
            title={RecommendedForYou}
            data={recommended_products.filter(({ option }) => option.qty > 0)}
            reFetchData={() => this.getHomeTDRDeals(2)}
            type={2}
          />
        )}

        {/* recommended_products end  */}

        {/* collection 1 start  */}
        {collection?.under_recommendedforyou?.length > 0 &&
          collection?.under_recommendedforyou?.map(
            ({ name, products, machine_name }) => {
              return (
                <CategoriesBlock
                  title={name}
                  data={products}
                  reFetchData={() => this.getCollections(0)}
                  type={5}
                  machine_name={machine_name}
                />
              );
            }
          )}
        {/* collection 1 end  */}

        <GirlsCategories
          data={girlsCategories}
          girlsCategoriesLoaded={this.state.girlsCategoriesLoaded}
        />

        {/* trending_products start  */}
        {trending_products.length > 0 && (
          <CategoriesBlock
            title={TrendingProducts}
            data={trending_products.filter(({ option }) => option.qty > 0)}
            reFetchData={() => this.getHomeTDRDeals(1)}
            type={1}
          />
        )}
        {/* trending_products end  */}
        {/* collection 2 start  */}

        {collection?.under_trending?.length > 0 &&
          collection?.under_trending?.map(
            ({ name, products, machine_name }) => {
              return (
                <CategoriesBlock
                  title={name}
                  data={products}
                  reFetchData={() => this.getCollections(0)}
                  type={5}
                  machine_name={machine_name}
                />
              );
            }
          )}
        {/* collection 2 end  */}

        {adsData?.home_under_tranding_block_full_space?.ad_image && (
          <BigAds
            data={adsData?.home_under_tranding_block_full_space}
            loader={adsLoader}
          />
        )}

        {/* top brands start  */}
        <TopBrands data={brands} brandLoaded={this.state.brandLoaded} />
        {/* top brands end  */}

        {/* daily deal start  */}
        {daily_deals.length > 0 && (
          <DailyDeal
            data={daily_deals}
            reFetchData={() => this.getHomeTDRDeals(3)}
          />
        )}
        {/* daily deal end  */}

        {/* collection 3 start  */}
        {collection?.under_dailydeal?.length > 0 &&
          collection?.under_dailydeal?.map(
            ({ name, products, machine_name }) => {
              return (
                <CategoriesBlock
                  title={name}
                  data={products}
                  reFetchData={() => this.getCollections(0)}
                  type={5}
                  machine_name={machine_name}
                />
              );
            }
          )}
        {/* collection 3 end  */}

        {/* categories product start  */}
        {categoriesWithProducts
          .filter(({ products }) => products.length > 0)
          .map((item) => {
            return (
              <CategoriesBlock
                title={item.name}
                data={item.products}
                reFetchData={() => this.getHomeCategoriesWithProducts()}
                machine_name={item.machine_name}
              />
            );
          })}
        {/* categories product end  */}
      </div>
    );
  }

  visitAdsLink = async (url, id) => {
    const { data, success } = await homeService.visitAdsLink(id);
    if (!success) return;
    window.open(url, "target");
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});

export default connect(
  mapStateToProps,
  { setLanguage }
)(Home);
