import React, { Component } from "react";
import { Accordion, Col, InputGroup, Modal, Row } from "react-bootstrap";
import TextInput from "../../blocks/TextInput";
import SelectInput from "../../blocks/SelectInput";
import LocationPicker from "react-location-picker";
import { connect } from "react-redux";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { locationService } from "../../../services/LocationService";
import {
    displayAlert,
    getResponseErrors,
    mapSelectData,
} from "../../../utils/misc";
import Global from "../../../language/Global.json";
import { generalServices } from "../../../services/GeneralService";
import { authService } from "../../../services/AuthService";
import { apiService } from "../../../services/ApiService";
import Label from "../../blocks/Label";
import Button from "@restart/ui/esm/Button";
import ReactCodeInput from "react-verification-code-input";

class GuestAddLocation extends Component {
    state = {
        addAddress: this.props.show,
        /* Default position */
        defaultPosition: {
            lat: 31.963158,
            lng: 35.930359,
        },
        position: {
            lat: 31.963158,
            lng: 35.930359,
        },
        fields: {
            name: "",
            address: "Al-Urdon St. 227, Amman, Jordan",
            building: "",
            phone: "",
            floor: "",
            apartment: "",
            password_confirmation: "",
            password: "",
        },
        errors: {
            name: "",
            address: "",
            building: "",
            phone: "",
            floor: "",
            apartment: "",
            password_confirmation: "",
            password: "",
        },

        userAddressesData: [],
        places: [],
        loader: true,
        areas_list: [],
        placesArray: [],
        AddLocation: false,
        CreatAccount: false,

        otpIsOpen: false,
        otpEnteredByUser: "",
        phoneNumberVerified: false,
        errVerificationCode: false,
        showSelect: true,
        placesIds: [],
    };
    componentDidMount() {
        // this.getPlaces(`places${0}`, this.props.country.id);
        this.getPlacesFunction(this.props.country.id, this.state.placesArray);
    }
    handleLocationChange = ({ position, address }) => {
        // Set new location
        this.setState({ position, fields: { ...this.state.fields, address } });
    };
    get guestId() {
        return localStorage.getItem("guestId");
    }
    getPlacesFunction = async (id, placesArrayParameter) => {
        const { data, success } = await generalServices.getAllCountries(id);
        if (!success) return;
        if (data?.length > 0 && !this.state.showSelect) {
            this.setState({
                placesArray: [
                    mapSelectData(data)?.map((item, index) => ({
                        value: item.id,
                        label: item.name,
                    })),
                ],
            });
        }
        if (data?.length > 0 && this.state.showSelect) {
            this.setState({
                placesArray: [
                    ...placesArrayParameter,
                    data?.map((item, index) => ({
                        value: item.id,
                        label: item.name,
                    })),
                ],
            });
        }
    };
    render() {
        const handleClose = () => {};
        const {
            language,
            i18n: {
                Email,
                CreateAnAccount,
                PlacesText,
                Password,
                ConfirmPassword,
                Name,
                BuildingNumber,
                FloorNumber,
                ApartmentNumber,
                DetailedDescription,
                City,
                phone,
                AddLocationButt,
                codeSent,
                verifyCode,
                verify,
            },
        } = this.props;
        const {
            fields,
            defaultPosition,
            errors,
            placesArray,
            AddLocation,
            CreatAccount,
            showSelect,
            placesIds,
        } = this.state;
        return (
            <div
                className={` ${language === "rtl" && "accordion-body-Arabic"}`}
            >
                {this.state.otpIsOpen && (
                    <Modal show={true} dir="ltr">
                        <Modal.Header style={{ flexDirection: "column" }}>
                            <Modal.Title>{verifyCode}</Modal.Title>
                            <p>
                                {codeSent}
                                {"  "}
                                <b>{fields.phone}</b>
                            </p>
                        </Modal.Header>
                        <Modal.Body className="d-flex justify-content-center">
                            <ReactCodeInput
                                fields={4}
                                onChange={(value) => {
                                    this.setState({
                                        otpEnteredByUser: `${value}`,
                                    });
                                }}
                                autoFocus={true}
                                value={this.state.otpEnteredByUser}
                                onComplete={(value) => {
                                    this.setState({
                                        otpEnteredByUser: "",
                                    });
                                }}
                            />
                            {this.state.errVerificationCode && (
                                <p style={{ color: "red" }}>Error</p>
                            )}
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <Button
                                variant="primary"
                                onClick={() => {
                                    this.setState({
                                        otpEnteredByUser: "",
                                    });
                                    this.checkUserPhone();
                                }}
                                className="verify-btn"
                            >
                                {verify}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
                <Accordion.Body>
                    <Row>
                        <Col lg={4} md={6} sm={6} xs={12} className="mb-2">
                            <TextInput
                                name="name"
                                label={Name}
                                maxLength={12}
                                placeholder={Name}
                                value={fields.name}
                                onFieldChange={this.onFieldChange}
                                validate={errors.name}
                            />
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12} className="mt-px">
                            <TextInput
                                name="email"
                                placeholder={Email}
                                label={Email}
                                type="email"
                                value={fields.email}
                                validate={errors.email}
                                onFieldChange={this.onFieldChange}
                            />
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12} className="mb-4">
                            <div
                                className={`text-input-block phone-input ${
                                    language === "rtl" && "phone-input-arabic"
                                }`}
                            >
                                <Label
                                    label={phone}
                                    tooltip={""}
                                    isRequired={false}
                                />
                                <div className="phone-input__content">
                                    <div className="phone-input__content__country--code">
                                        {this.props.country.zipcode ?? "+962"}
                                    </div>
                                    <InputGroup className="phone-input__content__container">
                                        <input
                                            className="phone-input__content__container__field"
                                            type="number"
                                            placeholder={phone}
                                            name="phone"
                                            onInput={({ target }) =>
                                                (target.value = Math.max(
                                                    0,
                                                    parseInt(target.value)
                                                )
                                                    .toString()
                                                    .slice(
                                                        0,
                                                        this.props.country
                                                            .digits
                                                    ))
                                            }
                                            onChange={({ target: { value } }) =>
                                                this.setState({
                                                    fields: {
                                                        ...fields,
                                                        phone: value,
                                                    },
                                                })
                                            }
                                            value={fields.phone}
                                            validate={errors.phone}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12} className="mb-2">
                            <TextInput
                                name="building"
                                type="number"
                                label={BuildingNumber}
                                placeholder={BuildingNumber}
                                value={fields.building}
                                onFieldChange={this.onFieldChange}
                                validate={errors.building}
                            />
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12} className="mb-2">
                            <TextInput
                                name="floor"
                                type="number"
                                label={FloorNumber}
                                placeholder={FloorNumber}
                                value={fields.floor}
                                onFieldChange={this.onFieldChange}
                                validate={errors.floor}
                            />
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={12} className="mb-2">
                            <TextInput
                                name="apartment"
                                type="number"
                                label={ApartmentNumber}
                                placeholder={ApartmentNumber}
                                value={fields.apartment}
                                onFieldChange={this.onFieldChange}
                                validate={errors.apartment}
                            />
                        </Col>

                        <Col lg={12} md={12} sm={12} className="mb-2">
                            <TextInput
                                name="address"
                                label={DetailedDescription}
                                placeholder={DetailedDescription}
                                value={fields.address}
                                onFieldChange={this.onFieldChange}
                                validate={errors.address}
                            />
                        </Col>
                    </Row>
                    {placesArray > 0 ||
                        (showSelect &&
                            placesArray.map((item, index) => {
                                return (
                                    <Col sm={12} className="mb-4">
                                        <SelectInput
                                            name={
                                                item.filter(
                                                    ({ value }) =>
                                                        value ==
                                                        placesIds[index]
                                                ).value
                                            }
                                            label={
                                                index === 0 ? PlacesText : " "
                                            }
                                            placeholder={City}
                                            value={
                                                item.filter(
                                                    ({ value }) =>
                                                        value ==
                                                        placesIds[index]
                                                ).value
                                            }
                                            onFieldChange={(name, value) =>
                                                this.selectChange(value, index)
                                            }
                                            data={item}
                                            validate={
                                                index ===
                                                    placesArray.length - 1 &&
                                                errors.place_id
                                            }
                                            // isTextArea
                                        />
                                    </Col>
                                );
                            }))}
                    <Row className="create-account">
                        <label
                            className={
                                CreatAccount
                                    ? "create-account__container create-account__active"
                                    : "create-account__container "
                            }
                        >
                            <p> {CreateAnAccount}</p>
                            <input
                                type="checkbox"
                                onClick={() =>
                                    this.setState({
                                        CreatAccount: !CreatAccount,
                                    })
                                }
                                className="create-account__container__input"
                            />
                            <span className="create-account__checkmark"></span>{" "}
                        </label>
                    </Row>
                    {CreatAccount ? (
                        <>
                            <Row>
                                <Col sm={6} xs={12} className="mt-px">
                                    <TextInput
                                        name="password"
                                        placeholder={Password}
                                        withImg
                                        label={Password}
                                        type="password"
                                        value={fields.password}
                                        validate={errors.password}
                                        onFieldChange={this.onFieldChange}
                                    />
                                </Col>

                                <Col sm={6} xs={12} className="mt-px">
                                    <TextInput
                                        name="password_confirmation"
                                        placeholder={ConfirmPassword}
                                        withImg
                                        label={ConfirmPassword}
                                        type="password"
                                        value={fields.password_confirmation}
                                        validate={errors.password_confirmation}
                                        onFieldChange={this.onFieldChange}
                                    />
                                </Col>
                            </Row>

                            {/* <Row>
                               <Col className="mt-px">
                                   <BasicSelectInput placeholder={Cities} label={Cities} options={cities} name="selectedCity" value={fields.selectedCity} validate={errors.selectedCity} onFieldChange={this.onFieldChange} />
                               </Col>
   
                               <Col className="mt-px">
                                   <BasicSelectInput placeholder={Area} label={Area} options={areas} />
                               </Col>
                           </Row> */}
                        </>
                    ) : null}
                    <LocationPicker
                        containerElement={<div style={{ height: "100%" }} />}
                        mapElement={<div style={{ height: "400px" }} />}
                        onChange={this.handleLocationChange}
                        defaultPosition={defaultPosition}
                        circleOptions={{ visible: false }}
                    />

                    {/** Add Or Cancel Location */}
                    <Row>
                        <div className="AddLocationButt">
                            <div>
                                <a
                                    onClick={() =>
                                        this.setState({
                                            AddLocation: !AddLocation,
                                        })
                                    }
                                >
                                    {" "}
                                    {/* Cancel */}
                                </a>
                            </div>

                            <div className="Add">
                                <a
                                    onClick={() => {
                                        // this.setState({
                                        //     otpIsOpen: true,
                                        // });
                                        this.addUserLocation();
                                    }}
                                >
                                    {AddLocationButt}
                                </a>
                            </div>
                        </div>
                    </Row>
                    {/** Add Or Cancel Location */}
                </Accordion.Body>
            </div>
        );
    }

    // selectChange = (name, value, index) => {
    //     const { fields } = this.state;
    //     if (fields[name]) return;

    //     this.setState({
    //         fields: { ...fields, [name]: value },
    //         errors: {
    //             place_id: "",
    //         },
    //     });
    //     this.getPlaces(`places${index}`, value.value);
    // };

    selectChange = (value, index) => {
        const { fields, placesArray, placesIds } = this.state;

        if (placesArray[index]) {
            let arr = placesArray.slice(0, index + 1);
            this.setState({
                placesArray: arr,
            });
            this.getPlacesFunction(value.value, arr);
        } else {
            this.getPlacesFunction(value.value, placesArray);
        }
        // setValue(fieldData.fieldName, target.value);

        if (placesIds[index]) {
            let arr = placesIds;
            arr[index] = value.value;
            this.setState({
                placesIds: arr,
            });
        } else {
            this.setState({
                placesIds: [...placesIds, value.value],
            });
        }

        this.setState({
            fields: { ...fields, place_id: value },
            errors: {
                place_id: "",
            },
        });
    };

    onFieldChange = (name, value) => {
        const { fields } = this.state;
        this.setState({
            fields: { ...fields, [name]: value },
            errors: {
                nickname: "",
                address: "",
                building: "",
                phone: "",
                floor: "",
                apartment: "",
            },
        });
    };

    checkUserPhone() {
        const { otpIsOpen } = this.state;
        const {
            phoneVerficationMessageSuccess,
            phoneVerficationMessageFaild,
            success,
            error,
        } = this.props.i18n;
        // get verefication code from API here ...

        // if (userCode === verificationCodeFromAPI)
        if (true) {
            this.setState({
                otpIsOpen: !otpIsOpen,
            });
            displayAlert(
                success,
                phoneVerficationMessageSuccess,
                "success"
            ).then(() => {
                this.addUserLocation();
            });
        } else {
            return displayAlert(error, phoneVerficationMessageFaild, "error");
        }
    }
    addUserLocation = async () => {
        const { fields, position, placesArray, CreatAccount } = this.state;
        const { country, changeAccordion } = this.props;

        const {
            error: errorTag,
            success: successText,
            FillAllFields,
            LocationAdded,
            LocationAddedSuccessfully,
        } = this.props.i18n;

        if (CreatAccount) {
            const {
                success: SignUpSuccess,
                data: SignUpData,
                message: SignUpMessage,
                errors: SignUpErrors,
            } = await authService.SignUp({
                password_confirmation: fields.password_confirmation,
                password: fields.password,
                place_id: country?.id,
                email: fields.email,
                phone: fields.phone,
                name: fields.name,
                guest_id: this.guestId,
            });
            if (!SignUpSuccess) {
                if (SignUpErrors) {
                    const handelErrors = getResponseErrors(SignUpErrors);
                    this.setState({
                        errors: handelErrors,
                    });
                    return;
                } else {
                    return displayAlert("Error", SignUpMessage, "error");
                }
            }

            apiService.storeToken(SignUpData.access_token);

            this.props.setUser({ ...SignUpData.user });

            /// sign up the guest end

            const { success, message, errors } =
                await locationService.addUserLocation({
                    nickname: fields.name,
                    address: fields.address,
                    latitude: position.lat,
                    longitude: position.lng,
                    building: fields.building,
                    phone: fields.phone,
                    floor: fields.floor,
                    apartment: fields.apartment,
                    // place_id:
                    //     fields[`placeID-${placesArray.length - 1}`]?.value,
                    place_id: fields.place_id.value,
                });
            if (!success) {
                if (errors) {
                    const handelErrors = getResponseErrors(errors);
                    this.setState({
                        errors: handelErrors,
                    });
                    return displayAlert(errorTag, FillAllFields, "error");
                } else {
                    return displayAlert("Error", message, "error");
                }
            }
            displayAlert(successText, LocationAdded, "success");
            this.props.reFetchData();
        } else {
            if (fields.name && fields.place_id) {
                this.setState({
                    guestLocation: {
                        name: fields.name,
                        address: fields.address,
                        latitude: position.lat,
                        longitude: position.lng,
                        building: fields.building,
                        phone: fields.phone,
                        floor: fields.floor,
                        email: fields.email,
                        apartment: fields.apartment,
                        // place_id:
                        //     fields[`placeID-${placesArray.length - 1}`]?.value,
                        place_id: fields.place_id.value,
                    },
                });
                displayAlert(
                    successText,
                    LocationAddedSuccessfully,
                    "success"
                ).then(() => {
                    changeAccordion(this.state.guestLocation);
                });
            } else {
                displayAlert(errorTag, FillAllFields, "error");
            }
        }
    };

    getPlaces = async (name, id) => {
        const { data, success } = await generalServices.getAllCountries(id);
        if (!success) return;

        data.length > 0 &&
            this.setState({
                placesArray: [
                    ...this.state.placesArray,
                    { [name]: mapSelectData(data) },
                ],
            });
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country,
});

export default connect(mapStateToProps, { setLanguage, setUser })(
    GuestAddLocation
);
