import React from "react";

const FloatingMessengerButton = () => {
    const handleButtonClick = () => {
        window.open('https://m.me/248423785186566', '_blank');
    };

    return (
        <button className="floating-messenger-button" onClick={handleButtonClick}>
            <img src="/images/messenger.png" alt="Messenger" />
        </button>
    );
};

export default FloatingMessengerButton;