import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../../utils/misc";
import { apiService } from "../../../services/ApiService";

class HomeCategories extends Component {
  state = {
    responsive: {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1200 },
        items: 8,
      },
      desktop: {
        breakpoint: { max: 1199, min: 992 },
        items: 6,
      },
      tablet: {
        breakpoint: { max: 991, min: 768 },
        items: 5,
      },
      largeMobile: {
        breakpoint: { max: 767, min: 425 },
        items: 4,
      },
      mobile: {
        breakpoint: { max: 424, min: 0 },
        items: 3,
      },
    },
  };

  render() {
    const { responsive } = this.state;
    const {
      language,
      data,
      categoriesLoader,
      i18n: { Categories, Items },
    } = this.props;
    return (
      <div
        className={`home-categories  ${language === "rtl" &&
          "home-categories-arabic"} p-0`}
      >
        {data.length > 0 && <h3 className="section-title">{Categories}</h3>}

        {categoriesLoader ? (
          <Row xs={4}>
            <Col>
              <Skeleton height={tabletScreen ? 75 : 150} />
            </Col>
            <Col>
              <Skeleton height={tabletScreen ? 75 : 150} />
            </Col>
            <Col>
              <Skeleton height={tabletScreen ? 75 : 150} />
            </Col>
            <Col>
              <Skeleton height={tabletScreen ? 75 : 150} />
            </Col>
          </Row>
        ) : (
          data.length > 0 && (
            <Carousel responsive={responsive} infinite>
              {/* single category start  */}
              {data.map(({ id, name, image, machine_name, items_count }) => {
                return (
                  <React.Fragment key={id}>
                    <a
                      href={
                        "/productsCategory/" + machine_name + "/1"

                        // machine_name.includes("%") ? `/productsCategory/${machine_name.replace("%", "%25")}/1` : "/productsCategory/" + machine_name + "/1"
                      }
                      className={`home-categories__single-item  ${language ===
                        "rtl" && "home-categories__single-item-arabic"}`}
                    >
                      <img src={apiService.imageLink + image} alt="l" />
                      {/* <img src="/images/lap.png" alt="l" /> */}

                      <div className="home-categories__single-item__title-items">
                        <p>{name}</p>
                        <span>
                          {items_count} {Items}
                        </span>
                      </div>
                    </a>
                  </React.Fragment>
                );
              })}
              {/* single category start  */}
            </Carousel>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
});

export default connect(
  mapStateToProps,
  { setLanguage }
)(HomeCategories);
