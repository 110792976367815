import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { cartService } from "../../../services/CartService";
import Skeleton from "react-loading-skeleton";
import Product from "../../blocks/Product";
import { homeService } from "../../../services/homeService";
import PaginatedItems from "../../Pages/Search/PaginationBloack";

class BrandProducts extends Component {
    state = {
        brandProducts: [],
        loaderPage: true,
        meta: {},
    };

    componentDidMount() {
        this.toTop();
        this.getSingleBrandProducts();
    }

    getSingleBrandProducts = async (page) => {
        const { data, success, meta } =
            await homeService.getSingleBrandProducts(
                this.props.match.params.page,
                { machine_name: this.props.match.params.machineName }
            );

        if (!success) return;
        this.setState({
            brandProducts: data,
            meta,

            loaderPage: false,
        });
    };

    render() {
        const { brandProducts, loaderPage, meta } = this.state;
        const {
            i18n: { Items, brandProductsEmpty },
        } = this.props;

        return (
            <div className="Cart">
                {/** Title */}
                <div className="CartTitle">
                    <Container>
                        <Row>
                            <Col>
                                <h2>
                                    {this.props.match.params.machineName}
                                    <h4>
                                        {brandProducts.length} {Items}
                                    </h4>
                                </h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/** Title End */}
                {/* Start */}
                <Container>
                    <Row>
                        {loaderPage ? (
                            <>
                                <Col md={3}>
                                    <Skeleton height={250} />
                                </Col>
                                <Col md={3}>
                                    <Skeleton height={250} />
                                </Col>
                                <Col md={3}>
                                    <Skeleton height={250} />
                                </Col>
                                <Col md={3}>
                                    <Skeleton height={250} />
                                </Col>
                            </>
                        ) : brandProducts.length === 0 ? (
                            <Col md={12}>
                                <div className="empty-products">
                                    <img src="/images/no_item.svg" alt="k" />
                                    <p> {brandProductsEmpty} </p>{" "}
                                </div>
                            </Col>
                        ) : (
                            brandProducts.map((item, index) => {
                                return (
                                    <Col lg={3} md={4} xs={6}>
                                        {/* <FavoriteProductBlock
                                            data={item}
                                            key={index}
                                            reFetchData={() =>
                                                this.getUserFavorites()
                                            }
                                        /> */}
                                        <Product
                                            data={item}
                                            reFetchData={() =>
                                                this.getSingleBrandProducts()
                                            }
                                        />
                                    </Col>
                                );
                            })
                        )}
                    </Row>

                    {/** Pagination Start */}
                    {meta.last_page > 1 ? (
                        <Row>
                            <Col md={12}>
                                <div className="Pagination">
                                    <PaginatedItems
                                        items={[...Array(meta?.total).keys()]}
                                        itemsPerPage={meta?.per_page}
                                        changePage={(page) =>
                                            this.changePage(page)
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    ) : null}

                    {/** Pagination End */}
                </Container>
                {/* End */}
            </div>
        );
    }

    toTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    changePage(page) {
        this.toTop();
        this.props.history.push(
            `/BrandProducts/${this.props.match.params.machineName}/${page + 1}`
        );
        // window.location.href = `/Search/${this.props.match.params.key}/${
        //     this.props.match.params.catId
        // }/${page + 1}`;
        // to={`/Search/${this.props.match.params.key}/${this.props.match.params.catId}/${index + 1}`}
        this.setState(
            {
                pageLoader: true,
            },
            () => {
                this.getSingleBrandProducts();
            }
        );
    }

    newPrice = (price, discount_percentage, fixed_amount) => {
        let discountAmount = (price * discount_percentage) / 100;
        let priceAfterDiscount = 0;
        if (discountAmount > fixed_amount) {
            priceAfterDiscount = price - fixed_amount;
            return priceAfterDiscount;
        } else {
            priceAfterDiscount = price - discountAmount;
            return priceAfterDiscount;
        }
    };

    changeQty = (qty, index) => {
        const { itemsCount } = this.state;
        const newItemCount = itemsCount;
        newItemCount[index] = qty;
        this.setState({
            itemsCount: newItemCount,
            qtyChangeIndex: index,
        });
    };
    totalItemsCount = () =>
        this.state.itemsCount.reduce((acc, currentValue) => {
            return currentValue + acc;
        }, 0);

    itemsPriceBeforeDiscount = () => {
        const itemsPriceBeforeDiscount = this.state.cart.reduce(
            (acc, currentValue, currentIndex) => {
                return (
                    currentValue?.productOption?.price *
                        this.state.itemsCount[currentIndex] +
                    acc
                );
            },
            0
        );
        return itemsPriceBeforeDiscount;
    };

    itemsPriceAfterDiscount = () => {
        const itemsPriceBeforeDiscount = this.state.cart.reduce(
            (acc, currentValue, currentIndex) => {
                return (
                    this.newPrice(
                        currentValue?.productOption?.price,
                        currentValue?.product_details?.campaign
                            .discount_percentage,
                        currentValue?.product_details?.campaign.fixed_amount
                    ) *
                        this.state.itemsCount[currentIndex] +
                    acc
                );
            },
            0
        );

        return itemsPriceBeforeDiscount;
    };

    discountAmount = () => {
        return this.itemsPriceBeforeDiscount() - this.itemsPriceAfterDiscount();
    };
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});

export default connect(mapStateToProps, { setLanguage })(BrandProducts);
