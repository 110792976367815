import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService";

class AuthService {
  async login(userData) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.login, userData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
  async socialLogin(userData) {
    try {
      {console.log('userData', userData)}
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.socialLogin, userData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
  async guestLogin(guestData) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.guestLogin, guestData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  async SignUp(userData) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.signUp, userData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  logout() {
    window.localStorage.clear();
    window.location.href = "/login";
  }

  async forgetPassword(email) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.resetPassword, email)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const authService = new AuthService();
