import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import AccountPageList from "../../blocks/AccountPageList";
import AccountPageMobileList from "../../blocks/AccountPageMobileList";
import Invoices from "../AccountPage/Invoices";
import { userService } from "../../../services/UserService";
import { apiService } from "../../../services/ApiService";

class InvoicePage extends Component {
    state = {
        remindMe: false,
        orderDetails: {},
        pageLoader: true,
    };

    componentDidMount() {
        this.getOrderInvoice();
    }

    getOrderInvoice = async () => {
        const { data, success } = await userService.getOrderInvoice(this.props.match?.params.id);

        if (!success) return;
        this.setState({
            orderDetails: data,
            pageLoader: false,
        });
    };

    render() {
        const {
            language,
            i18n: { SignOut },
        } = this.props;
        const { orderDetails, pageLoader } = this.state;
        return (
            <Container>
                <div className={`account-page ${language === "rtl" && "account-page-arabic"}`}>
                    {/* Mobile Side Menu Start */}
                    <AccountPageMobileList />
                    {/*Mobile Side Menu End */}
                    {/*Menu Start */}
                    <Row>
                        <Col className="board" md={3}>
                            {apiService.accessToken !== 0 && (
                                <div>
                                    <h3>
                                        <h3>{this.props.currentUser.name}</h3>
                                    </h3>
                                    <a
                                        href="/login"
                                        className="board__signOut"
                                        onClick={() => {
                                            localStorage.removeItem("TZShopAccessToken");
                                            localStorage.removeItem("persist:RZ");
                                        }}
                                    >
                                        {SignOut}
                                    </a>
                                </div>
                            )}

                            <AccountPageList />
                        </Col>
                        <Col md={9}>{!pageLoader && <Invoices data={orderDetails} />}</Col>
                    </Row>
                    {/*Menu End */}
                </div>
            </Container>
        );
    }
    closeMenu() {
        this.setState({ menuOpen: false });
    }
    toggleMenu() {
        this.setState((state) => ({ menuOpen: !state.menuOpen }));
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(InvoicePage);
