import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-elastic-carousel";
// import Product from "./Product";
import { connect } from "react-redux";
import Global from "../../language/Global.json";
import { setLanguage } from "../../redux/actions-reducers/language";
import { apiService } from "../../services/ApiService";
import Skeleton from "react-loading-skeleton";
import { phoneScreen, tabletScreen } from "../../utils/misc";
import { Link } from "react-router-dom";
// import Brands from "../Pages/Brands/Brands";

class TopBrands extends Component {
    constructor(props) {
        super(props);
        this.goto = this.goto.bind(this);
    }
    goto() {
        this.carousel.goTo(Number(0));
    }

    state = {
        breakPoints: [
            // { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
            // { width: 992, itemsToShow: 5 },
            { width: 1, itemsToShow: 2, showArrows: false, pagination: true },
            { width: 425, itemsToShow: 4, showArrows: false, pagination: true },
            { width: 576, itemsToShow: 5 },
            { width: 768, itemsToShow: 5 },
            { width: 992, itemsToShow: 6 },
            { width: 1200, itemsToShow: 6 },
        ],
        initialActiveIndex: 0,
    };

    render() {
        // const onNextStart = (currentItem, nextItem) => {
        //     if (currentItem.index === nextItem.index) {
        //         // we hit the last item, go to first item
        //         this.carousel.goTo(Number(0));
        //     }
        // };

        // const onPrevStart = (currentItem, nextItem) => {
        //     if (currentItem.index === nextItem.index) {
        //         // we hit the first item, go to last item
        //         this.carousel.goTo(Number(9));
        //     }
        // };
        // onPrevStart={onPrevStart} onNextStart={onNextStart}

        const {
            language,
            data,
            brandLoaded,
            i18n: { VIEWALL, TopBrands },
        } = this.props;
        // const { initialActiveIndex } = this.state;

        if (!brandLoaded) {
            return (
                <div className="top-brands">
                    <Container>
                        <Row>
                            <Col>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        } else if (data?.length > 0) {
            return (
                <div className="top-brands">
                    <Container>
                        <div className={`categories-block   ${language === "rtl" && "categories-block-arabic"}`}>
                            <div className="categories-block__header">
                                <h3>{TopBrands}</h3>
                                <div className="hr" />
                                <Link to="/brands" className="view-all">
                                    <span>{VIEWALL}</span>
                                    <img src="/images/arrow-small-right.svg" alt="l" />
                                </Link>
                            </div>
                            {data?.length < 4 && !phoneScreen ? (
                                <Row className="no-margin p-1">
                                    {data?.map(({ id, logo }) => {
                                        return (
                                            <Col key={id} lg={2} md={3} xs={4}>
                                                <div className="item-brand" key={id}>
                                                    <img src={apiService.imageLink + logo} alt="l" />
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            ) : (
                                <Carousel itemsToShow={6} pagination={false} ref={(ref) => (this.carousel = ref)} disableArrowsOnEnd={true} breakPoints={this.state.breakPoints} isRTL={language === "rtl" && true}>
                                    {data?.map(({ id, logo, machine_name }) => {
                                        return (
                                            <Link to={`/BrandProducts/${machine_name}/1`} className="item-brand" key={id}>
                                                <img src={apiService.imageLink + logo} alt="l" />
                                            </Link>
                                        );
                                    })}
                                </Carousel>
                            )}
                        </div>
                    </Container>
                </div>
            );
        } else {
            return null;
        }

    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(TopBrands);
