import React, { Component } from "react";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setUser } from "../../../redux/actions-reducers/user";
import { Row, Col, Form } from "react-bootstrap";
import { productService } from "../../../services/ProductService";
import { apiService } from "../../../services/ApiService";
import { generalServices } from "../../../services/GeneralService";
import { Link } from "react-router-dom";

class CartProductBlock extends Component {
    render() {
        const {
            language,
            data,
            i18n: { Remove, moveToWishList },
            reFetchData,
            currentUser,
        } = this.props;

        return (
            <div className={`product  ${language === "rtl" && "product-arabic"}`}>
                <Row className="CartRow">
                    <Col lg={3} xl={3} md={3} xs={3}>
                        {/* product img start  */}
                        <a href={`/productDetails/ + ${data?.product.machine_name}`}>
                            <img src={apiService.imageLink + data.product?.main_image} alt="k" className="product__img" />
                        </a>
                        {/* product img end  */}
                    </Col>

                    <Col lg={6} xl={6} md={6} xs={9}>
                        <div className="media-body">
                            <a href={`/productDetails/ + ${data?.product.machine_name}`}>
                                <h5 className="mt-0 product__title">{data.product?.title}</h5>
                            </a>
                            <h6> {data.product_option?.values_names.join(",")} </h6>
                            <br />
                            <div className="CartAction flex">
                                {currentUser.favoritesId.includes(data?.id) ? null : (
                                    <div onClick={() => this.addRemoveFavorites(data?.id, reFetchData)}>
                                        {/* <i className={currentUser.favoritesId.includes(data?.id) ? "fa fa-heart" : "fa fa-heart-o"} /> */}
                                        <img src={`/images/${currentUser.favoritesId.includes(data?.id) ? "heartColor" : "heart"}.svg`} alt="ss" />
                                        &nbsp;
                                        {moveToWishList}
                                    </div>
                                )}

                                <div
                                    onClick={() => {
                                        this.addToCart(data?.product_option?.id, 0, reFetchData);
                                    }}
                                >
                                    <img alt="Delete" src="/images/fi-rr-trash.svg" />
                                    &nbsp; {Remove}
                                </div>
                            </div>
                        </div>
                    </Col>
                    {/* product footer start  */}
                    <Col lg={3} xl={3} md={3} xs={12}>
                        <div className="cart-product-footer">
                            <div className="product__price">
                                <div className="product__price__new-price">
                                    <span>
                                        <sup>{this.props.country.currency}</sup> {data?.product_option.price_after_discount}
                                    </span>
                                </div>
                            </div>
                            <div className={`product__quantity  ${language === "rtl" && "product__quantity__ar"}`}>
                                <Form.Group controlId="formGridState">
                                    <Form.Select
                                        value={+data?.qty}
                                        onChange={({ target: { value } }) => {
                                            // changeQty(+value);
                                            this.addToCart(data?.product_option?.id, +value, reFetchData);
                                        }}
                                    >
                                        {/* {Array.from(Array(data?.product_option?.qty < 10 ? data?.product_option?.qty : 10).keys())?.map((item, index) => { */}
                                        {Array.from(Array(data?.product_option?.qty).keys())?.map((item, index) => {
                                            return <option value={index + 1}>{index + 1}</option>;
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                    {/* product footer end  */}
                </Row>
            </div>
        );
    }

    getIconData = async () => {
        const { data, success } = await generalServices.getIconData();
        if (!success) return;

        this.props.setUser({
            cart: data.cart_counter,
            fav: data.fav_counter,
            compare: data.compare_counter,
        });
    };
    getFavoritesIDs = async () => {
        const { data, success } = await generalServices.getFavoritesIDs();
        if (!success) return;

        this.props.setUser({
            favoritesId: data,
        });
    };
    getCartIDs = async () => {
        const { data, success } = await generalServices.getCartIDs();
        if (!success) return;
        this.props.setUser({
            cartIds: data,
        });
    };
    addRemoveFavorites = async (id, reFetchData) => {
        const { success } = await productService.addOrRemoveFavorite(id);
        if (!success) return;
        // this.setState({ isFav: !this.state.isFav });
        // this.getFavoritesIDs();
        this.addToCart(this.props.data?.product_option?.id, 0, reFetchData);
        // reFetchData();
        // this.getIconData();
    };
    addToCart = async (id, qty, reFetchData) => {
        const { success } = await productService.addToCart(id, qty);
        if (!success) return;

        await reFetchData();
        this.getFavoritesIDs();
        this.getCartIDs();

        this.getIconData();
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country,
});
export default connect(mapStateToProps, { setUser })(CartProductBlock);
