import React, { Component } from "react";
import { connect } from "react-redux";
import { generalServices } from "../../../services/GeneralService";
import { productService } from "../../../services/ProductService";
import { displayAlert } from "../../../utils/misc";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";

class ProductCompare extends Component {
    state = {
        isFav: this.props.product?.in_favorite,
        isCompare: this.props.product?.compare,
        in_cart: this.props.product?.in_cart,
    };

    render() {
        const { i, product, addRemoveCompares } = this.props;
        const { isFav } = this.state;
        return (
            <>
                <th key={i} className="btn-table-header">
                    <div className="compare-product">
                        <header className="compare-product__header flex">
                            <div
                                className="compare-product__fav"
                                onClick={() => {
                                    this.addRemoveFavorites(product?.id);
                                }}
                            >
                                {/* <i
                                    className={
                                        isFav ? "fa fa-heart" : "fa fa-heart-o"
                                    }
                                /> */}
                                {isFav ? <img src="/images/heartColor.svg" alt="heart" /> : <img src="/images/heart.svg" alt="heart" />}
                            </div>

                            <div className="compare-product__compare" onClick={() => addRemoveCompares(product?.id)}>
                                <img src={`/images/newCompC.svg`} alt="ss" />
                            </div>
                        </header>
                        {/* product img start  */}

                        <div className="compare-product__img--container">
                            <img
                                src={product.image}
                                alt="product img"
                                className="product-image"
                                id={product.image}
                                onClick={() => {
                                    window.location.href = "/ProductDetails/" + product.machine_name;
                                }}
                            />
                        </div>
                        {/* product img end  */}
                        {/* product name start  */}

                        <h6
                            onClick={() => {
                                window.location.href = "/ProductDetails/" + product.machine_name;
                            }}
                        >
                            {product.Name}
                        </h6>
                        {/* product name end  */}

                        <p>{product.subtitle}</p>
                        <button
                            className="compare-product-button flex"
                            onClick={() => {
                                this.addToCart(product?.option.id, product.in_cart ? 0 : 1);
                            }}
                        >
                            <img src="/images/cartIcon.svg" alt="l" />

                            <span> {this.state.in_cart ? this.props.i18n.DeleteFromCart : this.props.i18n.AddToCart}</span>
                        </button>
                    </div>
                </th>
            </>
        );
    }

    addRemoveFavorites = async (id, reFetchData) => {
        const { success } = await productService.addOrRemoveFavorite(id);
        if (!success) return;
        this.setState({ isFav: !this.state.isFav });

        this.getIconData();
    };

    getIconData = async () => {
        const { data, success } = await generalServices.getIconData();
        if (!success) return;

        this.props.setUser({
            cart: data.cart_counter,
            fav: data.fav_counter,
            compare: data.compare_counter,
        });
    };
    addToCart = async (id, qty) => {
        const { success } = await productService.addToCart(id, qty);
        const { DeletedFromCart, AddedToCart, success: successText } = this.props.i18n;
        if (!success) return;

        displayAlert(successText, this.state.in_cart ? DeletedFromCart : AddedToCart, "success");
        this.setState({ in_cart: !this.state.in_cart });

        this.getIconData();
        this.props.reFetchData();
    };
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage, setUser })(ProductCompare);
