import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../language/Global.json";
import { setUser } from "../../redux/actions-reducers/user";
import { apiService } from "../../services/ApiService";
import { generalServices } from "../../services/GeneralService";
import { productService } from "../../services/ProductService";
import { displayAlert } from "../../utils/misc";
class HorizontalProduct extends Component {
    state = {
        isFav: this.props.data?.in_favorite,
        compare: this.props.data?.compare,
    };

    render() {
        const {
            data,
            reFetchData,
            language,
            i18n: { outOfStuck, SoldOut },
        } = this.props;
        const { isFav } = this.state;
        return (
            <div className={`product-horizontal  ${language === "rtl" && "product-horizontal-arabic"}`}>
                {data.option.qty == 0 && <span className={`${"product-label product-label-soldOut"}`}>{SoldOut}</span>}
                {/* {data.label && data.option.qty > 0 && ( */}
                <span className={`${"product-label product-horizontal-label"}`}>
                    {/* {data.label.label} */}
                    Big Deal
                </span>
                {/* )} */}

                <div className="product-horizontal-fav" onClick={() => this.addRemoveFavorites(data?.id, reFetchData)}>
                    {/* <i className={isFav ? "fa fa-heart" : "fa fa-heart-o"}></i> */}
                    {isFav ? <img src="/images/heartColor.svg" alt="heart" /> : <img src="/images/heart.svg" alt="heart" />}
                </div>
                <Row className="m-0">
                    <Col xs={7} className="p-0">
                        {/* product body start  */}
                        <div className="product__body">
                            <div className="product__body__rate">
                                <ul>
                                    <li>
                                        <i
                                            className={data?.rate > 0 ? "fa fa-star" : "fa fa-star-o"}
                                            color="#000"
                                            // style={{ color: data?.rate > 0 ? "#FFA700" : "#E4E4E4" }}
                                        ></i>
                                    </li>

                                    <li>
                                        <i
                                            className={data?.rate > 1 ? "fa fa-star" : "fa fa-star-o"}
                                            // style={{ color: data?.rate > 1 ? "#FFA700" : "#E4E4E4" }}
                                        ></i>
                                    </li>

                                    <li>
                                        <i
                                            className={data?.rate > 2 ? "fa fa-star" : "fa fa-star-o"}
                                            // style={{ color: data?.rate > 2 ? "#FFA700" : "#E4E4E4" }}
                                        ></i>
                                    </li>
                                    <li>
                                        <i
                                            className={data?.rate > 3 ? "fa fa-star" : "fa fa-star-o"}
                                            // style={{ color: data?.rate > 3 ? "#FFA700" : "#E4E4E4" }}
                                        ></i>
                                    </li>
                                    <li>
                                        <i
                                            className={data?.rate > 4 ? "fa fa-star" : "fa fa-star-o"}
                                            // style={{ color: data?.rate > 4 ? "#FFA700" : "#E4E4E4" }}
                                        ></i>
                                    </li>
                                </ul>
                                <span>{data?.rate}</span>
                            </div>

                            <h5 onClick={() => (window.location.href = "/productDetails/" + data?.machine_name)} className="text-capitalize">
                                {data?.title}
                            </h5>
                            {/* <p
                                dangerouslySetInnerHTML={{
                                    __html: data?.subtitle,
                                }}
                            /> */}

                            <div className="product-horizontal__footer">
                                <div className="product__price">
                                    <div className="product__price__new-price">
                                        <span>{data?.option.price_after_discount}</span>
                                        <span> {this.props.country.currency}</span>
                                    </div>
                                    {data?.option.discount_percentage > 0 && (
                                        <div className="product__price__old-price">
                                            <span>{(data?.option.price).toFixed(2)}</span>

                                            <span>{data?.option.discount_percentage}% off</span>
                                        </div>
                                    )}
                                </div>
                                <div className="product__cart-compare">
                                    <div>
                                        <div onClick={() => this.addRemoveCompares(data?.id, reFetchData)}>
                                            {/* <img src={`/images/${this.props.currentUser.comparesId?.includes(data?.id) ? "on.svg" : "off.svg"}`} alt="ss" /> */}
                                            <img src={`/images/${this.props.currentUser.comparesId?.includes(data?.id) ? "newCompC.svg" : "newComp.svg"}`} alt="ss" />
                                        </div>
                                        <div
                                            onClick={() => {
                                                if (data?.option?.qty > 0) {
                                                    this.addToCart(data?.option.id, this.props.currentUser.cartIds?.includes(data?.option?.id) ? 0 : 1, reFetchData);
                                                }
                                            }}
                                        >
                                            {data?.option?.qty > 0 ? (
                                                // <img src={`/images/${this.props.currentUser.cartIds?.includes(data?.option?.id) ? "Group 1519" : "cartWithColor"}.svg`} alt="ss" />
                                                <img src={`/images/${this.props.currentUser.cartIds?.includes(data?.option?.id) ? "bagC" : "bag"}.svg`} alt="ss" />
                                            ) : (
                                                <img
                                                    src={`/images/qqqq.svg`}
                                                    alt="ss"
                                                    style={{
                                                        cursor: "no-drop",
                                                    }}
                                                    title={outOfStuck}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* product body end  */}
                    </Col>

                    <Col xs={5} className="p-0">
                        {/* product img start  */}
                        <div className="product__img text-center" onClick={() => (window.location.href = "/productDetails/" + data?.machine_name)}>
                            <img src={data?.main_image ? apiService.imageLink + data?.main_image : "/images/placeholder.png"} alt="k" className="product__img" />
                        </div>
                        {/* product img end  */}
                    </Col>
                </Row>
            </div>
        );
    }
    //#region We  Don't use it after change All API's
    newPrice = () => {
        const { data } = this.props;

        let discountAmount = (data?.price * data?.campaign.discount_percentage) / 100;

        let priceAfterDiscount = 0;
        if (discountAmount > data?.campaign.fixed_amount) {
            priceAfterDiscount = data?.price - data?.campaign.fixed_amount;
            return priceAfterDiscount;
        } else {
            priceAfterDiscount = data?.price - discountAmount;
            return priceAfterDiscount;
        }
    };

    //#endregion

    getIconData = async () => {
        const { data, success } = await generalServices.getIconData();
        if (!success) return;

        this.props.setUser({
            cart: data.cart_counter,
            fav: data.fav_counter,
            compare: data.compare_counter,
        });
    };
    getFavoritesIDs = async () => {
        const { data, success } = await generalServices.getFavoritesIDs();
        if (!success) return;

        this.props.setUser({
            favoritesId: data,
        });
    };
    getComparesIDs = async () => {
        const { data, success } = await generalServices.getComparesIDs();
        if (!success) return;
        this.props.setUser({
            comparesId: data,
        });
    };
    getCartIDs = async () => {
        const { data, success } = await generalServices.getCartIDs();
        if (!success) return;
        this.props.setUser({
            cartIds: data,
        });
    };

    addRemoveFavorites = async (id, reFetchData) => {
        const { success } = await productService.addOrRemoveFavorite(id);
        if (!success) return;
        this.setState({ isFav: !this.state.isFav });
        // reFetchData();
        this.getFavoritesIDs();

        this.getIconData();
    };
    addRemoveCompares = async (id, reFetchData) => {
        const { compare } = this.state;
        const { success } = await productService.addRemoveCompares(id);
        const { success: successText, CompareProductDeleted, CompareProductAdded } = this.props.i18n;
        if (!success) return;
        this.setState({ compare: !this.state.compare });

        displayAlert(successText, compare ? CompareProductDeleted : CompareProductAdded, "success", "noButtons", 2000);

        this.getComparesIDs();

        reFetchData();
        this.getIconData();
    };
    addToCart = async (id, qty, reFetchData) => {
        const { success } = await productService.addToCart(id, qty);
        const { success: successText, DeletedFromCart, AddedToCart } = this.props.i18n;
        if (!success) return;
        displayAlert(successText, qty === 0 ? DeletedFromCart : AddedToCart, "success", "noButtons", 2000);

        this.getCartIDs();
        reFetchData();
        this.getIconData();
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country,
});
export default connect(mapStateToProps, { setUser })(HorizontalProduct);
