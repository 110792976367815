import React, { Component } from "react";
import { Container, DropdownButton, FormControl, InputGroup, Dropdown, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../language/Global.json";
import { setLanguage } from "../../redux/actions-reducers/language";
import { setUser } from "../../redux/actions-reducers/user";
import { setCountry } from "../../redux/actions-reducers/country";
// import { slide as Menu } from "react-burger-menu";
import { generalServices } from "../../services/GeneralService";
import { apiService } from "../../services/ApiService";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import localStorage from "redux-persist/es/storage";
import { homeService } from "../../services/homeService";
import { tabletScreen } from "../../utils/misc";

class Header extends Component {
    state = {
        showCategory: false,
        showMegaCategory: false,
        menuOpen: false,
        countries: [],
        categories: [],
        topCategory: [],
        searchCategory: this.props.i18n?.all,
        topBrand: [],
        TopBarCategories: [],
        key: "",
        currentLang: this.props.language.lang,
        ads: {},
        headerData: {},
        searchCategoryId: 0,
    };
    onLanguageChange = async (name, langCode) => {
        // let currentLang;

        // if (this.props.language.langCode ==="ltr") {
        //     localStorage.setItem("langCode", "rtl");
        //     currentLang = "rtl";
        // } else {
        //     localStorage.setItem("langCode", "ltr");
        //     currentLang = "ltr";
        // }
        this.setState(
            {
                currentLang: name,
            },
            () => {
                localStorage.setItem("langCode", langCode);
                this.props.setLanguage({ lang: name, langCode }).then(() => {
                    window.location.reload();
                });
            }
        );
    };

    componentDidMount() {
        Promise.all([this.getFooter(), this.getCountries(), this.getIconData(), this.getFavoritesIDs(), this.getComparesIDs(), this.getCartIDs(), this.getCategories(0, 0, 0, 1, 1), this.getCategories(0, 0, 1, 1, 1)]);
    }


    getFooter = async () => {
        const { success, data } = await generalServices.getFooter();
        if (!success) return;
        // const { payment_methods, help_section, settings, social_media } = data
        this.setState({
            headerData: data,
        });
    }
    getCountries = async () => {
        const { data, success } = await generalServices.getAllCountries(0);
        if (!success) return;
        // this.props.setLanguage({ lang: data[0].languages[0].name, langCode: data[0].languages[0].direction }).then(() => {
        //     localStorage.setItem("langCode", data[0].languages[0].direction);
        // });
        this.setState({
            countries: data[0],
        });

        this.props.setCountry({
            id: data[0].id,
            name: data[0].name,
            language: data[0].languages,
            currency: data[0].currency,
            digits: data[0].digits,
            flag: data[0].flag,
            zipcode: data[0].zipcode,
        });
    };
    getCartIDs = async () => {
        const { data, success } = await generalServices.getCartIDs();
        if (!success) return;
        this.props.setUser({
            cartIds: data,
        });
    };
    getFavoritesIDs = async () => {
        const { data, success } = await generalServices.getFavoritesIDs();
        if (!success) return;

        this.props.setUser({
            favoritesId: data,
        });
    };
    getComparesIDs = async () => {
        const { data, success } = await generalServices.getComparesIDs();
        if (!success) return;
        this.props.setUser({
            comparesId: data,
        });
    };
    getIconData = async () => {
        const { data, success } = await generalServices.getIconData();
        if (!success) return;
        this.props.setUser({
            cart: data.cart_counter,
            fav: data.fav_counter,
            compare: data.compare_counter,
        });
    };

    getCategories = async (parentId, isFeatured, topBar, showBrands, showSub) => {
        const { data, success } = await generalServices.getCategories(parentId, isFeatured, topBar, showBrands, showSub, this.props.country.id);

        if (!success) return;
        let name = topBar === 0 ? "categories" : "TopBarCategories";
        this.setState({
            [name]: data,
            // topCategory: data[0].sub_category,
            // topBrand: data[0].brands,
        });
    };

    render() {

        const {
            language,
            currentUser,
            i18n: { Jordan, DeliverTo, LoginNew, CreateAccountNew, Lang, hello, search, all, AllCategories, TodaysDeals, Brands, BrandStore, SignIn, TopCategory, TopBrands, Categories, Guest, TrackYourOrder, Profile, MyOrders, MyLocations, Logout },
        } = this.props;

        const { showCategory, topBrand, currentLang, countries, topCategory, showMegaCategory, categories, searchCategory, TopBarCategories, ads, headerData } = this.state;
        return (
            <div className={`header ${language.langCode === "rtl" && "header header-arabic"}`}>
                {console.log('headerData', headerData)}
                <div
                    className="header__top"
                    onMouseOver={() => {
                        this.setState({
                            showMegaCategory: false,
                            showCategory: false,
                        });
                    }}
                >
                    <Container fluid>
                        <div className="header__top__content flex   ">
                            {/* logo start  */}
                            {headerData.header_logo && (
                                <a href="/">
                                    {/* <img src="/images/newLogo.png" alt="logo" className="logo" /> */}
                                    <img src={apiService.imageLink + headerData.header_logo} alt="logo" className="logo" />
                                </a>
                            )}
                            {/* logo end  */}

                            {/* delivery card start  */}
                            {/*  <div className={`flex header__location-card  ${language.langCode === "rtl" && "header__location-card-arabic"}`}>
                                <img src="/images/locationMark.svg" />
                                <div className="header__location-card__right-side">
                                    <span>{DeliverTo} </span>
                                    <p>{Jordan}</p>
                                </div>
                            </div> */}

                            {/* User card start  */}
                            {/* <DropdownButton
                                className={`header__location-card flex  ${language.langCode === "rtl" && "header__location-card-arabic"}`}
                                variant="outline-secondary"
                                title={
                                    <div className="flex button-contents">
                                        <img src="/images/locationMark.svg" alt="1" />
                                        <div className="header__location-card__right-side">
                                            <span>{DeliverTo}</span>
                                            <p>{this.props.country.name}</p>
                                        </div>
                                    </div>
                                }
                                style={{ overflow: "hidden" }}
                            ></DropdownButton> */}
                            {/* User card end  */}

                            {/* delivery card end  */}

                            {/* search section start  */}
                            {!tabletScreen && (
                                <InputGroup className={`header__search  ${language.langCode === "rtl" && "header__search-arabic"}`}>
                                    {/* <DropdownButton variant="outline-secondary" title={searchCategory} id="input-group-dropdown-1">
                                    <Dropdown.Item
                                        onClick={() =>
                                            this.setState({
                                                searchCategory: all,
                                                searchCategoryId: 0,
                                            })
                                        }
                                    >
                                        {all}
                                    </Dropdown.Item>
                                    {categories.map(({ name, sub_category, id, machine_name, brands }, index) => {
                                        return (
                                            <Dropdown.Item
                                                key={id}
                                                onClick={() =>
                                                    this.setState({
                                                        searchCategory: name,
                                                        // searchCategoryId: id,
                                                        searchCategoryId: machine_name,
                                                    })
                                                }
                                            >
                                                {name}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </DropdownButton> */}
                                    <FormControl
                                        placeholder={search}
                                        aria-label="Username"
                                        onChange={({ target: { value } }) => {
                                            this.setState({
                                                key: value,
                                            });
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                if (this.state.key === "") {
                                                } else {
                                                    this.Search(this.state.key);
                                                }
                                            }
                                        }}
                                        aria-describedby="basic-addon1"
                                    />
                                    <InputGroup.Text
                                        id="basic-addon1"
                                        onClick={() => {
                                            if (this.state.key === "") {
                                            } else {
                                                this.Search(this.state.key);
                                            }
                                        }}
                                    >
                                        <img src="/images/searchIcon.svg" alt="search" />
                                    </InputGroup.Text>
                                </InputGroup>
                            )}

                            {/* search section start  */}

                            {/* language section start  */}

                            {/* <div className="header__language flex" onClick={() => this.onLanguageChange()}>
                                <img src="/images/languageIcon.svg" alt="search" />

                                <span>{Lang}</span>
                            </div> */}

                            <DropdownButton
                                className={`header__location-card header__language-dropDown flex  ${language.langCode === "rtl" && "header__location-card-arabic"}`}
                                variant="outline-secondary"
                                title={
                                    <div className="header__language flex">
                                        <img src="/images/languageIcon.svg" alt="search" />
                                        <span>{currentLang}</span>
                                    </div>
                                }
                                id="input-group-dropdown-1"
                            >
                                {countries?.languages?.map(({ name, direction }) => {
                                    return <Dropdown.Item onClick={() => this.onLanguageChange(name, direction)}>{name}</Dropdown.Item>;
                                })}
                            </DropdownButton>
                            {/* language section start  */}

                            {/* User card start  */}
                            {currentUser?.email ? (
                                <DropdownButton
                                    className={`header__location-card flex  ${language.langCode === "rtl" && "header__location-card-arabic"}`}
                                    variant="outline-secondary"
                                    title={
                                        <div className="flex button-contents">
                                            <img src="/images/userIcon.svg" alt="1" />
                                            <div className="header__location-card__right-side">
                                                {/* <span>{hello}</span> */}
                                                <p>{currentUser?.name}</p>
                                            </div>
                                        </div>
                                    }
                                    id="input-group-dropdown-1"
                                >
                                    <Dropdown.Item href="/profile">
                                        <img src="/images/userDropdown.svg" alt="profile" />
                                        {Profile}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="/orders/1">
                                        <img src="/images/boxDropdown.svg" alt="orders" />
                                        {MyOrders}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="/addresses">
                                        <img src="/images/locationDropdown.svg" alt="addresses" />
                                        {MyLocations}
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item href="/returns">
                                        <img src="/images/returnDropdown.svg" />
                                        Returns
                                    </Dropdown.Item> */}
                                    <Dropdown.Item href="/trackYourOrder/1">
                                        <img src="/images/truckDropdown.svg" alt="trackYourOrder" />
                                        {TrackYourOrder}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        href="/login"
                                        onClick={() => {
                                            localStorage.removeItem("TZShopAccessToken");
                                            localStorage.removeItem("persist:RZ");
                                        }}
                                    >
                                        <img src="/images/logoutDropdown.svg" alt="Logout" />
                                        {Logout}
                                    </Dropdown.Item>
                                </DropdownButton>
                            ) : (
                                <>
                                    <div className={`flex header__location-card  ${language.langCode === "rtl" && "header__location-card-arabic"}`}>
                                        <img src="/images/userIcon.svg" />
                                        <div className="header__location-card__right-side">
                                            <Link to="/login">{LoginNew}</Link>/<Link to="/signup">{CreateAccountNew}</Link>
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title={
                                                    <>
                                                        <img src="/images/arrow-down.svg" alt="l" />
                                                    </>
                                                }
                                                id="input-group-dropdown-1"
                                                className="header__userButton"
                                            >
                                                <Dropdown.Item href="/login">
                                                    <img src="/images/logoutDropdown.svg" alt="Logout" />
                                                    {SignIn}
                                                </Dropdown.Item>
                                                <Dropdown.Item href="/orders/1">
                                                    <img src="/images/boxDropdown.svg" alt="orders" />
                                                    {MyOrders}
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </div>
                                </>
                            )}
                            {/* User card end  */}

                            {/*  compare count start  */}
                            <div className={`header__count flex  ${language.langCode === "rtl" && "header__count-arabic"}`}>
                                {/* compare count start  */}
                                <a href="/Compare">
                                    <img src="/images/compareIcon.svg" alt="search" />
                                    <span>{currentUser?.compare}</span>
                                </a>
                                {/* compare count end  */}

                                {/* fav count start  */}

                                <a href="/favourite">
                                    <img src="/images/favicon.svg" alt="search" />
                                    <span>{currentUser?.fav}</span>
                                </a>

                                {/* fav count end  */}
                                {/* cart count start  */}

                                <a href="/cart">
                                    <img src="/images/cartIcon.svg" alt="search" />
                                    <span>{currentUser?.cart}</span>
                                </a>

                                {/* cart count end  */}
                            </div>

                            {/* fav cart compare end  */}
                        </div>
                    </Container>
                </div>

                <div className="header__bottom">
                    <Container fluid>
                        <ul className={`flex header__bottom__list  ${language.langCode === "rtl" && "header__bottom__list-arabic"}`}>
                            <li
                                onMouseOver={() => {
                                    this.setState({
                                        showCategory: true,
                                        showMegaCategory: false,
                                    });
                                }}
                            >
                                <img src="/images/category.svg" alt="search" />

                                <span>
                                    {AllCategories}
                                    <img src="/images/pat.svg" alt="search" />
                                </span>
                            </li>
                            <li
                                onMouseOver={() => {
                                    this.setState({
                                        showMegaCategory: false,
                                    });
                                }}
                            >
                                <a href="/GeneralProduct/TodaysDeals" className="d-block">
                                    <img src="/images/hot-sale.svg" alt="search" className="m-2 mb-0 mt-0" />
                                    <span>{TodaysDeals}</span>
                                </a>
                            </li>
                            <li
                                onMouseOver={() => {
                                    this.setState({
                                        showMegaCategory: false,
                                    });
                                }}
                            >
                                <Link to="/brands">
                                    <img src="/images/brand.svg" alt="search" className="m-2 mb-0 mt-0" />
                                    <span>{Brands}</span>
                                </Link>
                            </li>

                            {TopBarCategories.map(({ name, subcategories, id, machine_name, brands, ads }) => {
                                return (
                                    <li
                                        key={id}
                                        onMouseOver={() => {
                                            this.setState({
                                                showCategory: false,
                                                showMegaCategory: true,
                                                topCategory: subcategories,
                                                topBrand: brands,
                                                ads: ads,
                                            });
                                        }}
                                    >
                                        <span>{name}</span>
                                    </li>
                                );
                            })}
                        </ul>

                        {/* mobile search section start  */}
                        {tabletScreen && (
                            <InputGroup className={`header__search mobile__search-header ${language.langCode === "rtl" && "header__search-arabic"}`}>
                                {/* <DropdownButton variant="outline-secondary" title={searchCategory} id="input-group-dropdown-1">
                                    <Dropdown.Item
                                        onClick={() =>
                                            this.setState({
                                                searchCategory: all,
                                                searchCategoryId: 0,
                                            })
                                        }
                                    >
                                        {all}
                                    </Dropdown.Item>
                                    {categories.map(({ name, sub_category, id, machine_name, brands }, index) => {
                                        return (
                                            <Dropdown.Item
                                                key={id}
                                                onClick={() =>
                                                    this.setState({
                                                        searchCategory: name,
                                                        // searchCategoryId: id,
                                                        searchCategoryId: machine_name,
                                                    })
                                                }
                                            >
                                                {name}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </DropdownButton> */}
                                <FormControl
                                    placeholder={search}
                                    aria-label="Username"
                                    onChange={({ target: { value } }) => {
                                        this.setState({
                                            key: value,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            if (this.state.key === "") {
                                            } else {
                                                this.Search(this.state.key);
                                            }
                                        }
                                    }}
                                    aria-describedby="basic-addon1"
                                />
                                <InputGroup.Text
                                    id="basic-addon1"
                                    onClick={() => {
                                        if (this.state.key === "") {
                                        } else {
                                            this.Search(this.state.key);
                                        }
                                    }}
                                >
                                    <img src="/images/searchIcon.svg" alt="search" />
                                </InputGroup.Text>
                            </InputGroup>
                        )}
                        {/* mobile search section start  */}
                    </Container>
                    {showCategory && (
                        <div
                            className="mega-menu shadow"
                            onMouseLeave={(name) => {
                                this.setState({
                                    showCategory: false,
                                });
                            }}
                        >
                            <ul className={`mega-menu__first-level  ${language.langCode === "rtl" && "mega-menu__first-level-arabic"}`}>
                                {categories.map(({ name, subcategories, id, machine_name, brands }) => {
                                    return (
                                        <li
                                            key={id}
                                            onMouseOver={() => {
                                                this.setState({
                                                    topCategory: subcategories,
                                                    topBrand: brands,
                                                });
                                            }}
                                        >
                                            <a href={"/ProductsCategory/" + machine_name + "/1"}>
                                                <span>
                                                    {name}
                                                    {subcategories?.length > 0 && <img src="/images/rightPath.svg" alt="search" />}
                                                </span>
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="sub-menu">
                                <div className="sub-menu__header">
                                    <span>{TopCategory}</span>
                                    <span>{TopBrands}</span>
                                </div>
                                <div className="sub-menu__lists">
                                    <ul>
                                        {topCategory.map(({ id, name, machine_name }) => {
                                            return (
                                                <li key={id}>
                                                    <a href={"/ProductsCategory/" + machine_name + "/1"}>
                                                        <span>{name}</span>
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>

                                    <ul>
                                        {topBrand.map(({ name, machine_name }, index) => {
                                            return (
                                                <li key={index}>
                                                    <a href={"/BrandProducts/" + machine_name + "/1"}>
                                                        <span>{name}</span>
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}

                    {showMegaCategory && (
                        <div
                            className="mega-menu2 shadow"
                            onMouseLeave={() => {
                                this.setState({
                                    showMegaCategory: false,
                                });
                            }}
                        >
                            <Container fluid>
                                <div className="mega-menu2__contents">
                                    <ul>
                                        <h6>{Categories}</h6>
                                        {topCategory.map(({ id, name, machine_name }) => {
                                            return (
                                                <li key={id}>
                                                    <a href={"/ProductsCategory/" + machine_name + "/1"}>
                                                        <span>{name}</span>
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>

                                    <div className="mega-menu2__top-brands">
                                        <h6>{TopBrands}</h6>

                                        <div className="mega-menu2__top-brands__list">
                                            {topBrand.map(({ logo, machine_name, name, id }) => {
                                                return (
                                                    <a key={id} href={"/BrandProducts/" + machine_name + "/1"} className="single-brand p-3">
                                                        <img src={apiService.imageLink + logo} alt="l" />
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className="mega-menu2__sales">
                                        <Row>
                                            {ads?.categories_in_top_bar_left?.ad_image && (
                                                <Col xl={ads?.categories_in_top_bar_right?.ad_image ? 8 : 12} md={ads?.categories_in_top_bar_right?.ad_image ? 6 : 12} className="menu2__sales__col">
                                                    <div
                                                        onClick={() => {
                                                            if (ads?.categories_in_top_bar_left?.can_click) {
                                                                this.visitAdsLink(ads?.categories_in_top_bar_left?.link, ads?.categories_in_top_bar_left?.id);
                                                            }
                                                        }}
                                                    >
                                                        <img src={ads?.categories_in_top_bar_left?.ad_image ? apiService.imageLink + ads?.categories_in_top_bar_left?.ad_image : "/images/placeholder.png"} alt="l" />
                                                    </div>
                                                </Col>
                                            )}
                                            {ads?.categories_in_top_bar_right?.ad_image && (
                                                <Col xl={ads?.categories_in_top_bar_left?.ad_image ? 4 : 12} md={ads?.categories_in_top_bar_left?.ad_image ? 6 : 12} className="menu2__sales__col">
                                                    <div
                                                        onClick={() => {
                                                            if (ads?.categories_in_top_bar_right?.can_click) {
                                                                this.visitAdsLink(ads?.categories_in_top_bar_right?.link, ads?.categories_in_top_bar_right?.id);
                                                            }
                                                        }}
                                                    >
                                                        <img src={ads?.categories_in_top_bar_right?.ad_image ? apiService.imageLink + ads?.categories_in_top_bar_right?.ad_image : "/images/placeholder.png"} alt="l" />
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    )}
                </div>
            </div>
        );
    }
    closeMenu() {
        this.setState({ menuOpen: false });
    }
    toggleMenu() {
        this.setState((state) => ({ menuOpen: !state.menuOpen }));
    }
    Search = (key) => {
        const { searchCategoryId } = this.state;

        if (key.includes("%")) {
            window.location.href = `/Search/${key.replace("%", "%25")}/${searchCategoryId}/1`;
        } else {
            window.location.href = `/Search/${key}/${searchCategoryId}/1`;
            // this.props.history.push(`/Search/${key}/${searchCategoryId}/1`);
        }
    };

    visitAdsLink = async (url, id) => {
        const { data, success } = await homeService.visitAdsLink(id);
        if (!success) return;
        window.open(url, "target");
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country,
});
export default connect(mapStateToProps, { setLanguage, setCountry, setUser })(withRouter(Header));
