import React, { Component } from "react";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import Product from "../../blocks/Product";
import { generalServices } from "../../../services/GeneralService";
import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../../utils/misc";
import { Link } from "react-router-dom";
// import ReactPaginate from "react-paginate";
// import PaginatedItems from "./PaginationBloack";
// import PaginationBlock from "../../blocks/PaginaitionBlock";
import PaginatedItems from "../../Pages/Search/PaginationBloack";
import { withRouter } from "react-router-dom";
class Search extends Component {
    state = {
        products: [],
        pageLoader: true,
        meta: {},
    };

    componentDidMount() {
        this.search();
    }
    search = async () => {
        const { success, data, meta } = await generalServices.search(this.props.match.params.key, this.props.match.params.catId, this.props.match.params.page);
        if (!success) return;
        this.setState({
            products: data,
            meta,
            pageLoader: false,
        });
    };
    render() {
        const {
            language,
            reFetchData,
            i18n: { SearchResultsby, noSearchResult },
        } = this.props;

        const { products, pageLoader, meta } = this.state;
        return (
            <Container>
                <div className={`search  ${language === "rtl" && "search-arabic"}`}>
                    <h2 className="search-title">
                        {SearchResultsby} : <span className="search-title__Word">`{this.props.match.params.key}`</span>
                    </h2>

                    {pageLoader ? (
                        <Row className="mb-4">
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={tabletScreen ? 100 : 200} />
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            {products.length < 1 ? (
                                <div className="empty-products">
                                    <img src="/images/search.svg" alt="k" />
                                    <p>{noSearchResult}</p>{" "}
                                </div>
                            ) : (
                                products?.map((item) => {
                                    return (
                                        <Col lg={3} md={4} xs={6}>
                                            <Product data={item} reFetchData={() => this.search()} />
                                        </Col>
                                    );
                                })
                            )}
                        </Row>
                    )}
                    {/** Pagination Start */}
                    {products.length >= 1 ? (
                        <Row>
                            <Col md={12}>
                                <div className="Pagination">
                                    <PaginatedItems items={[...Array(meta?.total).keys()]} itemsPerPage={meta?.per_page} changePage={(page) => this.changePage(page)} />
                                </div>
                            </Col>
                        </Row>
                    ) : null}

                    {/** Pagination End */}
                </div>
            </Container>
        );
    }

    toTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    changePage(page) {
        this.toTop();
        this.props.history.push(`/Search/${this.props.match.params.key}/${this.props.match.params.catId}/${page + 1}`);
        // window.location.href = `/Search/${this.props.match.params.key}/${
        //     this.props.match.params.catId
        // }/${page + 1}`;
        // to={`/Search/${this.props.match.params.key}/${this.props.match.params.catId}/${index + 1}`}
        this.setState(
            {
                pageLoader: true,
            },
            () => {
                this.search();
            }
        );
    }
    closeMenu() {
        this.setState({ menuOpen: false });
    }
    toggleMenu() {
        this.setState((state) => ({ menuOpen: !state.menuOpen }));
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(withRouter(Search));
