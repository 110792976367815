import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import Returns from "../AccountPage/Returns";
import AccountPageList from "../../blocks/AccountPageList";
import AccountPageMobileList from "../../blocks/AccountPageMobileList";

class ReturnsPage extends Component {
    state = {
        remindMe: false,
    };

    render() {
        const {
            language,
            i18n: { WelcomeName, SignOut },
        } = this.props;

        return (
            <Container>
                <div className={`account-page ${language === "rtl" && "account-page-arabic"}`}>
                    {/* Mobile Side Menu Start */}
                    <AccountPageMobileList />
                    {/*Mobile Side Menu End */}
                    {/*Menu Start */}
                    <Row>
                        <Col className="board" md={3}>
                            <h3>{WelcomeName}</h3>
                            <a
                                href="/login"
                                className="board__signOut"
                                onClick={() => {
                                    localStorage.removeItem("TZShopAccessToken");
                                    localStorage.removeItem("persist:RZ");
                                }}
                            >
                                {SignOut}
                            </a>
                            <AccountPageList />
                        </Col>
                        <Col md={9}>
                            <Returns />
                        </Col>
                    </Row>
                    {/*Menu End */}
                </div>
            </Container>
        );
    }
    closeMenu() {
        this.setState({ menuOpen: false });
    }
    toggleMenu() {
        this.setState((state) => ({ menuOpen: !state.menuOpen }));
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(ReturnsPage);
