import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json";

function PaginationBloack({ itemsPerPage, changePage, items }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(1);

    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.

    const [itemOffset, setItemOffset] = useState(1);
    const language = useSelector(({ language }) => language);
    const i18n = language.langCode === "ltr" ? Global.en : Global.ar;
    const { next, previous } = i18n;

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        changePage(event.selected);
        // changePage(newOffset);
        setItemOffset(newOffset);
    };

    return (
        <>
            <ReactPaginate
                nextLabel={<img src="/images/Vector.svg" alt="" />}
                onPageChange={handlePageClick}
                // pageRangeDisplayed={5}previous
                pageCount={pageCount}
                previousLabel={<img src="/images/arrow-right.svg" alt="" />}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
                marginPagesDisplayed={1}
                pageRangeDisplayed={4}
            />
        </>
    );
}

export default PaginationBloack;
