import React, { Component } from "react";
import { Col, Container, Row, InputGroup, Button, FormControl, Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import TextInput from "../../blocks/TextInput";
import { GoogleApiWrapper } from "google-maps-react";
import { cartService } from "../../../services/CartService";
import { userService } from "../../../services/UserService";
import AccordionLocation from "./AccordionLocation";
import { apiService } from "../../../services/ApiService";
import { generalServices } from "../../../services/GeneralService";
import SelectInput from "../../blocks/SelectInput";
import { displayAlert, mapSelectData } from "../../../utils/misc";
import { locationService } from "../../../services/LocationService";
import { ErrorMessage } from "../../blocks/ErrorMessage";
import LocationModal from "../addressesPage/LocationModal";
import Skeleton from "react-loading-skeleton";
import GuestAddLocation from "./GuestAddLocation";
import moment from "moment";
class Checkout extends Component {
    state = {
        products: [1, 2, 3, 4],
        products2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
        fullView: false,
        eventKey: 0,
        Check: false,
        CreatAccount: false,
        cart: [],
        itemsCount: [],
        payment_methods: [],
        AddLocation: false,
        adsData: [
            {
                image: "/images/Group55.png",
                link: "/",
                lg: 12,
                md: 12,
                sm: 12,
                xs: 12,
            },
        ],
        myLocations: [],
        fields: {},
        errors: {},

        areas: [],
        cities: [],
        addAddress: false,

        defaultPosition: {
            lat: 31.963158,
            lng: 35.930359,
        },
        position: {
            lat: 31.963158,
            lng: 35.930359,
        },
        Coupon: [],
        CouponFields: {},
        cartAmount: {},
        couponError: false,
        locationLoader: true,
        couponErrorText: "",
        couponInputIsEmpty: true,
        eventKeyPayment: 1,
        eventKeyDate: 1,
        timeSlots: [
            { label: "9-12", value: "9-12" },
            { label: "12-3", value: "12-3" },
            { label: "3-7", value: "3-7" },
        ],
        selectedLocation: {},
        guestLocation: {},
    };
    get guestId() {
        return localStorage.getItem("guestId");
    }

    handleLocationChange = ({ position, address, places }) => {
        // Set new location
        this.setState({ position, address });
    };

    componentDidMount() {
        Promise.all([this.getUserCart(), this.getUserLocation(), this.getCities(), this.getPaymentMethodsByPlaceID(), this.getCheckoutAmounts()]);
    }

    getCheckoutAmounts = async () => {
        const { data, success } = await cartService.getCheckoutAmounts({
            payment_id: this.state.selectedPayment ?? "",
            delivery_company_id: 0,
            // place_id: this.props.country.id,
            place_id: apiService.accessToken === 0 ? this.state.guestLocation?.place_id : this.state.selectedLocation?.place?.id,
            coupons: [],
        });

        if (!success) return;
        this.setState({
            cartAmount: data,
        });
    };

    async getPaymentMethodsByPlaceID() {
        const { success, data } = await generalServices.getPaymentMethodsByPlaceID(this.props.country.id);
        if (!success) return;
        this.setState({
            payment_methods: data,
        });
    }

    getUserCart = async () => {
        const { data, success } = await cartService.getUserCart();
        const itemsCount = data
            ?.map(({ qty }) => qty)
            .reduce((acc, currentValue) => {
                return currentValue + acc;
            }, 0);

        if (!success) return;
        this.setState({
            cart: data,
            itemsCount,
        });
    };

    getUserLocation = async () => {
        if (apiService.accessToken === 0)
            return this.setState({
                locationLoader: false,
            });
        const { data, success } = await locationService.getUserLocation();

        if (!success) return;
        this.setState({
            myLocations: data,
            locationLoader: false,
        });
    };

    getCities = async () => {
        const { country } = this.props;
        const { success, data } = await generalServices.getCities(country?.id);
        if (!success) return;
        this.setState({
            cities: mapSelectData(data),
        });
    };
    getAreas = async (id) => {
        const { success, data } = await generalServices.getAreas(id);
        if (!success) return;
        this.setState({
            areas: mapSelectData(data),
        });
    };

    // handle location change ////
    handleLocationChange = ({ position }) => {
        // Set new location
        this.setState({ position });
    };
    render() {
        const { addAddress, guestLocation, Check, timeSlots, eventKey, eventKeyPayment, eventKeyDate, couponInputIsEmpty, cartAmount, couponErrorText, couponError, myLocations, cart, selectedPayment, itemsCount, payment_methods, fields, errors, Coupon, CouponFields, locationLoader, selectedLocation } = this.state;
        const {
            language,
            i18n: { deliveryAmount, DeliveryLocation, PaymentMethod, Cart, Items, OrderSummary, Delete, CouponText, Apply, CouponCode, SubTotal, Discount, feesAmount, taxesAmount, Total, ProcerdtoCheckout, Righttimefordelivery, BasicInformation, AddLocationButt, Date, Time },
        } = this.props;

        return (
            <div className="Cart">
                {/** Title */}
                <div className="CartTitle">
                    <Container>
                        <Row>
                            <Col>
                                <h2>
                                    {Cart}
                                    <h4>
                                        {/* {itemsCount} */}
                                        {cart.length}

                                        {Items}
                                    </h4>
                                </h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/** Title End */}

                {/** Cart Products And Amount */}
                <Container>
                    <Row>
                        <Col xl={9} lg={9} md={8} sm={12} xs={12}>
                            <div className={`AccordionFilters AccordionFiltersCheckout ${language === "rtl" && "Cart-Arabic"}`}>
                                <Accordion accordion={true} defaultActiveKey={0} activeKey={eventKey} expandMode={"multiple"}>
                                    {/** Sub Accordion */}
                                    {/* {!AddLocation ? ( */}
                                    {apiService.accessToken !== 0 ? (
                                        <Accordion.Item eventKey={0}>
                                            <Accordion.Header
                                                onClick={() => {
                                                    this.setState({
                                                        eventKey: eventKey !== 0 ? 0 : false,
                                                    });
                                                }}
                                            >
                                                {DeliveryLocation}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <ul>
                                                    {/** Location Start */}
                                                    {locationLoader ? (
                                                        <>
                                                            <Col lg={12} md={12}>
                                                                <Skeleton height={120} />
                                                            </Col>
                                                            <Col lg={12} md={12}>
                                                                <Skeleton height={120} />
                                                            </Col>
                                                        </>
                                                    ) : (
                                                        myLocations?.map((item) => {
                                                            return (
                                                                <AccordionLocation
                                                                    data={item}
                                                                    selectedLocation={selectedLocation}
                                                                    changeAccordion={(id) =>
                                                                        this.setState(
                                                                            {
                                                                                Check: !Check,
                                                                                // eventKey: 1,
                                                                                selectedLocation: selectedLocation == item ? {} : item,
                                                                                Coupon: [],
                                                                            },
                                                                            () => this.getCheckoutAmounts()
                                                                        )
                                                                    }
                                                                />
                                                            );
                                                        })
                                                    )}
                                                    {/** Location Start */}
                                                </ul>

                                                {/** Add New Location */}
                                                <Container>
                                                    <div className="Locations LocationsAdd">
                                                        <div className="LocationInfo">
                                                            <div className="LocationIcon">
                                                                <img src="/images/Bold-Outline.svg" alt="" />
                                                            </div>
                                                            <div className="AddToCartSpan">
                                                                <span
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            addAddress: true,
                                                                        })
                                                                    }
                                                                >
                                                                    {AddLocationButt}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <img
                                                            src={"/images/pluse.png"}
                                                            alt="1"
                                                            onClick={() =>
                                                                this.setState({
                                                                    addAddress: true,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </Container>
                                                {/** Add New Location End*/}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ) : (
                                        <Accordion.Item eventKey={0}>
                                            <Accordion.Header
                                                onClick={() => {
                                                    this.setState({
                                                        eventKey: eventKey !== 0 ? 0 : false,
                                                    });
                                                }}
                                            >
                                                {BasicInformation}
                                            </Accordion.Header>
                                            <GuestAddLocation
                                                reFetchData={() => this.getUserLocation()}
                                                changeAccordion={(guestLocation) =>
                                                    this.setState(
                                                        {
                                                            Check: !Check,
                                                            eventKey: 1,
                                                            guestLocation,
                                                        },
                                                        () => this.getCheckoutAmounts()
                                                    )
                                                }
                                            />
                                        </Accordion.Item>
                                    )}
                                </Accordion>

                                {/** Sub Accordion End */}
                                <Row className="mb-5">
                                    <Col className="mb-5" sm={6}>
                                        <Accordion accordion={true} defaultActiveKey={0} activeKey={eventKeyPayment} expandMode={"multiple"}>
                                            {/** Sub Accordion */}
                                            <Accordion.Item eventKey={1}>
                                                <Accordion.Header
                                                    onClick={() => {
                                                        this.setState({
                                                            eventKeyPayment: eventKeyPayment !== 1 ? 1 : false,
                                                        });
                                                    }}
                                                >
                                                    {PaymentMethod}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <ul className="PaymentMethodAcc">
                                                        {payment_methods.map(({ name, image, id }, index) => {
                                                            return (
                                                                <>
                                                                    <li key={index}>
                                                                        <label className="CehckBoxContainer_">
                                                                            <img src={apiService.imageLink + image} alt="l" />
                                                                            &nbsp;
                                                                            <span className="CehckBoxContainer_span">{name}</span>
                                                                            <input
                                                                                type="radio"
                                                                                name="cash"
                                                                                onClick={() =>
                                                                                    this.setState(
                                                                                        {
                                                                                            // eventKeyPayment: 2,
                                                                                            selectedPayment: id,
                                                                                        },
                                                                                        () => this.getCheckoutAmounts()
                                                                                    )
                                                                                }
                                                                            />
                                                                            <span className="mark">
                                                                                {/* <i className="fa fa-check"></i> */}
                                                                                {this.state.selectedPayment === id && <img src="/images/check.svg" alt="k" />}
                                                                            </span>
                                                                        </label>
                                                                    </li>
                                                                </>
                                                            );
                                                        })}

                                                        {/* <li>
                                                            <label className="CehckBoxContainer">
                                                                <svg id="credit-card-payment_1_" data-name="credit-card-payment (1)" xmlns="http://www.w3.org/2000/svg" width="37.921" height="37.92" viewBox="0 0 37.921 37.92">
                                                                    <path
                                                                        id="Path_3848"
                                                                        data-name="Path 3848"
                                                                        d="M36.946,12.32,25.6.975a3.337,3.337,0,0,0-4.714,0L6.652,15.211H3.333A3.337,3.337,0,0,0,0,18.544V34.588a3.337,3.337,0,0,0,3.333,3.333H28.858a3.337,3.337,0,0,0,3.333-3.333v-12.8l4.755-4.755A3.337,3.337,0,0,0,36.946,12.32ZM22.459,2.546a1.112,1.112,0,0,1,1.571,0l5.846,5.846-6.819,6.819H9.794Zm7.51,32.042A1.112,1.112,0,0,1,28.858,35.7H3.333a1.112,1.112,0,0,1-1.111-1.111V18.544a1.112,1.112,0,0,1,1.111-1.111H28.858a1.112,1.112,0,0,1,1.111,1.111ZM29.31,15.242a3.348,3.348,0,0,0-.452-.031H26.2l5.248-5.248,1.571,1.571Zm6.065.22-3.184,3.184v-.1a3.319,3.319,0,0,0-.836-2.2l3.234-3.234.786.786a1.112,1.112,0,0,1,0,1.571Z"
                                                                        transform="translate(0 0)"
                                                                        fill="#cecece"
                                                                    />
                                                                    <path id="Path_3849" data-name="Path 3849" d="M60,406.574h4.134V408.8H60Z" transform="translate(-55.556 -376.461)" fill="#cecece" />
                                                                    <path id="Path_3850" data-name="Path 3850" d="M146.267,406.574H150.4V408.8h-4.134Z" transform="translate(-135.433 -376.461)" fill="#cecece" />
                                                                    <path id="Path_3851" data-name="Path 3851" d="M232.534,406.574h4.134V408.8h-4.134Z" transform="translate(-215.311 -376.461)" fill="#cecece" />
                                                                    <path id="Path_3852" data-name="Path 3852" d="M318.8,406.574h4.134V408.8H318.8Z" transform="translate(-295.188 -376.461)" fill="#cecece" />
                                                                    <path id="Path_3853" data-name="Path 3853" d="M67.5,280.766H60v6.666h7.5Zm-2.222,4.444H62.222v-2.222h3.057Z" transform="translate(-55.556 -259.971)" fill="#cecece" />
                                                                </svg>
                                                                &nbsp; credit card
                                                                <input type="radio" name="cash" ocClick={() => this.setState({ eventKey: 2 })} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </li> */}
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {/** Sub Accordion End */}
                                        </Accordion>
                                    </Col>

                                    <Col className="mb-5" sm={6}>
                                        <Accordion accordion={true} defaultActiveKey={1} activeKey={eventKeyDate} expandMode={"multiple"}>
                                            {/** Sub Accordion */}
                                            <Accordion.Item eventKey={1}>
                                                <Accordion.Header
                                                    onClick={() => {
                                                        this.setState({
                                                            eventKeyDate: eventKeyDate !== 1 ? 1 : false,
                                                        });
                                                    }}
                                                >
                                                    {Righttimefordelivery}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        <li>
                                                            {/* <DateInput placeholder={Date} label={Date} type={"date"} name="date" selectedDate={fields.date} validate={errors.date} onFieldChange={this.onFieldChange} /> */}

                                                            <TextInput
                                                                min={moment(moment().add(1, "days")._d).format("YYYY-MM-DD")}
                                                                // max={moment(moment().add(30, "days")._d).format("YYYY-MM-DD")}
                                                                placeholder={Date}
                                                                label={Date}
                                                                type={"date"}
                                                                name="date"
                                                                value={fields.date}
                                                                validate={errors.date}
                                                                onFieldChange={this.onFieldChange}
                                                            />
                                                        </li>

                                                        <li>
                                                            <SelectInput
                                                                name="time"
                                                                placeholder={Time}
                                                                label={Time}
                                                                value={fields.time}
                                                                onFieldChange={this.onFieldChange}
                                                                data={timeSlots}
                                                                validate={errors.time}
                                                            // isTextArea
                                                            />
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {/** Sub Accordion End */}
                                        </Accordion>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                            <div className="TotalCart">
                                <h3>{OrderSummary}</h3>

                                {/** Sub Total Start  */}
                                <div>
                                    <div className="TotalsCart">
                                        <span className="SubTotalSpan">{Items}</span>
                                        {/* <span className="SubTotalSpanCount">
                                            {itemsCount}
                                        </span> */}
                                    </div>
                                </div>
                                {/** Sub Total End  */}

                                {/** Items  */}
                                <ol>
                                    {cart?.map(({ product, qty }, index) => {
                                        return (
                                            <li>
                                                <p>
                                                    {/* <strong> 0{index + 1}</strong> */}
                                                    {product?.title}
                                                </p>
                                                <span> X {qty}</span>
                                            </li>
                                        );
                                    })}
                                </ol>
                                {/** Items End */}

                                {/** Code Start */}
                                {(apiService.accessToken === 0 ? guestLocation?.place_id : selectedLocation?.id) && selectedPayment && (
                                    <div className="mb-3">
                                        <InputGroup>
                                            <FormControl name={`couponCode-${Coupon.length}`} value={CouponFields[`couponCode-${Coupon.length - 1}`]} placeholder={CouponCode} aria-label={CouponCode} aria-describedby="basic-addon2" onChange={({ target: { name, value } }) => this.onCouponFieldChange(name, value)} />
                                            <Button disabled={couponInputIsEmpty} variant="outline-secondary" id="button-addon2" className={` ${language === "rtl" && "buttAr"}`} onClick={this.checkCouponIfValid}>
                                                {Apply}
                                            </Button>
                                        </InputGroup>
                                        {couponError && <ErrorMessage message={couponErrorText} isSelect />}
                                        <ul className="valid-coupon-list mt-4">
                                            {Coupon.map((item, index) => {
                                                return (
                                                    <li data-index={index} key={index} className="mb-1">
                                                        <span>
                                                            {CouponText} : {item}
                                                        </span>
                                                        <span onClick={() => this.deleteValidCoupon(index)}>{Delete}</span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                )}

                                {/** Code End */}

                                {/** Sub Total Start  */}
                                <div>
                                    <div className="TotalsCart">
                                        <span className="SubTotalSpan">
                                            {" "}
                                            {SubTotal}{" "}
                                            <span className="SubTotalSpanCount">
                                                {itemsCount} {Items}{" "}
                                            </span>
                                        </span>

                                        <span className="PriceSpan">
                                            <sup>{this.props.country.currency}</sup>
                                            {cartAmount?.items_amount}
                                        </span>
                                    </div>
                                </div>
                                {/** Sub Total End  */}

                                {/** Discount Start  */}
                                {cartAmount?.discount_amount > 0 && (
                                    <div>
                                        <div className="TotalsCart">
                                            <span className="SubTotalSpan"> {Discount}</span>

                                            <span className="PriceSpan">
                                                <span>{cartAmount?.discount_amount}</span>
                                            </span>
                                        </div>
                                    </div>
                                )}

                                {/** Discount End  */}
                                {/** taxes Amount Start  */}
                                {cartAmount?.taxes_amount > 0 && (
                                    <div>
                                        <div className="TotalsCart">
                                            <span className="SubTotalSpan"> {taxesAmount}</span>

                                            <span className="PriceSpan">
                                                <sup>{this.props.country.currency}</sup> {cartAmount?.taxes_amount}
                                            </span>
                                        </div>
                                    </div>
                                )}

                                {/** taxes Amount End  */}
                                {/** feesAmount Start  */}
                                {cartAmount?.fees_amount > 0 && (
                                    <div>
                                        <div className="TotalsCart">
                                            <span className="SubTotalSpan"> {feesAmount}</span>

                                            <span className="PriceSpan">
                                                <sup>{this.props.country.currency}</sup> {cartAmount?.fees_amount}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {/** feesAmount End  */}
                                {/** delivary Start  */}
                                {cartAmount?.delivery_amount > 0 && (
                                    <div>
                                        <div className="TotalsCart">
                                            <span className="SubTotalSpan">{deliveryAmount}</span>

                                            <span className="PriceSpan">
                                                <sup>{this.props.country.currency}</sup>
                                                {cartAmount?.delivery_amount}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {/** delivary End  */}
                                <hr></hr>
                                {/** Total Start  */}
                                <div>
                                    <div className="TotalsCart">
                                        <span className="SubTotalSpan"> {Total}</span> <br />
                                        <br />
                                        <span className="PriceSpan">
                                            <sup>{this.props.country.currency}</sup>
                                            {cartAmount?.total_amount}
                                        </span>
                                    </div>
                                </div>
                                {/** Total End  */}

                                <div className="CheckoutButt">
                                    {/** Checkout Button */}
                                    <a onClick={() => this.checkoutSubmit()}>{ProcerdtoCheckout}</a>
                                    {/** Checkout end */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/** Cart Products And Amount End */}

                {/* add/edit address modal */}
                <LocationModal show={addAddress} closeModal={this.closeModal} reFetchData={() => this.getUserLocation()} />
                {/* end add/edit address modal */}
            </div>
        );
    }

    checkCouponIfValid = async () => {
        const { selectedPayment, Coupon, CouponFields, selectedLocation, guestLocation } = this.state;
        let couponReadyToSend = [];
        for (const item in CouponFields) {
            couponReadyToSend.push(CouponFields[item]);
        }

        const { data } = await cartService.checkCouponIfValid({
            guest_id: apiService.accessToken === 0 ? this.guestId : 0,
            payment_id: selectedPayment ?? "",
            delivery_company_id: 0,
            // place_id: this.props.country.id,
            place_id: apiService.accessToken === 0 ? guestLocation.place_id : selectedLocation?.place?.id,
            coupon: CouponFields[`couponCode-${Coupon.length}`],
        });

        if (!data)
            return this.setState({
                couponError: true,
                couponErrorText: "Invalid Coupon Code",
            });

        if (Coupon.includes(CouponFields[`couponCode-${Coupon.length}`]))
            return this.setState({
                couponError: true,
                couponErrorText: "Coupon Used",
                CouponFields: { [`couponCode-${Coupon.length}`]: "" },
            });

        this.setState(
            {
                CouponFields: { [`couponCode-${Coupon.length}`]: "" },
                couponInputIsEmpty: true,
                Coupon: [...Coupon, ...couponReadyToSend],
            },
            () => {
                this.checkOutAmount();
            }
        );
    };
    deleteValidCoupon = (couponIndex) => {
        let newCoupon = this.state.Coupon.filter((item, index) => index !== couponIndex);
        this.setState(
            {
                Coupon: newCoupon,
            },
            () => this.getCheckoutAmounts()
        );
    };
    checkOutAmount = async () => {
        const { selectedPayment, CouponFields } = this.state;
        let couponReadyToSend = [];
        for (const item in CouponFields) {
            couponReadyToSend.push(CouponFields[item]);
        }

        const { data, success } = await cartService.getCheckoutAmounts({
            payment_id: selectedPayment,
            delivery_company_id: 0,
            // place_id: this.props.country.id,
            place_id: apiService.accessToken === 0 ? this.state.guestLocation?.place_id : this.state.selectedLocation?.place?.id,

            coupons: this.state.Coupon,
        });
        if (!success) this.setState({ couponError: true });
        this.setState({
            cartAmount: data,
            Coupon: data.coupons,
        });
    };

    onCouponFieldChange = (name, value) => {
        if (value === "") return this.setState({ couponInputIsEmpty: true });
        this.setState({
            couponError: false,
            couponInputIsEmpty: false,
            CouponFields: {
                [name]: value,
            },
        });
    };

    onCityChange = (name, value) => {
        const { fields } = this.state;
        this.setState({
            fields: { ...fields, [name]: value },
        });
        this.getAreas(value.value);
    };

    onFieldChange = (name, value) => {
        const { fields } = this.state;
        this.setState({
            fields: { ...fields, [name]: value },
            errors: {
                email: "",
                password: "",
            },
        });
    };

    addLocationSubmit = async (id, qty, reFetchData) => {
        const { fields, position } = this.state;
        const { success: successText, LocationAdded } = this.props.i18n;
        const { success } = await locationService.addLocationSubmit({
            nickname: fields.name,
            address: fields.address,
            latitude: position.lat,
            longitude: position.lng,
            phone: fields.phone,
            area_id: fields.selectedArea.value,
        });
        if (!success) return;
        displayAlert(successText, LocationAdded, "success").then(() => this.setState({ addAddress: false }));
        this.getUserLocation();
    };

    checkoutSubmit = async () => {
        const { fields, selectedLocation, selectedPayment, Coupon, guestLocation } = this.state;
        const { success: successText, OrderCheckoutSuccessfully, error: errorTag, SelectAllFields } = this.props.i18n;
        let dataSendToApi = {};
        if (apiService.accessToken === 0) {
            dataSendToApi = {
                preferred_delivery_date: fields.date?.replaceAll("/", "-"),
                payment_method_id: selectedPayment,
                preferred_time_slot: fields.time?.value,
                // replaceAll("/", "-"),
                coupons: Coupon,
                notes: "",
                user_location_id: 0,
                delivery_comapny_id: "",

                guest_details: {
                    place_id: guestLocation?.place_id,
                    person_name: guestLocation?.name,
                    latitude: guestLocation?.latitude,
                    longitude: guestLocation?.longitude,
                    building: guestLocation?.building,
                    floor: guestLocation?.floor,
                    appartment: guestLocation?.apartment,
                    address: guestLocation?.address,
                    phone: guestLocation?.phone,
                    email: "",
                },
            };
        } else {
            dataSendToApi = {
                preferred_delivery_date: fields.date?.replaceAll("/", "-"),
                payment_method_id: selectedPayment,
                preferred_time_slot: fields.time?.value,
                // ?.replaceAll("/", "-"), // "preferred_time_slot":"10:00 - 12:00",
                coupons: Coupon,
                notes: "",
                user_location_id: selectedLocation?.id,
                delivery_comapny_id: "",
                guest_details: {},
            };
        }

        if ((selectedLocation?.id || guestLocation.place_id) && selectedPayment && fields.time?.value && fields.date) {
            const { success, message, data } = await userService.checkoutSubmit(dataSendToApi);

            if (!success)
                return displayAlert(errorTag, message, "error").then((ok) => {
                    if (ok) {
                        window.location.href = "/cart";
                    }
                });

            data.has_payment_link == true ?
                displayAlert(successText, OrderCheckoutSuccessfully, "success", "noButtons", 2000).then(() => (window.location.href = apiService.baseURL + data.payment_link))
                :
                displayAlert(successText, OrderCheckoutSuccessfully, "success", "noButtons", 2000).then(() => (window.location.href = "/orders/1"));
        } else {
            displayAlert(errorTag, SelectAllFields, "error");
        }
    };
    closeModal = () => {
        this.setState({ addAddress: false });
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country,
});

export default connect(
    mapStateToProps,
    setLanguage
)(
    GoogleApiWrapper({
        apiKey: "AIzaSyBeEQoJ2XPNATY5YWXatV45hD6DyGt7Y0s",
    })(Checkout)
);
