import React, { Component } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setUser } from "../../../redux/actions-reducers/user";
import { apiService } from "../../../services/ApiService";
import { productService } from "../../../services/ProductService";
import CategoriesBlock from "../../blocks/CategoriesBlock";
import CompareBlock from "./CompareBlock";
import Skeleton from "react-loading-skeleton";
import { displayAlert, tabletScreen } from "../../../utils/misc";
import { Link } from "react-router-dom";
import Reviews from "../../blocks/Reviews";
import { generalServices } from "../../../services/GeneralService";
var whiteOptions = [];

class ProductDetails extends Component {
  state = {
    isLoading: false,
    activeTab: 1,
    images: [],
    product_option_group: [],
    product_option_group_obj: [],
    selectedRate: 0,
    options: [
      {
        name: "Color",
        values: [
          {
            id: "1",
            name: "i3",
            image: "/images/Rectangle 2046.png",
          },
          {
            id: "2",
            name: "i5",
            image: "/images/Rectangle 2045.png",
          },
          {
            id: "3",
            name: "i7",
            image: "/images/Rectangle 2040.png",
          },
        ],
      },
      {
        name: "Processor",
        values: [
          {
            id: "1",
            name: "i3",
            image: null,
          },
          {
            id: "2",
            name: "i5",
            image: null,
          },
          {
            id: "3",
            name: "i7",
            image: null,
          },
          {
            id: "4",
            name: "i9",
            image: null,
          },
        ],
      },
      {
        name: "Ram",
        values: [
          {
            id: "1",
            name: "6 GB",
            image: null,
          },
          {
            id: "2",
            name: "8 GB",
            image: null,
          },
          {
            id: "3",
            name: "10 GB",
            image: null,
          },
          {
            id: "12 GB",
            name: "i9",
            image: null,
          },
        ],
      },
      {
        name: "Display",
        values: [
          {
            id: "1",
            name: "6'",
            image: null,
          },
          {
            id: "2",
            name: "8'",
            image: null,
          },
          {
            id: "3",
            name: "10'",
            image: null,
          },
        ],
      },
    ],
    optionSelected: "",
    qty: 1,
    isCheckOutSideShow: false,
    CompareProducts: [],
    showInvoice: window.innerWidth > 992 ? true : false,
    productDetails: {},
    pageLoader: true,
    customerData: [],
    min_order: 1,
    start_Bar: [1, 2, 3, 4, 5],
    ReviewData: [],
    reviewRates: [],
    currentPage: 1,
    reviewMeta: {},
    compareLoader: true,
    compare: false,
    ordersCount: "",
    lastOptionId: -1,
    alsoSoldWith: [],
    fromSameBrand: [],
  };

  // render main img gallery carousel
  _renderCustomControls(item) {
    return (
      <InnerImageZoom
        zoomScale={1}
        src={item.original}
        zoomSrc={item.original}
      />
    );
  }
  // end render main img gallery carousel

  // render Thumb img gallery carousel

  _renderCustomThumbs(item) {
    // if (this.state.IsOptionSelected && this.state.OptionsImages.length < 1) {
    // } else {
    return <img src={item.thumbnail} alt="l" style={{ width: "100%" }} />;
    // }
  }
  // end render Thumb img gallery carousel

  componentDidMount() {
    Promise.all([
      this.getProductDetails(),
      this.getProductRelated(),
      this.getProductsFromSameBrand(),
      this.getProductSoldWith(),
      this.getProductReviews(),
      this.getUserProductCompared(),
    ]);
  }

  getProductReviews = async () => {
    const { data, meta } = await productService.getProductReviews(
      this.props.match.params.id,
      this.state.currentPage
    );
    this.setState({
      ReviewData: [...this.state.ReviewData, ...data],

      reviewMeta: meta,
    });
  };
  getProductRelated = async () => {
    const { data, success } = await productService.getProductRelated(
      this.props.match.params.id
    );

    if (!success) return;
    this.setState({
      customerData: data,
    });
  };

  getProductsFromSameBrand = async () => {
    const { data, success } = await productService.getProductsFromSameBrand(
      this.props.match.params.id
    );

    if (!success) return;
    this.setState({
      fromSameBrand: data,
    });
  };
  getProductSoldWith = async () => {
    const { data, success } = await productService.getProductSoldWith(
      this.props.match.params.id
    );

    if (!success) return;
    this.setState({
      alsoSoldWith: data,
    });
  };

  getProductDetails = async () => {
    const { data, success } = await productService.getProductDetails(
      this.props.match.params.id
    );

    if (!success) return;

    const arr = [
      {
        original: apiService.imageLink + data.main_image,
        thumbnail: apiService.imageLink + data.main_image,
      },
    ];
    data.images.forEach(({ image, image_thumb }) => {
      arr.push({
        original: apiService.imageLink + image,
        thumbnail: apiService.imageLink + image_thumb,
      });
    });

    let ActiveGroup = [];
    let ActiveGroupObj = [];
    if (data.options_groups.length > 0) {
      ActiveGroup = data.options_groups[0].values;
      ActiveGroupObj = data.options_groups[0];
    }

    this.setState({
      productDetails: data,
      product_option_group: ActiveGroup,
      product_option_group_obj: ActiveGroupObj,
      images: arr,
      pageLoader: false,
      min_order: data.options_groups[0].min_order,
      qty:
        data.options_groups[0].min_order > data.options_groups[0].qty
          ? data.options_groups[0].qty
          : data.options_groups[0].min_order,
      reviewRates: data.rates,
      compare: data.in_compare,
      ordersCount: data.store.ordersCount,
    });
  };

  getUserProductCompared = async () => {
    const { data, success } = await productService.getUserProductCompared();
    if (!success) return;

    const arr = [];
    data.forEach(
      ({
        title,
        main_image,
        id,
        rate,
        option,
        subtitle,
        in_favorite,
        in_cart,
        machine_name,
      }) => {
        arr.push({
          Name: title,
          id,
          image: apiService.imageLink + main_image,
          Rate: (
            <div className="compare-rate flex">
              <ul className="flex">
                {[...Array(rate).keys()].map((item, index) => {
                  return (
                    <li key={index}>
                      <i className="fa fa-star"></i>
                    </li>
                  );
                })}
              </ul>
              <span>{rate}</span>
            </div>
          ),
          Price: (
            <span className="compare-price">
              {option.price_after_discount} {this.props.country.currency}
            </span>
          ),
          option,
          subtitle,
          in_cart,
          in_favorite,
          machine_name,
          Memory: "1 TB",
          Processor: "Intel core i5",
          Ram: "8 GB",
          Cpu_speed: "4.6 GHz",
          Display_size: '18"',
          Display_resolution: "5.1.7 LP",
          Item_dimensions: "14.01*10.01*0.7 inch",
        });
      }
    );
    this.setState({
      CompareProducts: arr,
      compareLoader: false,
    });
  };

  render() {
    const {
      images,
      min_order,
      qty,
      activeTab,
      alsoSoldWith,
      fromSameBrand,

      customerData,
      isCheckOutSideShow,
      isSizeGide,
      CompareProducts,
      compareLoader,
      showInvoice,
      product_option_group_obj,
      ReviewData,
      reviewRates,
    } = this.state;
    const {
      i18n: {
        off,
        Quantity,
        alsoSoldWithText,
        fromSameBrandText,
        outOfStuck,
        ProcerdtoCheckout,
        Total,
        LeftStock,
        Only,
        Categories,
        Vendor,
        Brand,
        RelatedProducts,
        EstimatedDeliveryTimeWithin,
        days,
        showInCart,
        AddToCart,
        BuyNow,
        VenderInformation,
        TrustedDealer,
        added,
        ContinueShopping,
        description,
        reviews,
        specification,
        successOrders,
        SpecialOffer,
        Orders,
        Id,
        packaging
      },
      language,
    } = this.props;
    const { productDetails, product_option_group, pageLoader } = this.state;
    return (
      <div
        className={`product-Details ${language === "rtl" && "product-Details-arabic"
          }`}
      >
        {/* check out side bar start  */}
        {isCheckOutSideShow && (
          <div className="checkout-side-bar flex">
            <div
              className="overlay"
              onClick={() =>
                this.setState(
                  { isCheckOutSideShow: false },
                  this.getProductDetails
                )
              }
            ></div>

            <div className="checkout-side-bar__contents">
              {isSizeGide ? (
                <div className="size-gide-img">
                  <img
                    src={apiService.imageLink + productDetails?.guide?.image}
                    alt="/"
                  />
                </div>
              ) : (
                <>
                  <div className="checkout-side-bar__product ">
                    <div>
                      <img
                        src={apiService.imageLink + productDetails?.main_image}
                        alt="l"
                      />
                    </div>
                    <div className="checkout-side-bar__product__title--container">
                      <p className="checkout-side-bar__product__title m-0">
                        {productDetails?.title}
                      </p>
                      <span className="product-details__contents__center-details__sup-title">
                        {productDetails?.subtitle}
                      </span>

                      <p className="checkout-side-bar__added--label">
                        <span>{added}</span>
                        <i className="fa fa-check-circle m-1"></i>
                      </p>
                    </div>
                  </div>

                  <div className="checkout-side-bar__footer flex">
                    <span>{Total}</span>
                    <span>
                      {this.Price()}
                      {this.props.country.currency}
                    </span>
                  </div>
                  <div className="checkout-side-bar__buttons flex">
                    <Link to="/cart" className="submit-button2">
                      <span>{ProcerdtoCheckout}</span>
                    </Link>
                    <button
                      className="submit-button shadow"
                      onClick={() => window.history.back()}
                    >
                      {ContinueShopping}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {/* check out side bar end  */}

        {/* Breadcrumb start  */}
        <div className="Breadcrumb">
          {/*   <Container>
                        <div className="Breadcrumb__contents flex">
                            <span>{Categories}</span>
                            <div></div>
                            <a href="/">Computer</a>
                            <img src="/images/bread.svg" alt="l" />
                            <a href="/">Audio & Video</a>
                        </div>
                    </Container>*/}
        </div>
        {/* Breadcrumb end  */}

        <div className="product-details__contents">
          <Container>
            <Row>
              {/* product images start  */}
              <Col md={6} lg={5}>
                {pageLoader ? (
                  <Skeleton height={tabletScreen ? 200 : 500} />
                ) : (
                  <>
                    <ImageGallery
                      slideOnThumbnailOver
                      items={images}
                      thumbnailPosition="left"
                      showNav={true}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      showThumbnails={true}
                      renderItem={(images) =>
                        this._renderCustomControls(images)
                      }
                      renderThumbInner={(images) =>
                        this._renderCustomThumbs(images)
                      }
                    />

                    <div className="product-details__contents__compare-fav flex">
                      <button
                        onClick={() =>
                          this.addRemoveCompares(productDetails?.id)
                        }
                      >
                        {/* <img
                                                    // src="/images/compareWithColor.svg"
                                                    src={`/images/${productDetails.in_compare ? "on.svg" : "off.svg"}`}
                                                    alt="search"
                                                /> */}

                        <img
                          src={`/images/${
                            productDetails.in_compare
                              ? "newCompC.svg"
                              : "newComp.svg"
                          }`}
                          alt="ss"
                        />
                      </button>
                      <button
                        onClick={() =>
                          this.addRemoveFavorites(productDetails?.id)
                        }
                      >
                        {/* <i className={productDetails?.in_favorite ? "fa fa-heart" : "fa fa-heart-o"}></i> */}
                        {productDetails?.in_favorite ? (
                          <img src="/images/heartColor.svg" alt="heart" />
                        ) : (
                          <img src="/images/heartCC.svg" alt="heart" />
                        )}
                      </button>

                      <button
                        className="showInvoice-button"
                        onClick={() =>
                          this.setState({
                            showInvoice: !showInvoice,
                          })
                        }
                      >
                        <img src="/images/invoice.svg" alt="search" />
                      </button>
                    </div>
                  </>
                )}
              </Col>
              {/* product images end  */}

              <Col md={6} lg={4}>
                {pageLoader ? (
                  <Skeleton height={tabletScreen ? 200 : 500} />
                ) : (
                  <div className="product-details__contents__center-details">
                    {/* store name start  */}
                    <div className="store-name">
                      {/* <span>{Vendor}</span> */}
                      <span>{Id}</span>
                      <div></div>
                      {/* <a href="/">{productDetails?.store?.name}</a> */}
                      {/* <a href="/">{productDetails?.id}</a> */}
                      <a href="/">{productDetails?.machine_name && productDetails?.machine_name.replace(/^products_RZ/i, '')}</a>
                    </div>
                    {console.log('productDetails', productDetails)}
                    {productDetails.component_no_web && productDetails.unit_code_web && (
                      <div className="store-name">
                        <span>{packaging} : </span>
                        <span className="text-dark">{`${productDetails.component_no_web} / ${productDetails.unit_code_web}`}</span>
                      </div>
                    )}
                    {/* store name end  */}
                    {/* store name start  */}
                    <div className="store-name">
                      <span>{Brand}</span> :{/* <div></div> */}
                      <Link
                        to={`/BrandProducts/${productDetails?.brand?.machine_name}/1`}
                      >
                        {productDetails?.brand?.name}
                      </Link>
                    </div>
                    {/* store name end  */}
                    {/* <img src={apiService.imageLink + productDetails?.images[0].main_image} alt="l" /> */}
                    <p className="text-capitalize">{productDetails?.title}</p>
                    <span className="product-details__contents__center-details__sup-title">
                      {productDetails?.subtitle}
                    </span>

                    {/* product rates and counts start  */}
                    <div className="product-rates-orders-count flex">
                      <ul className="flex">
                        <li>
                          <i
                            className={
                              productDetails?.rate > 0
                                ? "fa fa-star"
                                : "fa fa-star-o"
                            }
                          ></i>
                        </li>

                        <li>
                          <i
                            className={
                              productDetails?.rate > 1
                                ? "fa fa-star"
                                : "fa fa-star-o"
                            }
                          ></i>
                        </li>

                        <li>
                          <i
                            className={
                              productDetails?.rate > 2
                                ? "fa fa-star"
                                : "fa fa-star-o"
                            }
                          ></i>
                        </li>

                        <li>
                          <i
                            className={
                              productDetails?.rate > 3
                                ? "fa fa-star"
                                : "fa fa-star-o"
                            }
                          ></i>
                        </li>

                        <li>
                          <i
                            className={
                              productDetails?.rate > 4
                                ? "fa fa-star"
                                : "fa fa-star-o"
                            }
                          ></i>
                        </li>
                      </ul>
                      <div></div>

                      <span>
                        {productDetails?.ordersCount} {Orders}
                      </span>
                    </div>
                    {/* product rates and counts end  */}

                    {/* products options start  */}
                    <div className="product-details__options">
                      {productDetails?.options?.map(
                        ({ name, values }, index) => {
                          return (
                            <React.Fragment key={index}>
                              {/* <span>{name}</span> */}
                              {values.length < 1 ? null : <span> {name} </span>}
                              <ul className="flex">
                                {values.map(({ id, name: name2, image }) => {
                                  return image ? (
                                    <li
                                      key={id}
                                      // className={optionSelected === id ? "optionSelected" : ""}

                                      className={
                                        product_option_group.indexOf(id) > -1
                                          ? "optionSelected optionImg"
                                          : "optionImg"
                                      }
                                      onClick={() => {
                                        var current_value = id;
                                        let stopLoop = false;
                                        var whiteOptions = [];

                                        productDetails.options_groups.forEach(
                                          (group) => {
                                            if (
                                              group.values.includes(
                                                current_value
                                              ) &&
                                              whiteOptions.includes(
                                                current_value
                                              )
                                            ) {
                                              this.setState({
                                                lastOptionId: current_value,
                                                product_option_group:
                                                  group.values,
                                                product_option_group_obj: group,
                                                qty:
                                                  group.min_order < group.qty
                                                    ? group.min_order
                                                    : group.qty,
                                                min_order: group.min_order,
                                              });
                                              stopLoop = true;
                                            } else {
                                              if (
                                                group.values.includes(
                                                  current_value
                                                )
                                              ) {
                                                whiteOptions = group.values;

                                                this.setState({
                                                  lastOptionId: current_value,

                                                  product_option_group:
                                                    group.values,
                                                  product_option_group_obj:
                                                    group,
                                                  qty:
                                                    group.min_order < group.qty
                                                      ? group.min_order
                                                      : group.qty,
                                                  min_order: group.min_order,
                                                });
                                              }
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      <div></div>
                                      <img
                                        src={apiService.imageLink + image}
                                        alt="l"
                                      />
                                    </li>
                                  ) : (
                                    <li
                                      className={
                                        product_option_group.indexOf(id) > -1
                                          ? "optionSelected"
                                          : ""
                                      }
                                      onClick={() => {
                                        var current_value = id;
                                        let stopLoop = false;

                                        productDetails.options_groups.map(
                                          (group) => {
                                            if (!stopLoop) {
                                              if (
                                                group.values.includes(
                                                  current_value
                                                ) &&
                                                whiteOptions.includes(
                                                  current_value
                                                )
                                              ) {
                                                this.setState({
                                                  lastOptionId: current_value,
                                                  product_option_group:
                                                    group.values,
                                                  product_option_group_obj:
                                                    group,
                                                  qty:
                                                    group.min_order < group.qty
                                                      ? group.min_order
                                                      : group.qty,
                                                  min_order: group.min_order,
                                                });
                                                stopLoop = true;
                                              } else {
                                                if (
                                                  group.values.includes(
                                                    current_value
                                                  )
                                                ) {
                                                  whiteOptions = group.values;
                                                  this.setState({
                                                    lastOptionId: current_value,
                                                    product_option_group:
                                                      group.values,
                                                    product_option_group_obj:
                                                      group,
                                                    qty:
                                                      group.min_order <
                                                        group.qty
                                                        ? group.min_order
                                                        : group.qty,
                                                    min_order: group.min_order,
                                                  });
                                                }
                                              }
                                            }
                                          }
                                        );

                                        // this.setState({ optionSelected: id })
                                      }}
                                    >
                                      {name2}
                                    </li>
                                  );
                                })}
                              </ul>
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                    {/* products options end  */}

                    {/* product quantity start  */}
                    {product_option_group_obj.qty === 0 ? null : (
                      <div className="product-details-quantity flex">
                        <p>{Quantity}</p>
                        <div className="qty flex">
                          {/* mins  button start  */}
                          <span
                            onClick={() => {
                              if (qty > min_order) {
                                this.setState({
                                  qty: qty - 1,
                                });
                              }
                            }}
                          >
                            <i className="fa fa-minus"></i>
                          </span>
                          {/* mins  button end  */}

                          {/* input (value) qty start  */}
                          <input
                            type="tel"
                            value={qty}
                            onBlur={() => {
                              if (!qty) {
                                this.setState({
                                  qty: min_order,
                                });
                              }
                            }}
                            onChange={({ target: { value } }) => {
                              if (!value) {
                                this.setState({
                                  qty: null,
                                });
                                return;
                              }
                              if (value < product_option_group_obj?.qty) {
                                if (value < min_order) {
                                  this.setState({
                                    qty: min_order,
                                  });
                                } else {
                                  this.setState({
                                    // qty: value[0] == 0 ? value.slice(1, value.length) : value,
                                    qty: parseInt(value),
                                  });
                                }
                              } else {
                                this.setState({
                                  qty: product_option_group_obj.qty,
                                });
                              }
                            }}
                          />
                          {/* input (value) qty end  */}

                          {/* plus button start  */}
                          <span
                            onClick={() => {
                              if (qty < product_option_group_obj?.qty) {
                                this.setState({
                                  qty: qty + 1,
                                });
                              }
                            }}
                          >
                            <i className="fa fa-plus"></i>
                          </span>
                          {/* plus button end  */}
                        </div>
                        {/* Sizes Gide button start  */}

                        <p
                          className="Sizes-Gide-button"
                          onClick={() => {
                            this.setState({
                              isSizeGide: true,
                              isCheckOutSideShow: true,
                            });
                          }}
                        >
                          {productDetails?.guide?.name}
                        </p>

                        {/* Sizes Gide button end  */}
                      </div>
                    )}
                    {/* product quantity end  */}
                  </div>
                )}
              </Col>
              <Col md={12} lg={3}>
                {/* product total price and delivery details start  */}
                {pageLoader ? (
                  <Skeleton height={tabletScreen ? 200 : 500} />
                ) : (
                  <div
                    className={
                      window.innerWidth < 992 &&
                      showInvoice &&
                      "product-details__checkout-info-parent"
                    }
                  >
                    <div
                      className="overlay"
                      onClick={() =>
                        this.setState({
                          showInvoice: false,
                        })
                      }
                    ></div>

                    <div className={`product-details__checkout-info`}>
                      {/* <div className={`product-details__checkout-info ${!showInvoice && "d-none"}`}> */}
                      {/* total product price start  */}
                      <h3>
                        {this.Price()} {this.props.country.currency}
                        {product_option_group_obj?.discount_percentage > 1 && (
                          <div className="product-details__oldPrice">
                            <span>
                              {(product_option_group_obj?.price * qty).toFixed(
                                2
                              )}
                            </span>
                            <span>
                              {product_option_group_obj?.discount_percentage}%{" "}
                              {off}
                            </span>
                          </div>
                        )}
                      </h3>
                      {/* total product price end  */}

                      <div className="estimated-delivery flex">
                        <img src="/images/calender.svg" alt="l" />
                        <span>
                          {EstimatedDeliveryTimeWithin}{" "}
                          {product_option_group_obj?.preparing_days} {days}
                        </span>
                      </div>
                      {/* location-delivery start  */}
                      <div className="location-delivery flex">
                        <img src="/images/Bold-Outline.svg" alt="l" />
                        <span>{productDetails?.store?.place.name}</span>
                      </div>
                      {/* location-delivery end  */}

                      {/* location-delivery start  */}
                      {/* <div className="location-delivery flex">
                                                <img src="/images/Bold-Outline.svg" alt="l" />
                                                <span>
                                                    {discount_percentage}
                                                    {product_option_group_obj?.discount_percentage} %
                                                </span>
                                            </div> */}
                      {/* location-delivery end  */}

                      <div className="in-stock flex">
                        <img src="/images/box.svg" alt="l" />
                        <span>
                          {Only} {product_option_group_obj?.qty} {LeftStock}
                        </span>
                      </div>

                      {/* product-details__buttons start  */}
                      <div className="product-details__buttons">
                        {product_option_group_obj?.qty > 0 ? (
                          <button
                            className="add-to-cart-button flex"
                            onClick={() => {
                              if (product_option_group_obj?.in_cart) {
                                window.location.href = "/cart";
                              } else {
                                this.setState({
                                  isCheckOutSideShow: true,
                                  isSizeGide: false,
                                });
                                this.addToCart(
                                  product_option_group_obj.id,
                                  product_option_group_obj?.in_cart ? 0 : 1
                                );
                              }
                            }}
                          >
                            <img src="/images/bag.svg" alt="l" />
                            {product_option_group_obj?.in_cart
                              ? showInCart
                              : AddToCart}
                          </button>
                        ) : (
                          <button className="outOfStuck-button flex" disabled>
                            {/* <img src="/images/fi-rr-shopping-cart.svg" alt="l" /> */}
                            {outOfStuck}
                          </button>
                        )}
                        {product_option_group_obj?.in_cart ||
                          product_option_group_obj?.qty < 1 ? null : (
                          <button
                            className="submit-button2"
                            onClick={async () => {
                              await this.addToCart(
                                product_option_group_obj.id,
                                1
                              );

                              window.location.href = "/cart";
                            }}
                          >
                            {BuyNow}
                          </button>
                        )}
                      </div>
                      {/* product-details__buttons end  */}
                      {productDetails.benefits.length > 0 && (
                        <div className="security-checkout-note">
                          {productDetails?.benefits?.map(
                            ({ name, id, icon, url }) => {
                              return (
                                <a
                                  href={url}
                                  target="_blank"
                                  className="location-delivery flex"
                                  key={id}
                                >
                                  <img
                                    src={apiService.imageLink + icon}
                                    alt="l"
                                  />
                                  <span>{name}</span>
                                </a>
                              );
                            }
                          )}
                        </div>
                      )}
                      <h5>{VenderInformation}</h5>

                      {/* Trusted Dealer start  */}

                      <div className="estimated-delivery flex">
                        <img src="/images/fi-rr-badge.svg" alt="l" />
                        <span>{TrustedDealer}</span>
                      </div>
                      {/* Trusted Dealer end  */}

                      <div className="product-details__checkout-info__product-rates flex">
                        <span>{productDetails?.store?.rate}</span>
                        <div>
                          <ul className="flex">
                            <li>
                              <i
                                className={
                                  productDetails?.store?.rate > 0
                                    ? "fa fa-star"
                                    : "fa fa-star-o"
                                }
                              ></i>
                            </li>

                            <li>
                              <i
                                className={
                                  productDetails?.store?.rate > 1
                                    ? "fa fa-star"
                                    : "fa fa-star-o"
                                }
                              ></i>
                            </li>

                            <li>
                              <i
                                className={
                                  productDetails?.store?.rate > 2
                                    ? "fa fa-star"
                                    : "fa fa-star-o"
                                }
                              ></i>
                            </li>
                            <li>
                              <i
                                className={
                                  productDetails?.store?.rate > 3
                                    ? "fa fa-star"
                                    : "fa fa-star-o"
                                }
                              ></i>
                            </li>
                            <li>
                              <i
                                className={
                                  productDetails?.store?.rate > 4
                                    ? "fa fa-star"
                                    : "fa fa-star-o"
                                }
                              ></i>
                            </li>
                          </ul>
                          <p>
                            {productDetails?.store?.ordersCount} {successOrders}
                          </p>
                        </div>
                      </div>

                      {/* <a
                                                href="/"
                                                className="more-info flex"
                                            >
                                                {MoreInformation}
                                                <img
                                                    src="/images/arrow-right-short.png"
                                                    alt="/"
                                                />
                                            </a> */}
                    </div>
                  </div>
                )}
                {/* product total price and delivery details end  */}
              </Col>

              <Col md={12} lg={9}>
                {pageLoader ? (
                  <Skeleton
                    height={tabletScreen ? 200 : 500}
                    className="mt-3"
                  />
                ) : (
                  <div className="product-details__description-reviews">
                    <ul className="product-details__description-reviews-ul">
                      <li
                        onClick={() =>
                          this.setState({
                            activeTab: 1,
                          })
                        }
                      >
                        <span className={activeTab === 1 && "activeTab"}>
                          {description}
                        </span>
                      </li>
                      <div></div>
                      {productDetails?.specifications?.length > 0 && (
                        <>
                          <li
                            onClick={() =>
                              this.setState({
                                activeTab: 2,
                              })
                            }
                          >
                            <span className={activeTab === 2 && "activeTab"}>
                              {specification}
                            </span>
                          </li>

                          <div></div>
                        </>
                      )}
                      {
                        <li
                          onClick={() =>
                            this.setState({
                              activeTab: 3,
                            })
                          }
                        >
                          <span className={activeTab === 3 && "activeTab"}>
                            {reviews}
                          </span>
                        </li>
                      }
                    </ul>
                    <div className="product-details__description-reviews__contents">
                      {activeTab === 1 ? (
                        <div className="product-details__description-reviews__contents__div">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: productDetails?.description,
                            }}
                          />
                        </div>
                      ) : activeTab === 2 ? (
                        <Table striped bordered hover>
                          {/* <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Username</th>
                                                        </tr>
                                                    </thead> */}
                          <tbody>
                            {productDetails?.specifications.map(
                              ({ id, key, value }) => {
                                return (
                                  <tr key={id}>
                                    <td>{key}</td>
                                    <td>{value}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <Reviews
                          rateBar={this.rateBar}
                          data={ReviewData}
                          refetchData={() => this.getProductReviews()}
                          reviewRates={reviewRates}
                          loadMoreComments={this.loadMoreComments}
                          isLoading={this.state.isLoading}
                          meta={this.state.reviewMeta}
                          currentPage={this.state.currentPage}
                          ordersCount={productDetails?.ordersCount}
                          id={productDetails.id}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Col>
              {/* <Col md={12} lg={3}>
                                {pageLoader ? (
                                    <Skeleton height={tabletScreen ? 150 : 350} className="mt-3" />
                                ) : (
                                    <div className="special-offer">
                                        <a href="/">
                                            <img src="/images/53011fvq_huawei_laptop_01_m_p.png" alt="l" />
                                            <span className="special-offer__label">{SpecialOffer}</span>

                                            <p>Apple Mac Book Por 14" 2019 Cor i7</p>
                                            <div className="product__price__new-price">
                                                <span> {this.props.country.currency}</span>
                                                <span>
                                                    174.<sup>99 </sup>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                )}
                            </Col> */}
            </Row>
            {customerData.length > 0 && (
              <CategoriesBlock
                title={RelatedProducts}
                data={customerData}
                reFetchData={() => this.getProductRelated()}
                isRelated={true}
              />
            )}
            {alsoSoldWith.length > 0 && (
              <CategoriesBlock
                title={alsoSoldWithText}
                data={alsoSoldWith}
                reFetchData={() => this.getProductRelated()}
                isRelated={true}
              />
            )}
            {fromSameBrand.length > 0 && (
              <CategoriesBlock
                title={fromSameBrandText}
                data={fromSameBrand}
                reFetchData={() => this.getProductRelated()}
                isRelated={true}
              />
            )}

            {customerData.length > 0 &&
              (compareLoader ? (
                <Skeleton height={tabletScreen ? 300 : 600} className="my-4" />
              ) : (
                CompareProducts.length > 0 && (
                  <CompareBlock
                    data={CompareProducts}
                    features={["Rate", "Price"]}
                    reFetchData={() => this.getUserProductCompared()}
                  // hideItemOption={false}
                  // onHide = {onHideCallback}
                  />
                )
              ))}
          </Container>
        </div>
      </div>
    );
  }
  loadMoreComments = () => {
    this.setState(
      {
        isLoading: true,
        currentPage: this.state.currentPage + 1,
      },
      () => {
        this.getProductReviews(
          this.props.match.params.id,
          this.state.currentPage
        );
        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
        }, 500);
      }
    );
  };
  newPrice = () => {
    const { productDetails } = this.state;

    let discountAmount =
      (productDetails?.price * productDetails?.campaign.discount_percentage) /
      100;

    let priceAfterDiscount = 0;
    if (discountAmount > productDetails?.campaign.fixed_amount) {
      priceAfterDiscount =
        productDetails?.price - productDetails?.campaign.fixed_amount;

      return priceAfterDiscount;
    } else {
      priceAfterDiscount = productDetails?.price - discountAmount;
      return priceAfterDiscount;
    }
  };
  Price = () => {
    const { product_option_group_obj, qty } = this.state;

    return (product_option_group_obj?.price_after_discount * qty).toFixed(2);
  };

  addRemoveFavorites = async (id) => {
    const { success } = await productService.addOrRemoveFavorite(id);
    if (!success) return;
    this.getIconData();
    this.getProductDetails();
  };

  addToCart = async (id) => {
    const { qty } = this.state;
    const { success } = await productService.addToCart(id, qty);
    if (!success) return;
    // displayAlert(
    //     "Success",
    //     qty === 0 ? "Deleted from Cart" : "Added To Cart",
    //     "success"
    // );
    this.getIconData();
    // this.getProductDetails();
  };

  addRemoveCompares = async (id) => {
    const { compare } = this.state;
    const { success } = await productService.addRemoveCompares(id);
    const {
      success: successText,
      CompareProductDeleted,
      CompareProductAdded,
    } = this.props.i18n;
    if (!success) return;

    this.getIconData();
    this.getProductDetails();

    displayAlert(
      successText,
      compare ? CompareProductDeleted : CompareProductAdded,
      "success",
      "noButtons",
      2000
    );
    this.setState({ compare: !this.state.compare });
  };
  getIconData = async () => {
    const { data, success } = await generalServices.getIconData();
    if (!success) return;

    this.props.setUser({
      cart: data.cart_counter,
      fav: data.fav_counter,
      compare: data.compare_counter,
    });
  };
  getCartIDs = async () => {
    const { data, success } = await generalServices.getCartIDs();
    if (!success) return;
    this.props.setUser({
      cartIds: data,
    });
  };
  // The rateBar() function in Review.jsx
  rateBar = (rate, rate_count) => {
    // const { selectedRate, reviewRates } = this.state;
    return (
      <div className="bar-row">
        <ul className="bar">
          <li>
            <i className={rate > 0 ? "fa fa-star" : "fa fa-star-o"}></i>
          </li>
          <li>
            <i className={rate > 1 ? "fa fa-star" : "fa fa-star-o"}></i>
          </li>
          <li>
            <i className={rate > 2 ? "fa fa-star" : "fa fa-star-o"}></i>
          </li>
          <li>
            <i className={rate > 3 ? "fa fa-star" : "fa fa-star-o"}></i>
          </li>
          <li>
            <i className={rate > 4 ? "fa fa-star" : "fa fa-star-o"}></i>
          </li>
        </ul>
        <div className="evaluator-number">
          <span> {rate}</span>
          {rate_count > -1 ? <span> ({rate_count})</span> : null}
        </div>
      </div>
    );
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "ltr" ? Global.en : Global.ar,
  currentUser,
  country,
});

export default connect(mapStateToProps, { setUser })(ProductDetails);
