import React, { Component } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { generalServices } from "../../../services/GeneralService";
import { productService } from "../../../services/ProductService";
import { displayAlert } from "../../../utils/misc";
import ProductCompare from "./ProductCompare";

class CompareBlock extends Component {
    constructor(props) {
        super(props);

        this.hideProduct = this.hideProduct.bind(this);
        var handleHide = this.hideProduct;
        if (this.props.onHide) {
            handleHide = this.props.onHide;
        }
        this.state = {
            productDataList: this.props.data,
            productListTodisplay: this.props.data,
            handleHideProduct: handleHide,
            hideItemOption: false,
        };
    }

    state = {
        compare: true,
    };

    render() {
        const {
            language,
            withOutHeader,
            i18n: { VIEWALL, CompareWithSimilarItems },
        } = this.props;

        return (
            <div className={`categories-block ${language === "rtl" && "categories-block-arabic"}`}>
                {!withOutHeader && (
                    <div className="categories-block__header">
                        <h3>{CompareWithSimilarItems}</h3>
                        <div className="hr" />
                        <a href="/Compare" className="view-all">
                            <span>{VIEWALL}</span>
                            <img src="/images/arrow-small-right.svg" alt="l" />
                        </a>
                    </div>
                )}
                <div className={`compare-table ${language === "rtl" && "compare-table-arabic"}`}>
                    <Table className="compare-result-table" striped>
                        {this.generateHeader(this.state.productListTodisplay)}
                        {this.generateBody(this.state.productListTodisplay)}
                    </Table>
                </div>
            </div>
        );
    }

    generateHeader(data) {
        return (
            <thead>
                <tr>
                    <th>
                        <Row className="product-image-header">
                            <Col md={12} sm={6} />
                        </Row>
                        <Row className="blue-header">
                            <Col md={12} sm={6}></Col>
                        </Row>
                    </th>
                    {data?.map((product, i) => (
                        <ProductCompare product={product} i={i} compare={this.state.compare} addRemoveCompares={this.addRemoveCompares} reFetchData={() => this.props.reFetchData()} />
                    ))}
                </tr>
            </thead>
        );
    }

    getIconData = async () => {
        const { data, success } = await generalServices.getIconData();
        if (!success) return;

        this.props.setUser({
            cart: data.cart_counter,
            fav: data.fav_counter,
            compare: data.compare_counter,
        });
    };

    addRemoveCompares = async (id) => {
        const { success } = await productService.addRemoveCompares(id);
        const { success: successText, CompareProductDeleted } = this.props.i18n;
        if (!success) return;
        this.setState({ compare: !this.state.compare });
        this.hideProduct(id);
        this.getIconData();
        displayAlert(successText, CompareProductDeleted, "success", "noButtons", 2000);

        this.props.reFetchData();
    };
    productHeaderDetails(product, i) {
        return (
            <th key={i} className="btn-table-header">
                <div className="compare-product">
                    <div className="compare-product__fav" onClick={() => this.addRemoveFavorites(product?.id)}>
                        {/* <i
                            className={
                                product?.in_favorite
                                    ? "fa fa-heart"
                                    : "fa fa-heart-o"
                            }
                        /> */}

                        {product?.in_favorite ? <img src="/images/heartColor.svg" alt="heart" /> : <img src="/images/heart.svg" alt="heart" />}
                    </div>
                    {/* product img start  */}
                    <div className="compare-product__img--container">
                        <img
                            src={product.image}
                            alt="product img"
                            className="product-image"
                            id={product.image}
                            onClick={() => {
                                window.location.href = "/ProductDetails/" + product.machine_name;
                            }}
                        />
                    </div>
                    {/* product img end  */}
                    {/* product name start  */}

                    <h6
                        onClick={() => {
                            window.location.href = "/ProductDetails/" + product.machine_name;
                        }}
                        // className="compare-product__title"
                    >
                        {product.Name}
                    </h6>
                    {/* product name end  */}

                    <p>{product.subtitle}</p>
                    <button
                        className="compare-product-button flex"
                        onClick={() => {
                            this.addToCart(product?.option.id, 1);
                        }}
                    >
                        <img src="/images/fi-rr-shopping-cart.svg" alt="l" />

                        {this.props.i18n.AddToCart}
                    </button>
                </div>
            </th>
        );
    }

    generateBody(data) {
        return <tbody>{this.props.features?.map((feature) => this.productComparisonFeature(feature, data))}</tbody>;
    }

    productComparisonFeature(feature, data) {
        if (data?.find((item) => item[feature] !== "N/A")) {
            return (
                <tr>
                    <td className="compare-header">{feature}</td>
                    {data?.map((product) => this.getFeatureForProduct(product, feature))}
                </tr>
            );
        }
    }
    getFeatureForProduct(product, feature) {
        return <td className="compare-value">{product[feature]}</td>;
    }

    hideProduct(product_id) {
        var temp_list = this.state.productListTodisplay;
        temp_list = temp_list.filter((item) => item.id !== product_id);
        this.setState({
            productListTodisplay: temp_list,
        });
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage, setUser })(CompareBlock);
