import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";

class Filter extends Component {
    state = {
        products: [1, 2, 3, 4],
        products2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
        brands: [],
        price_from: "",
        price_to: "",
        rate: [],
        options_values: [],
        free_shipping: false,
        new_arrivals: false,
        isChecked: false,
        discount: false,
        arr: [],
    };

    render() {
        const { brands, options_values, new_arrivals, free_shipping, arr, discount } = this.state;

        const {
            language,
            data,
            i18n: { filter, resetFilter, Min, Max, FreeShipping, NewArrivals, Brands, Price, Rate, Up, Discount },
        } = this.props;

        // Push new filter to state

        return (
            <div className={`products-filter ${language === "rtl" && "products-filter-arabic"}`}>
                <div className="FilterTitle">
                    <h1>{filter}</h1>
                    <a onClick={() => this.resetFilter()}>{resetFilter}</a>
                </div>
                <div className="AccordionFilters">
                    <Accordion accordion={true} defaultActiveKey={"0"} expandMode={"multiple"} flush>
                        {/** Discount Accordion */}
                        {data?.brands?.length > 0 && (
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>{Brands}</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        {data?.brands?.map(({ name, id, machine_name }) => {
                                            return (
                                                <li key={id}>
                                                    <label
                                                        onClick={() => {
                                                            this.setState({
                                                                isChecked: id,
                                                            });
                                                        }}
                                                        className={`CehckBoxContainer ${brands.includes(id) && "CehckBoxContainerActive"}`}
                                                    >
                                                        {name}
                                                        <input type="checkbox" checked={brands.includes(id) && "checked"} onClick={() => this.pushNewFilter("brands", id)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                        {/** Discount Accordion End */}
                        {/** Price Accordion */}
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>{Price}</Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <div className="btn-group">
                                        {/* <button type="button">$ Min</button> */}

                                        <input
                                            type="number"
                                            onChange={({ target: { value } }) =>
                                                this.setState({
                                                    price_from: value,
                                                })
                                            }
                                            placeholder={Min}
                                        />

                                        <input
                                            type="number"
                                            onChange={({ target: { value } }) =>
                                                this.setState({
                                                    price_to: value,
                                                })
                                            }
                                            placeholder={Max}
                                        />

                                        <button
                                            type="button"
                                            onClick={() => {
                                                this.pushNewFilter("price_from", this.state.price_from);
                                                this.pushNewFilter("price_to", this.state.price_to);
                                            }}
                                        >
                                            GO
                                        </button>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        {/** Price Accordion End */}

                        {/** Rate Accordion */}
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>{Rate}</Accordion.Header>
                            <Accordion.Body>
                                <form>
                                    <ul>
                                        <li>
                                            <label className={this.state.rate.includes(5) ? "CehckBoxContainer ActiveFa" : "CehckBoxContainer"}>
                                                <div className="primary--color">
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <span className="CehckBoxContainer__upText"> </span>
                                                </div>
                                                <input
                                                    type="checkbox"
                                                    name="rate_check"
                                                    data-rate="5"
                                                    onClick={() => {
                                                        this.pushNewFilter("rate", 5);
                                                    }}
                                                    checked={this.state.rate.includes(5)}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>

                                        <li>
                                            <label className={this.state.rate.includes(4) ? "CehckBoxContainer ActiveFa" : "CehckBoxContainer"}>
                                                <div className="primary--color">
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                    <span className="CehckBoxContainer__upText"> </span>
                                                </div>
                                                <input
                                                    type="checkbox"
                                                    name="rate_check"
                                                    data-rate="4"
                                                    onClick={() => {
                                                        this.pushNewFilter("rate", 4);
                                                    }}
                                                    checked={this.state.rate.includes(4)}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>

                                        <li>
                                            <label className={this.state.rate.includes(3) ? "CehckBoxContainer ActiveFa" : "CehckBoxContainer"}>
                                                <div>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                    <span className="CehckBoxContainer__upText"> </span>
                                                </div>

                                                <input
                                                    type="checkbox"
                                                    name="rate_check"
                                                    data-rate="3"
                                                    onClick={() => {
                                                        this.pushNewFilter("rate", 3);
                                                    }}
                                                    checked={this.state.rate.includes(3)}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>

                                        <li>
                                            <label className={this.state.rate.includes(2) ? "CehckBoxContainer ActiveFa" : "CehckBoxContainer"}>
                                                <div>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                    <span className="CehckBoxContainer__upText"> </span>
                                                </div>

                                                <input
                                                    type="checkbox"
                                                    name="rate_check"
                                                    data-rate="2"
                                                    onClick={() => {
                                                        this.pushNewFilter("rate", 2);
                                                    }}
                                                    checked={this.state.rate.includes(2)}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>

                                        <li>
                                            <label className={this.state.rate.includes(1) ? "CehckBoxContainer ActiveFa" : "CehckBoxContainer"}>
                                                <div>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                    <span className="CehckBoxContainer__upText"> </span>
                                                </div>

                                                <input
                                                    type="checkbox"
                                                    name="rate_check"
                                                    data-rate="1"
                                                    onClick={() => {
                                                        this.pushNewFilter("rate", 1);
                                                    }}
                                                    checked={this.state.rate.includes(1)}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                </form>
                            </Accordion.Body>
                        </Accordion.Item>
                        {/** Rate Accordion End */}
                        {/* Colors Accordion */}
                        {data.options?.map(({ name, id, values }) => {
                            return (
                                <Accordion.Item eventKey={id}>
                                    <Accordion.Header>{name}</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            {values.map(({ name, id, image }) => {
                                                return (
                                                    <li key={id}>
                                                        <label className={`CehckBoxContainer ${options_values.includes(id) && "CehckBoxContainerActive"}`}>
                                                            {name}

                                                            <input type="checkbox" checked={this.state.options_values.includes(id) && "checked"} onClick={() => this.pushNewFilter("options_values", id)} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                        {/* Colors Accordion End */}
                        {/** <!-- Free Shipping  switch --> */}
                        {/* <div className="SwitchButt">
                            <h3>{FreeShipping} </h3>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={free_shipping}
                                    onClick={() =>
                                        this.pushNewFilter(
                                            "free_shipping",
                                            !this.state.free_shipping
                                        )
                                    }
                                />
                                <span
                                    className={`slider round
                                        ${free_shipping ? "open_switch" : null}
                                        `}
                                ></span>
                            </label>
                        </div> */}

                        {/** <!-- New Arrivals switch --> */}
                        <div className="SwitchButt">
                            <h3>{NewArrivals}</h3>
                            <label className="switch">
                                <input type="checkbox" checked={new_arrivals} onClick={() => this.pushNewFilter("new_arrivals", !this.state.new_arrivals)} />
                                <span
                                    className={`slider round
                                        ${new_arrivals ? "open_switch" : null}
                                        `}
                                ></span>
                            </label>
                        </div>
                        {/** <!-- Dicount Shipping  switch --> */}
                        <div className="SwitchButt">
                            <h3>{Discount} </h3>
                            <label className="switch">
                                <input type="checkbox" checked={discount} onClick={() => this.pushNewFilter("discount", !this.state.discount)} />
                                <span
                                    className={`slider round
                                        ${discount ? "open_switch" : null}
                                        `}
                                ></span>
                            </label>
                        </div>
                    </Accordion>
                </div>
            </div>
        );
    }

    sendFilterData = () => {
        this.props.getFilterData({
            options_values: this.state.options_values,
            brands: this.state.brands,
            rate: this.state.rate,
            price_from: this.state.price_from,
            price_to: this.state.price_to,
            free_shipping: this.state.free_shipping,
            new_arrivals: this.state.new_arrivals,
            discount: this.state.discount,
        });
    };

    pushNewFilter = (filterType, value) => {
        if (filterType === "brands" || filterType === "options_values" || filterType === "rate") {
            if (this.state[`${filterType}`].includes(value)) {
                this.setState(
                    {
                        [filterType]: this.state[`${filterType}`].filter((element) => element !== value),
                    },
                    () => {
                        this.sendFilterData();
                    }
                );
            } else {
                this.setState(
                    {
                        [filterType]: [...this.state[`${filterType}`], value],
                    },
                    () => {
                        this.sendFilterData();
                    }
                );
            }
        } else {
            this.setState(
                {
                    [filterType]: value,
                },
                () => {
                    this.sendFilterData();
                }
            );
        }
    };
    resetFilter = () => {
        const { options_values, brands, rate, price_from, price_to, free_shipping, new_arrivals, discount } = this.state;
        this.setState({
            options_values: [],
            brands: [],
            rate: "",
            price_from: "",
            price_to: "",
            free_shipping: false,
            discount: false,
            new_arrivals: false,
        });
        this.props.getFilterData({
            options_values,
            brands,
            rate,
            price_from,
            price_to,
            free_shipping,
            new_arrivals,
            discount,
        });
    };
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});

export default connect(mapStateToProps)(Filter);
