import React, { Component } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { ErrorMessage } from "./ErrorMessage";
import Label from "./Label";

class TextInput extends Component {
    state = {
        openCloseImg: "closedeye",
        type: this.props.type ?? "text",
    };
    render() {
        const {
            label,
            placeholder,
            tooltip,
            validate,
            maxLength,
            value,
            name,
            isRequired,
            isTextArea = false,
            SearchImage,
            withImg,
            disabled = false,
            max,
            min,
        } = this.props;
        const { openCloseImg, type } = this.state;
        return (
            <div className="text-input-block">
                {label && (
                    <Label
                        label={label}
                        tooltip={tooltip}
                        isRequired={isRequired}
                    />
                )}
                <InputGroup className="mb-3">
                    {isTextArea ? (
                        <textarea
                            name={name}
                            value={value}
                            placeholder={placeholder ?? label}
                            maxLength={maxLength}
                            onChange={this.onChange}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                    ) : (
                        <>
                            <FormControl
                                name={name}
                                value={value}
                                max={max ?? null}
                                min={min ?? null}
                                placeholder={placeholder ?? label}
                                maxLength={maxLength}
                                onChange={this.onChange}
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                type={type}
                                disabled={disabled}
                            />
                            {withImg && (
                                <img
                                    src={`/images/${openCloseImg}.png`}
                                    alt="l"
                                    onClick={() =>
                                        this.setState({
                                            type:
                                                openCloseImg === "eye"
                                                    ? "password"
                                                    : "text",
                                            openCloseImg:
                                                openCloseImg === "eye"
                                                    ? "closedeye"
                                                    : "eye",
                                        })
                                    }
                                />
                            )}
                            {SearchImage && (
                                <img src={`/images/search2.png`} alt="l" />
                            )}
                        </>
                    )}
                </InputGroup>
                {validate && <ErrorMessage message={validate} />}
            </div>
        );
    }

    onChange = ({ target: { name, value } }) => {
        const { onFieldChange } = this.props;
        onFieldChange(name, value);
    };
}

export default TextInput;
