import React, { Component } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import TextInput from "../../blocks/TextInput";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { authService } from "../../../services/AuthService";
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import { apiService } from "../../../services/ApiService";
import { Link } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
class Login extends Component {
    state = {
        remindMe: false,
        errors: { email: "", password: "" },
        fields: { email: "", password: "" },
        AddAddress: false,
        showVerificationCode: false,
        verificationCode: "",
        userVerificationCode: "",

        socialLogin: {
            email: "",
            social_provider: "",
            social_provider_id: "",
            phone: "",
            name: "",
            place_id: "",
            social_image: "",
        },
    };
    closeModal = () => {
        this.setState({
            AddAddress: false,
        });
    };
    componentDidMount() {
        const start = async () => {
          await gapi.client.init({
            clientId: "89614726756-iflee9ng98ebcu9psj9r5csvt9gdsqpl.apps.googleusercontent.com",
          });
        };
        gapi.load("client:auth2", start);
      }
    render() {
        const {
            language,
            i18n: {
                LoginToYourAccount,
                Email,
                Password,
                CreateBtn,
                LoginByFacebookOrGoogle,
                DontHaveAcount,
                LoginBtn,
                LittleNoteUnderEmail,
                RememberMe,
                ResetYourPassword,
                ForgetPassword,
                ResetPassword,
            },
        } = this.props;
        const { errors, fields, AddAddress } = this.state;
        return (
            <Container>
                <div
                    className={`signup-login   ${
                        language === "rtl" && "signup-login-arabic "
                    }`}
                >
                    {/* create new account title */}
                    <h3>{LoginToYourAccount}</h3>
                    {/* login form start */}
                    <div className="signup-login__form">
                        <Row>
                            <Col sm={12}>
                                <TextInput
                                    placeholder={Email}
                                    name="email"
                                    value={fields.email}
                                    label={Email}
                                    onFieldChange={this.onFieldChange}
                                    validate={errors.email}
                                />
                                {!errors.email && (
                                    <span className="text-note mt-1">
                                        {LittleNoteUnderEmail}
                                    </span>
                                )}
                            </Col>

                            <Col className="mt-px" sm={12}>
                                {" "}
                                <TextInput
                                    placeholder={Password}
                                    withImg
                                    name="password"
                                    value={fields.password}
                                    label={Password}
                                    type="password"
                                    onFieldChange={this.onFieldChange}
                                />
                                <div className="checkbox-ForgetPassword">
                                    <div className="checkbox-container">
                                        <p
                                            className="checkbox"
                                            onClick={() =>
                                                this.setState({
                                                    remindMe:
                                                        !this.state.remindMe,
                                                })
                                            }
                                        >
                                            {this.state.remindMe ? (
                                                <i className="fa fa-check"></i>
                                            ) : null}
                                        </p>
                                        <p>{RememberMe}</p>
                                    </div>
                                    <p
                                        onClick={() =>
                                            this.setState({ AddAddress: true })
                                        }
                                        className="ForgetPassword"
                                    >
                                        {ForgetPassword}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* login form End */}
                    {/* Create account button */}
                    <button className="submit-button" onClick={this.submit}>
                        {LoginBtn}
                    </button>
                    {/* Login with facebook or google section start */}
                    <h4>{LoginByFacebookOrGoogle}</h4>

                    <Container className="signup-login__by-fb-google" >
                        <Row className="no-wrap">
                            <Col md={6} sm={12} className="text-center mt-2">
                                <FacebookLogin
                                    appId="341655375537928"
                                    autoLoad={false}
                                    fields="id,name,email,picture"
                                    callback={this.responseFacebook}
                                    cssclassName=""
                                    isMobile={false}
                                    icon="fa fa-facebook-f fa-2x"
                                    textButton=""
                                    className="socialBtn fb-btn"
                                />
                            </Col>
                            <Col md={6} sm={12} className="text-center mt-2">
                                <GoogleLogin
                                    clientId="89614726756-iflee9ng98ebcu9psj9r5csvt9gdsqpl.apps.googleusercontent.com"
                                    buttonText={``}
                                    onSuccess={this.responseGoogle}
                                    onFailure={(error) => {
                                        console.log(error);
                                    }}
                                    cookiePolicy={"single_host_origin"}
                                    className="socialBtn gl-btn"
                                />
                            </Col>
                        </Row>
                    </Container>

                    {/* Login with facebook or google section End */}
                    {/* I don't Have An Account section start */}
                    <h5 className="h5-login">{DontHaveAcount}</h5>
                    <Link to="/signup" className="submit-button login-button">
                        {CreateBtn}
                    </Link>
                    {/* I don't Have An Account section end */}
                </div>
                {/* add/edit address modal */}
                <Modal
                    show={AddAddress}
                    width="600"
                    effect="fadeInUp"
                    onHide={this.closeModal}
                    className={`location-modal ${
                        language === "rtl" && "location-modal-arabic"
                    }`}
                >
                    <div className="modal-title flex">
                        <h5>
                            {this.state.showVerificationCode
                                ? "Enter verification code"
                                : ResetYourPassword}
                        </h5>
                        <i
                            onClick={this.closeModal}
                            className="fa fa-times"
                        ></i>
                    </div>
                    <div className="address-inputs">
                        <Row>
                            <Col
                                lg={12}
                                md={12}
                                sm={12}
                                style={{ marginBottom: "1rem" }}
                            >
                                {!this.state.showVerificationCode && (
                                    <TextInput
                                        name="email"
                                        label={Email}
                                        placeholder={Email}
                                        value={fields.email}
                                        onFieldChange={this.onFieldChange}
                                        validate={errors.email}
                                    />
                                )}
                                {this.state.showVerificationCode && (
                                    <ReactCodeInput
                                        fields={4}
                                        // onComplete={this.checkVerificationCode}
                                        onChange={(value) => {
                                            this.setState({
                                                userVerificationCode: `${value}`,
                                            });
                                        }}
                                    />
                                )}
                            </Col>

                            <Col
                                lg={12}
                                md={12}
                                sm={12}
                                className="locationButton"
                            >
                                <button
                                    className="submit-button2 margin-top-0 "
                                    onClick={() => {
                                        !this.state.showVerificationCode
                                            ? this.checkEmail()
                                            : this.checkVerificationCode();
                                    }}
                                >
                                    {this.state.showVerificationCode
                                        ? "Submit code"
                                        : ResetPassword}
                                </button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                {/* end add/edit address modal */}
            </Container>
        );
    }
    onFieldChange = (name, value) => {
        const { fields } = this.state;
        this.setState({
            fields: { ...fields, [name]: value },
            errors: {
                email: "",
                password: "",
            },
        });
    };
    submit = async () => {
        const { fields } = this.state;
        const { errors: errorTag } = this.props.i18n;
        const { success, data, message, errors } = await authService.login({
            email: fields.email,
            password: fields.password,
        });

        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert(errorTag, message, "error");
            }
        }

        apiService.storeToken(data.access_token);

        this.props.setUser({ ...data.user });

        window.location.href = "/";
    };

    // Handle Social Login START..

    responseFacebook = async (response) => {
        const { error: errorTag } = this.props.i18n;
        const { success, data, message, errors } =
            await authService.socialLogin({
                phone: "",
                place_id: this.props.country.id, // What
                email: response.email ? response.email : "",
                name: response.name ? response.name : "",
                social_image: response.image ? response.image : "",
                social_provider_id: response.id ? response.id : "",
                social_provider: "facebook",
            });
        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert(errorTag, message, "error");
            }
        }
        apiService.storeToken(data.access_token);
        this.props.setUser({ ...data.user });
        window.location.href = "/";
    };

    responseGoogle = async (response) => {
        console.error(response);
        const { error: errorTag } = this.props.i18n;
        const { success, data, message, errors } =
            await authService.socialLogin({
                phone: "",
                place_id: this.props.country.id,
                email: response.profileObj.email
                    ? response.profileObj.email
                    : "",
                name: response.profileObj.givenName
                    ? response.profileObj.givenName +
                      " " +
                      response.profileObj.familyName
                    : "",
                social_image: response.profileObj.image
                    ? response.profileObj.image
                    : "",
                social_provider_id: response.profileObj.googleId
                    ? response.profileObj.googleId
                    : "",
                social_provider: "google",
            });
        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert(errorTag, message, "error");
            }
        }
        console.log('data', data);
        apiService.storeToken(data.access_token);
        this.props.setUser({ ...data.user });
        window.location.href = "/";
    };
    // Handle Social Login END..
    // Check Reset password email
    checkEmail = async () => {
        const { fields } = this.state;
        const { error: errorTag } = this.props.i18n;
        const { success, data, message, errors } =
            await authService.forgetPassword({
                email: fields.email,
            });

        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert(errorTag, message, "error");
            }
        }
        displayAlert(
            this.props.i18n.Done,
            this.props.i18n.VerificationCodeSentToYourEmail,
            "success"
        ).then(() => {
            this.setState({ showVerificationCode: true });
            this.setState({ verificationCode: data });
        });
    };

    checkVerificationCode = async (inputCode) => {
        const { userVerificationCode, verificationCode } = this.state;
        if (userVerificationCode === verificationCode) {
            displayAlert(
                this.props.i18n.Done,
                this.props.i18n.VerificationCodeSent,
                "success"
            ).then(() => {
                this.setState({ showVerificationCode: false });
            });
        } else {
            displayAlert(
                this.props.i18n.Error,
                this.props.i18n.CodeInvalid,
                "error"
            );
            this.setState({ showVerificationCode: false });
            return 0;
        }
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country,
});
export default connect(mapStateToProps, { setLanguage, setUser })(Login);
