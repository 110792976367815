import React, { Component } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import Global from "../../language/Global.json";
import { setLanguage } from "../../redux/actions-reducers/language";
import { apiService } from "../../services/ApiService";
import { generalServices } from "../../services/GeneralService";
import { homeService } from "../../services/homeService";
import Cart from "../Pages/Cart/Cart";
import Compare from "../Pages/ComparePage/Compare";
import Favourite from "../Pages/Favourite/Favourite";
import Header from "./Header";

class HeaderMobile extends Component {
    state = {
        showCategory: false,
        showMegaCategory: false,
        menuOpen: false,
        allCategory: [],
        topCategory: [],
        countries: [],
        topBrand: [],
        tab: "home",
        categoriesLoader: true,
        selectedCategory: {},
    };
    // onLanguageChange = async () => {
    //     let currentLang;
    //     if (this.props.language === "ltr") {
    //         localStorage.setItem("langCode", "rtl");
    //         currentLang = "rtl";
    //     } else {
    //         localStorage.setItem("langCode", "ltr");
    //         currentLang = "ltr";
    //     }
    //     await this.props.setLanguage(currentLang);
    //     window.location.reload();
    // };

    onLanguageChange = async (name, langCode) => {
        this.setState(
            {
                currentLang: name,
            },
            () => {
                localStorage.setItem("langCode", langCode);
                this.props.setLanguage({ lang: name, langCode }).then(() => {
                    window.location.reload();
                });
            }
        );
    };

    componentDidMount() {
        Promise.all([this.getCountries(), this.getCategories(0, 0, 0, 1, 1), this.getCategories(0, 0, 1, 1, 1)]);
    }

    getCountries = async () => {
        const { data, success } = await generalServices.getAllCountries(0);
        if (!success) return;
        // this.props.setLanguage({ lang: data[0].languages[0].name, langCode: data[0].languages[0].direction }).then(() => {
        //     localStorage.setItem("langCode", data[0].languages[0].direction);
        // });
        this.setState({
            countries: data[0],
        });

        // this.props.setCountry({
        //     id: data[0].id,
        //     name: data[0].name,
        //     language: data[0].languages,
        //     currency: data[0].currency,
        //     digits: data[0].digits,
        //     flag: data[0].flag,
        //     zipcode: data[0].zipcode,
        // });
    };

    getCategories = async (parentId, isFeatured, topBar, showBrands, showSub) => {
        const { data, success } = await generalServices.getCategories(parentId, isFeatured, topBar, showBrands, showSub, this.props.country.id);
        if (!success) return;
        let name = topBar === 0 ? "allCategory" : "topCategory";
        this.setState({
            [name]: data,
            // topCategory: data[0].sub_category,
            // topBrand: data[0].brands,
            categoriesLoader: false,
        });
        if (topBar === 0) {
            this.setState({
                selectedCategory: data[0],
            });
        }
    };

    render() {
        const {
            language,
            currentUser,
            i18n: { Profile, Language, MyOrders, MyLocations, Items, Logout, Lang, Login, Home, Categories, Favourite: favoriteText, Account, TopCategories, TodaysDeals, BrandStore, TopBrands, ComparePage, CartMobile },
        } = this.props;
        const { tab, allCategory, categoriesLoader, countries, selectedCategory } = this.state;
        return (
            <>
                <div
                    className={`mobile-header  ${language === "rtl" && "mobile-header-arabic"}`}
                    style={
                        tab === "home"
                            ? null
                            : {
                                  position: "fixed",
                                  width: "100%",
                                  marginTop: "-92px",
                              }
                    }
                >
                    {tab === "home" ? null : <Header />}
                    <div className="mobile-header__back-header"></div>
                    <div className="mobile-header__contents" style={tab === "home" ? { display: "none" } : null}>
                        {tab === "cart" ? (
                            <div>
                                <Cart />
                            </div>
                        ) : // search tab end

                        tab === "categories" ? (
                            // categories tab start

                            <div className={`header-mobile__categories  ${language === "rtl" && "header-mobile__categories-arabic"}`}>
                                {/* <Container fluid> */}
                                <Row>
                                    <Col xs={4}>
                                        <ul className="top-categories">
                                            {/* {allCategory.map(({ name, subcategories, id, machine_name, brands }, index) => { */}
                                            {allCategory.map((item, index) => {
                                                return (
                                                    <li key={index} onClick={() => this.setState({ selectedCategory: item })} className={selectedCategory?.id == item.id && "mobile__category-active"}>
                                                        {item.name}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </Col>
                                    <Col xs={8}>
                                        <div className="mobile__categories-accordion">
                                            <h5>{selectedCategory?.name}</h5>

                                            <div className="mobile__ads">
                                                {selectedCategory?.ads?.categories_in_top_bar_left?.ad_image && (
                                                    <div
                                                        onClick={() => {
                                                            if (selectedCategory?.ads?.categories_in_top_bar_left?.can_click) {
                                                                this.visitAdsLink(selectedCategory?.ads?.categories_in_top_bar_left?.link, selectedCategory?.ads?.categories_in_top_bar_left?.id);
                                                            }
                                                        }}
                                                    >
                                                        <img src={selectedCategory?.ads?.categories_in_top_bar_left?.ad_image ? apiService.imageLink + selectedCategory?.ads?.categories_in_top_bar_left?.ad_image : "/images/placeholder.png"} alt="l" />
                                                    </div>
                                                )}
                                                {selectedCategory?.ads?.categories_in_top_bar_right?.ad_image && (
                                                    <div
                                                        onClick={() => {
                                                            if (selectedCategory?.ads?.categories_in_top_bar_right?.can_click) {
                                                                this.visitAdsLink(selectedCategory?.ads?.categories_in_top_bar_right?.link, selectedCategory?.ads?.categories_in_top_bar_right?.id);
                                                            }
                                                        }}
                                                    >
                                                        <img src={selectedCategory?.ads?.categories_in_top_bar_right?.ad_image ? apiService.imageLink + selectedCategory?.ads?.categories_in_top_bar_right?.ad_image : "/images/placeholder.png"} alt="l" />
                                                    </div>
                                                )}
                                            </div>

                                            {selectedCategory?.brands?.length > 0 && (
                                                <>
                                                    <h5>{TopBrands}</h5>

                                                    <div className="mobile__brands-list">
                                                        {selectedCategory?.brands?.map(({ logo, machine_name, name, id }) => {
                                                            return (
                                                                <a key={id} href={"/BrandProducts/" + machine_name + "/1"} className="single-brand p-3">
                                                                    <img src={apiService.imageLink + logo} alt="l" />
                                                                </a>
                                                            );
                                                        })}
                                                    </div>
                                                </>
                                            )}

                                            {this.renderCategories()}

                                            {/* {selectedCategory?.subcategories?.map(({ name, subcategories, id, machine_name, brands, items_count, image }, index) => {
                                                if (subcategories?.length <= 0) {
                                                    return (
                                                        <a href={"/productsCategory/" + machine_name + "/1"} className={`mobile-categories__single-item  ${language === "rtl" && "home-categories__single-item-arabic"}`}>
                                                            <img src={apiService.imageLink + image} alt="l" />
                                                            <p>{name}</p>
                                                            <span>
                                                                {items_count} {Items}
                                                            </span>
                                                        </a>
                                                    );
                                                }
                                            })} */}
                                        </div>
                                    </Col>
                                </Row>
                                {/* </Container> */}

                                {/* <div className="top-categories">
                                    <Container fluid>
                                        <Row>
                                            <Col xs={6} sm={4}>
                                                <a href="/" className="top-category__single-card">
                                                    <img src="/images/hot-sale1.svg" alt="search" />
                                                    <p>{TodaysDeals}</p>
                                                </a>
                                            </Col>
                                            <Col xs={6} sm={4}>
                                                <a href="/" className="top-category__single-card">
                                                    <img src="/images/best-product.svg" alt="search" />
                                                    <p>{BrandStore}</p>
                                                </a>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div> */}

                                {/* <div className="header-mobile__categories__all-category">
                                    <Accordion defaultActiveKey="0">
                                        {categoriesLoader ? (
                                            <>
                                                <Accordion.Item eventKey={1}>
                                                    <Skeleton height={30} />
                                                </Accordion.Item>
                                                <Accordion.Item eventKey={1}>
                                                    <Skeleton height={30} />
                                                </Accordion.Item>
                                                <Accordion.Item eventKey={1}>
                                                    <Skeleton height={30} />
                                                </Accordion.Item>
                                                <Accordion.Item eventKey={1}>
                                                    <Skeleton height={30} />
                                                </Accordion.Item>
                                                <Accordion.Item eventKey={1}>
                                                    <Skeleton height={30} />
                                                </Accordion.Item>
                                                <Accordion.Item eventKey={1}>
                                                    <Skeleton height={30} />
                                                </Accordion.Item>
                                            </>
                                        ) : (
                                            <>
                                                {allCategory.map(({ name, subcategories, id, machine_name, brands }, index) => {
                                                    return (
                                                        <Accordion.Item key={index} eventKey={id}>
                                                            <Accordion.Header>{name}</Accordion.Header>
                                                            <Accordion.Body>
                                                                <h5>{TopCategories}</h5>
                                                                <ul>
                                                                    {subcategories.map(({ name }) => {
                                                                        return (
                                                                            <li>
                                                                                <a href={"/ProductsCategory/" + machine_name + "/1"}>{name}</a>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                                <h5>{TopBrands}</h5>
                                                                <ul>
                                                                    {brands.map(({ name }) => {
                                                                        return (
                                                                            <li>
                                                                                <a href={"/ProductsCategory/" + machine_name + "/1"}>{name}</a>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </Accordion>
                                </div> */}
                            </div>
                        ) : tab === "favourite" ? (
                            <Favourite />
                        ) : tab === "compare" ? (
                            <Compare />
                        ) : (
                            // categories tab end
                            // Account tab start
                            <div className="header-mobile__account">
                                <ul style={{ marginTop: "30px" }}>
                                    <li>
                                        <a href="/orders/1" className="p-1">
                                            <img src="/images/boxDropdown.svg" alt="k" className="m-2" />
                                            <p className="m-0 d-inline-block">{MyOrders}</p>
                                        </a>
                                    </li>
                                    {apiService.accessToken === 0 ? null : (
                                        <>
                                            <li>
                                                <a href="/addresses" className="p-1">
                                                    <img src="/images/locationDropdown.svg" alt="j" className="m-2" />
                                                    <p className="m-0 d-inline-block">{MyLocations}</p>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="/Profile" className="p-1">
                                                    <img src="/images/userDropdown.svg" alt="k" className="m-2" />
                                                    <p className="m-0 d-inline-block">{Profile}</p>
                                                </a>
                                            </li>
                                        </>
                                    )}
                                    <li>
                                        <a href="/Compare" className="p-1">
                                            <img src="/images/compareiconmobile.svg" alt="k" className="m-2" />
                                            <p className="m-0 d-inline-block">{ComparePage}</p>
                                        </a>
                                    </li>

                                    <li>
                                        {/* <a className="p-1">
                                            <img src="/images/global2.svg" alt="k" className="m-2" />
                                            <p className="m-0 d-inline-block">{Lang}</p>
                                        </a> */}

                                        <Accordion defaultActiveKey="0" className=" language-mobile-header">
                                            {/* // return <Dropdown.Item onClick={() => this.onLanguageChange(name, direction)}>{name}</Dropdown.Item>; */}
                                            <Accordion.Header className="p-0">
                                                <img src="/images/global2.svg" alt="k" className="m-2" />
                                                {Language}
                                            </Accordion.Header>
                                            <Accordion.Body className="p-0">
                                                {/* <h5>{TopCategories}</h5> */}
                                                <ul>
                                                    {countries?.languages?.map(({ name, direction }) => {
                                                        return (
                                                            <li>
                                                                <a onClick={() => this.onLanguageChange(name, direction)}>{name}</a>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion>
                                    </li>

                                    {apiService.accessToken === 0 ? (
                                        <li>
                                            <a className="p-1" href="/login">
                                                <img src="/images/user (13).svg" alt="k" className="m-2" />
                                                <p className="m-0 d-inline-block">{Login}</p>
                                            </a>
                                        </li>
                                    ) : (
                                        <li>
                                            <a
                                                href="/login"
                                                onClick={() => {
                                                    localStorage.removeItem("TZShopAccessToken");
                                                    localStorage.removeItem("persist:RZ");
                                                }}
                                                className="p-1"
                                            >
                                                <img src="/images/logoutDropdown.svg" alt="k" className="m-2" />
                                                <p className="m-0 d-inline-block">{Logout}</p>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>

                            // Account tab end
                        )}
                    </div>

                    <nav className="mobile-header__tabs">
                        <ul className="">
                            <li
                                onClick={() => {
                                    // this.setState({
                                    //     tab: "home",
                                    // });
                                    window.location.href = "/";
                                }}
                            >
                                <a>
                                    <img src="/images/home (2).svg" alt="search" />
                                    {Home}
                                </a>
                            </li>
                            <li
                                onClick={() => {
                                    if (tab === "categories") {
                                        this.setState({
                                            tab: "home",
                                        });
                                    } else {
                                        this.setState({
                                            tab: "categories",
                                        });
                                    }
                                }}
                            >
                                <a>
                                    <img src="/images/catigoery.svg" alt="search" />
                                    {Categories}
                                </a>
                            </li>
                            <li
                                onClick={() => {
                                    if (tab === "cart") {
                                        this.setState({
                                            tab: "home",
                                        });
                                    } else {
                                        this.setState({
                                            tab: "cart",
                                        });
                                    }
                                }}
                            >
                                <a>
                                    <div className="img-container">
                                        {" "}
                                        <img src="/images/cartsvg.svg" alt="search" />
                                        <span>{currentUser?.cart}</span>
                                    </div>
                                    {CartMobile}
                                </a>
                            </li>
                            <li
                                onClick={() => {
                                    if (tab === "favourite") {
                                        this.setState({
                                            tab: "home",
                                        });
                                    } else {
                                        this.setState({
                                            tab: "favourite",
                                        });
                                    }
                                }}
                            >
                                <a>
                                    <div className="img-container">
                                        {" "}
                                        <img src="/images/heart-line.svg" alt="favourite" />
                                        <span>{currentUser?.fav}</span>
                                    </div>
                                    {favoriteText}
                                </a>
                            </li>
                            {/* <li
                            onClick={() => {
                                if (tab === "search") {
                                    this.setState({
                                        tab: "home",
                                    });
                                } else {
                                    this.setState({
                                        tab: "compare",
                                    });
                                }
                            }}
                        >
                            <a>
                                <img
                                    src="/images/compare-new.png"
                                    alt="search"
                                />
                                Compare
                            </a>
                        </li> */}

                            {/* <li>
                        <a >
                            <img src="/images/home.png" alt="search" />
                            Cart
                        </a>
                    </li> */}
                            <li
                                onClick={() => {
                                    if (tab === "account") {
                                        this.setState({
                                            tab: "home",
                                        });
                                    } else {
                                        this.setState({
                                            tab: "account",
                                        });
                                    }
                                }}
                            >
                                <a>
                                    <img src="/images/acountsvg.svg" alt="search" />
                                    {Account}
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </>
        );
    }
    closeMenu() {
        this.setState({ menuOpen: false });
    }
    toggleMenu() {
        this.setState((state) => ({ menuOpen: !state.menuOpen }));
    }
    renderCategories() {
        const { selectedCategory } = this.state;
        const {
            language,
            i18n: { TopCategories, Items },
        } = this.props;

        let firstLevel = selectedCategory?.subcategories?.filter(({ subcategories }) => subcategories.length > 0);
        let secondLevel = selectedCategory?.subcategories?.filter(({ subcategories }) => subcategories.length <= 0);

        return (
            <div>
                {firstLevel?.length > 0 && (
                    <Accordion defaultActiveKey="0">
                        {selectedCategory?.subcategories
                            ?.filter(({ subcategories }) => subcategories.length > 0)
                            .map(({ name, subcategories, id, machine_name, brands, items_count, image }, index) => {
                                if (subcategories?.length > 0) {
                                    return (
                                        <Accordion.Item key={index} eventKey={id}>
                                            <Accordion.Header>{name}</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="mobile__categories-items ">
                                                    {subcategories.map(({ name }) => {
                                                        return (
                                                            <a href={"/productsCategory/" + machine_name + "/1"} className={`mobile-categories__single-item  ${language === "rtl" && "home-categories__single-item-arabic"}`}>
                                                                <img src={apiService.imageLink + image} alt="l" />
                                                                <p>{name}</p>
                                                                <span>
                                                                    {items_count} {Items}
                                                                </span>
                                                            </a>
                                                        );
                                                    })}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                }
                            })}
                    </Accordion>
                )}

                {secondLevel?.length > 0 && (
                    <div className="mobile__categories-items mt-3 ">
                        {selectedCategory?.subcategories
                            ?.filter(({ subcategories }) => subcategories.length <= 0)
                            .map(({ name, subcategories, id, machine_name, brands, items_count, image }, index) => {
                                if (subcategories?.length <= 0) {
                                    return (
                                        <a href={"/productsCategory/" + machine_name + "/1"} className={`mobile-categories__single-item  ${language === "rtl" && "home-categories__single-item-arabic"}`}>
                                            <img src={apiService.imageLink + image} alt="l" />
                                            <p>{name}</p>
                                            <span>
                                                {items_count} {Items}
                                            </span>
                                        </a>
                                    );
                                }
                            })}
                    </div>
                )}
            </div>
        );
    }

    visitAdsLink = async (url, id) => {
        const { data, success } = await homeService.visitAdsLink(id);
        if (!success) return;
        window.open(url, "target");
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country,
});
export default connect(mapStateToProps, { setLanguage })(HeaderMobile);
