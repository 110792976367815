import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json";
import { apiService } from "../../../services/ApiService";
import { statusTypesColor } from "../../../utils/misc";
import ReactToPrint from "react-to-print";
import moment from "moment";

const Invoices = ({ data }) => {
    const language = useSelector(({ language }) => language);
    const currentUser = useSelector(({ currentUser }) => currentUser);
    const country = useSelector(({ country }) => country);
    const i18n = language.langCode === "ltr" ? Global.en : Global.ar;

    const { Orders, OrdersTitle2, DeliverTo, OrderNum, Name, SafeerCo, DownloadInvoice, Date, Email, Phone, DeliveredOn, Username, days, BillTo, BillFrom, Company, Status, Pic, feesAmount, ItemName, Quntity, UnitPrice, Total, Note, taxesAmount, Discount, ItemsAmount } = i18n;

    const componentRef = useRef();
    const [responsiveTable, setResponsiveTable] = useState(false);

    window.addEventListener("resize", () => {
        if (window.innerWidth === 786) {
            setResponsiveTable(true);
        }
    });
    return (
        <div className={`invoice ${language.langCode === "rtl" && "invoice-arabic"}`}>
            {/**************** header start ************/}
            <div className="px-3">
                <h2 className="invoice__title">{Orders}</h2>
                <p className="invoice__subtitle mb-4">{OrdersTitle2}</p>
            </div>
            {/**************** header end************/}

            {/**************** order number section start ************/}
            <div className="invoice__sec1 mb-4 p-3">
                <div className="flex gap-2">
                    <div>
                        <p className="mb-0 invoice__sec1__orderNum">
                            {OrderNum} &nbsp; : &nbsp; #{data?.order_information?.id}
                        </p>
                    </div>
                    {/* <div>{data?.order_information?.order_reference}</div> */}
                    <div>
                        <ReactToPrint
                            trigger={() => (
                                <div>
                                    {/* <img src="/images/surface1.svg" alt="download-icon" className="mx-3" /> */}
                                    <a className="invoice__sec1__download">{DownloadInvoice}</a>
                                </div>
                            )}
                            content={() => componentRef.current}
                        />
                    </div>
                </div>
            </div>
            {/********* order number section end ******/}
            <div ref={componentRef} className="printable-section-container">
                <div className="invoice-header">
                    <img src="/images/795326374824304.png" alt="logo" className="logo mb-4 pt-4" />
                </div>
                <Table responsive="sm" className={`printable-section-container__table`}>
                    <thead className="printable-section-container__table__header">
                        <tr>
                            <th colSpan={2}>{BillTo}</th>
                            <th>{BillFrom}</th>
                        </tr>
                    </thead>
                    <tbody className="printable-section-container__table__body">
                        <tr>
                            <td colSpan={2}>
                                <table>
                                    <tr>
                                        <td className="table-field">{Name} : </td>
                                        <td className="ms-3">{currentUser.name}</td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td className="table-field">{Company} : </td>
                                        <td className="ms-3">{SafeerCo}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <table>
                                    <tr>
                                        <td className="table-field">{DeliverTo} : </td>
                                        <td className="ms-3">{data?.order_information?.receiver_details?.address}</td>
                                    </tr>
                                </table>
                            </td>
                            {/*  */}
                            <td>
                                <table>
                                    <tr>
                                        <td className="table-field">{Phone} :</td>
                                        <td className="ms-3 direction-class">+962 78 0000000</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <table>
                                    <tr>
                                        <td className="table-field">{Phone} :</td>
                                        <td className="ms-3">{data?.order_information.receiver_details?.phone}</td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td className="table-field">{Email} :</td>
                                        <td className="ms-3">care@RZ.jo</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <table>
                                    <tr>
                                        <td className="table-field">{Email} :</td>
                                        <td className="ms-3">{data?.order_information.receiver_details?.email}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className={`printable-section-container__table__responsive__container d-none`}>
                    <Table className="printable-section-container__table__responsive">
                        <thead className="printable-section-container__table__header">
                            <tr>
                                <th colSpan={2}>{BillTo}</th>
                            </tr>
                        </thead>
                        <tbody className="printable-section-container__table__body">
                            <tr>
                                <td colSpan={2}>
                                    <table>
                                        <tr>
                                            <td className="table-field">{Name} :</td>
                                            <td className="ms-3">{currentUser.name}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table>
                                        <tr>
                                            <td className="table-field">{DeliverTo} :</td>
                                            <td className="ms-3">{data?.order_information?.receiver_details?.address}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table>
                                        <tr>
                                            <td className="table-field">{Phone} :</td>
                                            <td className="ms-3">{data?.order_information.receiver_details?.phone}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table>
                                        <tr>
                                            <td className="table-field">{Email} :</td>
                                            <td className="ms-3">{data?.order_information.receiver_details?.email}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <Table className="printable-section-container__table__responsive">
                        <thead className="printable-section-container__table__header">
                            <tr>
                                <th colSpan={2}>{BillFrom}</th>
                            </tr>
                        </thead>
                        <tbody className="printable-section-container__table__body">
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td className="table-field">{Company} :</td>
                                            <td className="ms-3">Amman</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td className="table-field">{Phone} :</td>
                                            <td className="ms-3">+962 78 0000000</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td className="table-field">{Email} :</td>
                                            <td className="ms-3">care@RZ.jo</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                {/****** date and status section start *****/}
                <div className="invoice__sec2 mb-4 ps-2">
                    <div className="gap-4">
                        <div>
                            <p className="mb-0 invoice__sec2__date">
                                <span className="invoice__span1">{Date}</span>
                                :&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>{moment(data?.order_information?.created_at).format("yy-MM-DD")}</span>
                            </p>

                            {/* <p className="mb-0 invoice__sec2__date">
                                <span className="invoice__span1">{DeliveredOn}</span>
                                :&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>{moment(data?.order_information?.complete_date_time).format("yy-MM-DD")}</span>
                            </p> */}

                            {/* {data?.order_information.complete_date_time && ( 
                            <p className="mb-0 invoice__sec2__deliverdOn">
                                <span className="invoice__span1">{DeliveredOn}</span>
                                :&nbsp;&nbsp;&nbsp;&nbsp;
                                {data?.order_information.complete_date_time}
                                {/* <span>+/- 2 {days}</span> 
                            </p> 
                            {/* )} */}
                        </div>
                        <div className="align-self-start mt-2">
                            <p className="mb-0 invoice__sec2__status">
                                <span>{Status} :</span>
                                <span
                                    style={{
                                        color: data.order_information?.statusColor,
                                    }}
                                    // className="d-flex align-items-center"
                                    // style={{columnGap: "10px"}}
                                >
                                    <span className="m-0 p-0" style={{ display: "inline-block" }}>
                                        {language.langCode === "ltr" ? data.order_information?.statusText : data.order_information?.statusText == "Pending" ? "قيد الانتظار" : data.order_information?.statusText == "Cacnelled" ? "ملغي" : data.order_information?.statusText == "Accepted" ? "تم القبول" : data.order_information?.statusText == "On the Way" ? "في الطريق" : data.order_information?.statusText == "Arrived" ? "تم التوصيل" : data.order_information?.statusText == "Completed" ? "مكتمل" : null}
                                    </span>
                                    <i className=" fa fa-circle ps-3"></i>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                {/********* date and status section end ******/}

                {/*********** Table Start  *********************/}
                <div className="invoice-table-parent">
                    <Table bordered className="invoice-table">
                        <thead>
                            <tr>
                                <th>{Pic}</th>
                                <th>{ItemName}</th>
                                <th>{Quntity}</th>
                                <th>{UnitPrice}</th>
                                <th>{Total}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.items?.map(({ product, qty, unit_price, total_price, option_values }) => {
                                return (
                                    <tr>
                                        <td>
                                            <img alt="" src={product.main_image ? apiService.imageLink + product.main_image : "/images/placeholder.png"} />
                                        </td>
                                        <td className="ItemNameTable">
                                            {product.title}
                                            <br />
                                            <span className="invoice-option">{option_values.join(",")}</span>
                                        </td>
                                        <td>X {qty}</td>
                                        <td>
                                            <strong>
                                                {unit_price} {country.currency}
                                            </strong>
                                        </td>
                                        <td>
                                            <strong>
                                                {total_price} {country.currency}
                                            </strong>
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td colSpan="2" className="NoLeftBorder">
                                    {" "}
                                </td>
                                <td colSpan="2" className="NoLeftBorder">
                                    <div>
                                        <h4>{ItemsAmount}</h4>
                                        <h4>{Discount} </h4>
                                        {/* <h4>{Discount} "Code :A25sal"</h4> */}
                                        <h4>{taxesAmount}</h4>
                                        <h4>{feesAmount}</h4>
                                    </div>
                                </td>
                                <td colSpan="2">
                                    <h2>
                                        {data?.order_information.items_amount} {country.currency}
                                    </h2>
                                    <h3>
                                        {data?.order_information.discount_amount} {country.currency}
                                    </h3>
                                    <h2>
                                        {data?.order_information.taxes_amount} {country.currency}
                                    </h2>
                                    <h2>
                                        {data?.order_information.fees_amount} {country.currency}
                                    </h2>
                                </td>
                            </tr>

                            <tr>
                                <td className="NoBorder" colSpan="2"></td>
                                <td colSpan="1" className="ColoredTD">
                                    {" "}
                                    {Total}{" "}
                                </td>
                                <td className="ColoredTD" colSpan="3">
                                    <h3>
                                        {data?.order_information.total_amount} {country.currency}
                                    </h3>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
            {/* <div className="TableNote">
                {Note} : Lorem Ipsum Is Simply Dummy Text Of The Printing And
                Typesetting Industry. Lorem Ipsum Has Been The Industry's
                Standard Dummy Text Ever Since The 1500S,
            </div> */}
            {/*********** Table End  *********************/}
        </div>
    );
};
export default Invoices;
