import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Filter from "./Filter";
import Global from "../../../language/Global.json";
import { connect } from "react-redux";

class FilterModal extends Component {
    state = {
        displayFilterModal: false,
    };

    render() {
        console.log(this.props.data, "dlkfjsdlk");

        const {
            language,
            i18n: { filterItems },
        } = this.props;

        return (
            <Modal
                show={this.props.filterModal}
                className={language === "rtl" && "modal-arabic"}
            >
                <Modal.Header closeButton onClick={this.props.closeModal}>
                    <Modal.Title>{filterItems}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Filter
                        data={this.props.data}
                        getFilterData={this.props.getFilterData}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

// export default FilterModal;

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});

export default connect(mapStateToProps)(FilterModal);
