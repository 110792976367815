import axios from "axios";

class ApiService {
    baseURL = "https://mwapi.rz.jo/api/";
    token = "f95aedfed793a46023f9baa7d769c4c01be459cf";
    imageLink = "";
    // imageLink = "http://192.168.1.88:8007/storage/";
    // http://192.168.1.21:1993/api

    get accessToken() {
        return window.localStorage.getItem("TZShopAccessToken") ?? 0;
    }

    get language() {
        return window.localStorage.getItem("langCode") === "rtl" ? "ar" : "en";
    }
    authenticated() {
        return axios.create({ baseURL: this.baseURL, headers: { Token: this.token, Authorization: `Bearer ${this.accessToken}`, Language: this.language } });
    }

    unauthenticated() {
        return axios.create({ baseURL: this.baseURL, headers: { Token: this.token, Language: this.language } });
    }

    storeToken(token) {
        window.localStorage.setItem("TZShopAccessToken", token);
    }
}

export const apiService = new ApiService();
