import React, { Component } from "react";
import { Container, Row, Col, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { userService } from "../../../services/UserService";
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import Label from "../../blocks/Label";
import SelectInput from "../../blocks/SelectInput";
import TextInput from "../../blocks/TextInput";
class Profile extends Component {
    state = {
        remindMe: false,
        userInfo: {},
        languages: [
            { label: "English", value: 1 },
            { label: "العربية", value: 2 },
        ],
        generalFields: {
            first_name: "",
            last_name: "",
            language_id: "",
            Phone: "",
            email: "",
        },
        securityFields: {
            old_password: "",
            password: "",
            password_confirmation: "",
        },
        errors: {
            name: "",
            language_id: "",
            old_password: "",
            password: "",
            password_confirmation: "",
        },
    };

    componentDidMount() {
        this.getUserInformation();
    }

    getUserInformation = async () => {
        const { data, success } = await userService.getUserInformation();
        if (!success) return;
        this.setState({
            userInfo: data,

            generalFields: {
                ...this.state.generalFields,
                first_name:
                    data.name.split(" ").filter((item) => item !== "")[0] ?? "",
                last_name:
                    data.name.split(" ").filter((item) => item !== "")[1] ?? "",
                email: data.email,
                language_id: this.state.languages.find(
                    ({ value }) => data.language_id === value
                ),
                Phone: data.phone,
            },
            securityFields: {
                // password: data.email,
                // confirmPassword: data.email,
            },
        });
    };

    render() {
        const {
            language,
            i18n: {
                FirstName,
                LastName,
                oldPassword,
                Password,
                Email,
                ConfirmPassword,
                Save,
                Profile,
                ManageProfileDetails,
                GeneralInformation,
                Security,
                PreferredLanguage,
                Phone,
            },
        } = this.props;
        const { languages, generalFields, securityFields, errors } = this.state;
        return (
            <Container>
                {/* title start */}
                <div
                    className={`profile  ${
                        language === "rtl" && "profile-arabic"
                    }`}
                >
                    <div className="account-page__title">
                        <h3>{Profile}</h3>
                        <h4>{ManageProfileDetails}</h4>
                    </div>
                    {/* title start */}
                    {/* general information Start section */}
                    <div className="general-info">
                        <h2 className="general-info__title">
                            {GeneralInformation}
                        </h2>
                        <Row>
                            <Col lg={6} md={6}>
                                <TextInput
                                    name="first_name"
                                    label={FirstName}
                                    placeholder={FirstName}
                                    value={generalFields.first_name}
                                    onFieldChange={this.onFieldChange}
                                    validate={errors.name}
                                />
                            </Col>
                            <Col lg={6} md={6}>
                                <TextInput
                                    name="last_name"
                                    label={LastName}
                                    placeholder={LastName}
                                    value={generalFields.last_name}
                                    onFieldChange={this.onFieldChange}
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                <TextInput
                                    name="email"
                                    label={Email}
                                    placeholder={Email}
                                    value={generalFields.email}
                                    onFieldChange={this.onFieldChange}
                                    validate={errors.email}
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                {/* <TextInput name="Phone" type="number" label={Phone} placeholder={Phone} value={generalFields.Phone} onFieldChange={this.onFieldChange} validate={errors.phone}  /> */}

                                <div
                                    className={`text-input-block phone-input `}
                                >
                                    <Label
                                        label={Phone}
                                        tooltip={""}
                                        isRequired={false}
                                    />

                                    <InputGroup>
                                        <input
                                            style={{ fontWeight: "600" }}
                                            type="number"
                                            placeholder={Phone}
                                            name="Phone"
                                            onInput={({ target }) =>
                                                (target.value = Math.max(
                                                    0,
                                                    parseInt(target.value)
                                                )
                                                    .toString()
                                                    .slice(
                                                        0,
                                                        this.props.country
                                                            .digits
                                                    ))
                                            }
                                            onChange={({ target: { value } }) =>
                                                this.setState({
                                                    generalFields: {
                                                        ...generalFields,
                                                        Phone: value,
                                                    },
                                                })
                                            }
                                            value={generalFields.Phone}
                                            validate={errors.Phone}
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col lg={6} md={12}>
                                <SelectInput
                                    name="language_id"
                                    label={PreferredLanguage}
                                    placeholder={PreferredLanguage}
                                    value={generalFields.language_id}
                                    onFieldChange={this.onFieldChange}
                                    // isTextArea
                                    data={languages}
                                    validate={errors.language_id}
                                />
                            </Col>
                            <div className="button-container">
                                <button
                                    className="submit-button2"
                                    onClick={this.submitGeneralInfo}
                                >
                                    {Save}
                                </button>
                            </div>
                        </Row>
                    </div>
                    {/* general information End section */}
                    {/* Security Start section */}
                    <div className="Security">
                        <h2 className="Security__title">{Security}</h2>
                        <Row>
                            <Col lg={4} md={12}>
                                <TextInput
                                    type="password"
                                    name="old_password"
                                    label={oldPassword}
                                    placeholder={oldPassword}
                                    value={securityFields.old_password}
                                    onFieldChange={this.onSecurityFieldChange}
                                    validate={errors.old_password}
                                    withImg
                                />
                            </Col>
                            <Col lg={4} md={6}>
                                <TextInput
                                    name="password"
                                    label={Password}
                                    placeholder={Password}
                                    value={securityFields.password}
                                    onFieldChange={this.onSecurityFieldChange}
                                    type="password"
                                    validate={errors.password}
                                    withImg
                                />
                            </Col>
                            <Col lg={4} md={6}>
                                <TextInput
                                    name="password_confirmation"
                                    placeholder={ConfirmPassword}
                                    withImg
                                    label={ConfirmPassword}
                                    type="password"
                                    value={securityFields.password_confirmation}
                                    onFieldChange={this.onSecurityFieldChange}
                                    validate={errors.password_confirmation}
                                />
                            </Col>

                            <div className="button-container">
                                <button
                                    onClick={this.submitSecurityInfo}
                                    className="submit-button2"
                                >
                                    {Save}
                                </button>
                            </div>
                        </Row>
                    </div>
                    {/* Security End section */}
                </div>
            </Container>
        );
    }

    onFieldChange = (name, value) => {
        const { generalFields } = this.state;
        this.setState({
            generalFields: { ...generalFields, [name]: value },
            errors: {
                first_name: "",
                last_name: "",
                language_id: "",
                Phone: "",
            },
        });
    };

    onSecurityFieldChange = (name, value) => {
        const { securityFields } = this.state;
        this.setState({
            securityFields: { ...securityFields, [name]: value },
            errors: {
                password: "",
                old_password: "",
                password_confirmation: "",
            },
        });
    };

    submitGeneralInfo = async () => {
        const { generalFields } = this.state;
        const { success: successText, ProfileUpdatedSuccessfully } =
            this.props.i18n;
        const { success, message, errors } =
            await userService.updateGeneralInfo({
                email: generalFields.email,
                phone: generalFields.Phone,
                name: `${generalFields.first_name} ${generalFields.last_name}`,
                place_id: this.props.country.id,
                image: "",
            });

        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert("Error", message, "error");
            }
        }
        // apiService.storeToken(data.access_token);
        displayAlert(successText, ProfileUpdatedSuccessfully, "success");
        this.props.setUser({
            name: generalFields.first_name + " " + generalFields.last_name,
        });
    };

    submitSecurityInfo = async () => {
        const { securityFields } = this.state;
        const { success, message, errors } =
            await userService.updateSecurityInfo({
                old_password: securityFields.old_password,
                password: securityFields.password,
                password_confirmation: securityFields.password_confirmation,
            });
        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return;
            } else {
                return displayAlert("Error", message, "error");
            }
        }
        // apiService.storeToken(data.access_token);
        displayAlert(
            this.props.i18n.Done,
            this.props.i18n.PasswordUpdatedSuccessfully,
            "success"
        ).then(() => {
            this.setState({
                securityFields: {
                    password: "",
                    old_password: "",
                    password_confirmation: "",
                },
            });
        });
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country: country,
});
export default connect(mapStateToProps, { setLanguage, setUser })(Profile);
