import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { apiService } from "../../../services/ApiService";
import { tabletScreen } from "../../../utils/misc";

class GirlsCategories extends Component {
    render() {
        const {
            language,
            data,
            girlsCategoriesLoaded,
            // i18n: { },
        } = this.props;

        if (!girlsCategoriesLoaded) {
            return (
                <Container>
                    <div className={`girls-categories  ${language === "rtl" && "girls-categories-arabic"}`}>

                        <Row>
                            <Col>
                                <Skeleton height={tabletScreen ? 120 : 250} />
                            </Col>
                            <Col>
                                <Skeleton height={tabletScreen ? 120 : 250} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            )
        } else if (data?.length > 0) {
            return (
                <Container>
                    <div className={`girls-categories  ${language === "rtl" && "girls-categories-arabic"}`}>

                        <Row className="m-0">
                            {data?.map(({ id, image, machine_name, name, products, products_count, description }, index) => {
                                return index === 0 || index === 1 ? (
                                    // <Col key={index} className="mb-4" md={6} key={id}>
                                    //     <a
                                    //         href={`/productsCategory/${machine_name}/1`}
                                    //         className={index === 0 ? "girls-categories__main-card" : "girls-categories__horizontal-card"}
                                    //     >
                                    //         <img src={apiService.imageLink + image} alt="/" />
                                    //         <div>
                                    //             <h3>{name}</h3>
                                    //             <span className="cat-discription">{description}</span>
                                    //         </div>
                                    //     </a>
                                    // </Col>

                                    <Col key={index} className=" col" xs={6} md={6} key={id}>
                                        <a href={`/productsCategory/${machine_name}/1`} className="girls-categories__horizontal-card">
                                            <img src={apiService.imageLink + image} alt="/" />
                                            <div>
                                                <h3>{name}</h3>
                                                <span>{description}</span>
                                            </div>
                                        </a>
                                    </Col>
                                ) : (
                                    <Col key={index} className=" col" xs={6} md={3}>
                                        <a href={`/productsCategory/${machine_name}/1`} className="girls-categories__vertical-card">
                                            <img src={apiService.imageLink + image} alt="/" />
                                            <h3>{name}</h3>
                                        </a>
                                    </Col>
                                );
                            })}
                            {/* <img src="/images/gaming.png" alt="/" /> */}

                            {/*  <Col className="mb-4" xs={6} md={3}>
                                    <a href="/" className="girls-categories__vertical-card">
                                        <img src="/images/qqq.png" alt="/" />
                                        <h3>Headphones</h3>
                                    </a>
                                </Col>
                                 <Col className="mb-4" xs={6} md={3}>
                                <a href="/" className="girls-categories__vertical-card">
                                    <img src="/images/Layer 1.png" alt="/" />
                                    <h3>Cameras</h3>
                                </a>
                            </Col>
                            <Col className="mb-4" md={6}>
                                <a href="/" className="girls-categories__horizontal-card">
                                    <img src="/images/sss.png" alt="/" />
                                    <div>
                                        <h3>Gifts and More</h3>
                                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                    </div>
                                </a>
                            </Col>
                            <Col className="mb-4" md={6}>
                                <a href="/" className="girls-categories__horizontal-card">
                                    <img src="/images/eee.png" alt="/" />
                                    <div>
                                        <h3>Sun Glasses </h3>
                                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                    </div>
                                </a>
                            </Col> */}
                        </Row>

                    </div>
                </Container>
            );
        } else {
            return null;
        }


    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(GirlsCategories);
