import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";

import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../../utils/misc";
import { apiService } from "../../../services/ApiService";
import { generalServices } from "../../../services/GeneralService";
import { Link } from "react-router-dom";

class Brands extends Component {
    state = {
        brands: [],
    };

    componentDidMount() {
        this.getBrands(0);
    }

    getBrands = async (limit) => {
        const { data, success } = await generalServices.getBrands(limit);

        if (!success) return;
        this.setState({
            brands: data,
            brandsLoaded: true
        });
    };

    render() {
        const {
            language,
            i18n: { Brands },
        } = this.props;

        const { brands, brandsLoaded } = this.state;

        if (!brandsLoaded) {
            return (
                <Container>
                      <div
                        className={`brands  ${language === "rtl" && "brands-arabic"
                            }`}
                    >
                        <Row>
                            <Col md={3} sm={4} xs={6}>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col md={3} sm={4} xs={6}>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col md={3} sm={4} xs={6}>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col md={3} sm={4} xs={6}>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col md={3} sm={4} xs={6}>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col md={3} sm={4} xs={6}>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col md={3} sm={4} xs={6}>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                            <Col md={3} sm={4} xs={6}>
                                <Skeleton height={tabletScreen ? 100 : 250} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            );
        } else {
            return (
                <Container>
                    <div
                        className={`brands  ${language === "rtl" && "brands-arabic"
                            }`}
                    >
                        {/* Title start */}
                        <div className="title">
                            <h3>{Brands}</h3>
                        </div>
                        {/* Title end */}
                        {/* brands grid start */}



                        <Row>
                            {brands.map(({ logo, id, machine_name }) => (
                                <Col md={3} sm={4} xs={6}>
                                    <div className="brands-container" key={id}>
                                        <Link
                                            to={`/BrandProducts/${machine_name}/1`}
                                            className="item-brand"
                                            key={id}
                                        >
                                            <img
                                                src={
                                                    apiService.imageLink + logo
                                                }
                                                alt="1"
                                            />
                                        </Link>
                                    </div>
                                </Col>
                            ))}
                        </Row>

                        {/* brands grid end */}
                    </div>
                </Container>
            );
        }

    }
    closeMenu() {
        this.setState({ menuOpen: false });
    }
    toggleMenu() {
        this.setState((state) => ({ menuOpen: !state.menuOpen }));
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(Brands);
