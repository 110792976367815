export const apiEndPoints = {
    auth: {
        login: "basicLogin",
        signUp: "registerUser",
        guestLogin: "generateGuestID",
        resetPassword: "/password/send-reset-code",
        socialLogin: "loginBySocialMedia",
    },
    general: {
        getPlaces: "getPlaces",
        getIconData: "getFavCartCompCounters",
        getSlider: "getSliderData",
        getCategories: "getCategories",
        getCities: "/getCities",
        getAreas: "/getAreas",
        getBrands: "getBrandsData",
        getFooter: "getSettings",
        getPublicPagesData: "getPublicPages ",
        getPublicPagesDataDetails: "getPublicPageDetails",
        search: "getSearchProducts",
        getHomeAds: "getHomeAds",
        getSocialMediaLinks: "getSocialMediaLinks",
        getPaymentMethodsByPlaceID: "getPaymentMethodsByPlaceID",
        getHelpCenter: "getHelpCenter",
        getSingleAds: "getSingleAds",
        getFavoritesIDs: "getFavoritesIDs",
        getComparesIDs: "getComparesIDs",
        getCartIDs: "getCartIDs",
    },
    category: {
        getSingleCategoryProducts: "getSingleCategoryProducts",
        getSingleCategoryInfoAndFilters: "getSingleCategoryInfoAndFilters",
    },
    home: {
        getHomePageData: "/getHomePageData?sub_categories=1&categories=1&recommended_products=1&trending_products=1&brands=1&daily_deals=1",
        getHomeTDRDeals: "getHomeTDRDeals",
        getHomeCategoriesWithProducts: "getHomeCategoriesWithProducts",
        getSingleBrandProducts: "getSingleBrandProducts",
        getCollections: "getCollections",
        getSingleCollectionProducts: "getSingleCollectionProducts",
        visitAdsLink: "visitAdsLink",
    },
    cart: {
        getUserCart: "getCart",
        getUserFavorites: "getFavorites",
        getCheckoutAmounts: "getCheckoutAmounts",
        checkCouponIfValid: "checkCouponIfValid",
    },
    user: {
        checkoutSubmit: "createOrder",
        getUserOrders: "getMyOrders",
        getOrderInvoice: "getMyOrderDetails",
        getUserInformation: "/getUserInformation",
        updateGeneralInfo: "/editUserInformation",
        updateSecurityInfo: "editUserPassword",
    },
    location: {
        getUserLocation: "getUserLocations",
        addLocationSubmit: "/user/addNewLocation",
        deleteUserLocation: "deleteUserLocation",
        addUserLocation: "addUserLocation",
        editUserLocation: "editUserLocation",
    },
    product: {
        addOrRemoveFavorite: "addRemoveFavorites",
        addRemoveCompares: "addRemoveCompares",
        getProductDetails: "getProductDetails",
        addToCart: "addUpdateRemoveCart",
        getUserProductCompared: "getCompares",
        getProductRelated: "getProductRelated",
        getSingleProductReviews: "getProductReviews",
        addProductReview: "addProductReview",
        getProductsFromSameBrand: "getProductsFromSameBrand",
        getProductSoldWith: "getProductSoldWith",
    },
};
