import React, { Component } from "react";
import { Container, Row, Col, InputGroup, FormControl, Dropdown, Pagination } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { userService } from "../../../services/UserService";
import TextInput from "../../blocks/TextInput";
import OrderCard from "./OrderCard";
import PaginatedItems from "../../Pages/Search/PaginationBloack";
import { withRouter } from "react-router-dom";

class Orders extends Component {
    state = {
        StatusCase: this.props.i18n.All,
        color: "",
        orders: [],
        fields: {
            to: "",
            from: "",
            key: "",
            status: "",
        },
        currentPage: 1,
        loader: true,
        meta: {},
    };

    componentDidMount() {
        this.getUserOrders();
    }
    getUserOrders = async (page) => {
        const {
            fields: { from = "", to = "", key = "", status = "" },
            StatusCase,
        } = this.state;

        const { data, success, meta } = await userService.getUserOrders(this.props.match.params.page, {
            from_date: from,
            to_date: to,
            search_key: key,
            status,
        });
        if (!success) return;
        this.setState({
            orders: data,
            pageLoader: false,
            meta: meta,
            loader: false,
        });
    };
    render() {
        const {
            language,
            i18n: { Orders, OrdersTitle2, Returned, BuyDate, From, To, SortBy, search, Pending, Complete, Canceled, ordersEmpty, All, filterBy, Arraived, OnTheWay, Accepted },
        } = this.props;
        const { StatusCase, orders, fields, meta } = this.state;
        return (
            <Container>
                <div className={`orders ${language === "rtl" && "orders-arabic"}`}>
                    {/* Title Start */}
                    <div className="account-page__title">
                        <h3>{Orders}</h3>
                        <h4>{OrdersTitle2}</h4>
                    </div>
                    {/* Title End */}
                    {/* Filter-Bar Start */}
                    <div className="filter-bar">
                        {/* Search Section Start */}
                        <div className="search-section">
                            <TextInput SearchImage placeholder={search} name="key" value={fields.key} onFieldChange={this.onFieldChange} />
                        </div>
                        {/* Search Section End */}
                        {/* Date Section Start */}
                        <div className="date-section">
                            <p className="buy-date">{BuyDate}</p>
                            <InputGroup>
                                <label for="html" className="input-group-text input-group-text1">
                                    {From}
                                </label>
                                <FormControl id="html" type="date" name="from" value={fields.from} aria-label="Username" aria-describedby="basic-addon1" className="input1" onChange={({ target: { name, value } }) => this.onFieldChange(name, value)} />
                            </InputGroup>
                            <InputGroup>
                                <label for="To" className="input-group-text input-group-text2 ">
                                    {To}
                                </label>
                                <FormControl id="To" type="date" name="to" value={fields.to} aria-label="Username" aria-describedby="basic-addon1" className="input2" onChange={({ target: { name, value } }) => this.onFieldChange(name, value)} />
                            </InputGroup>
                        </div>
                        {/* Date Section End */}
                        {/* Select Section Start */}
                        <div className="select-section">
                            <p className="sort-by">{filterBy}</p>
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    <div className="status-case">
                                        {" "}
                                        <p style={StatusCase === OnTheWay ? { color: "#000957" } : StatusCase === Arraived ? { color: "#B983FF" } : StatusCase === Accepted ? { color: "#116530" } : StatusCase === Pending ? { color: "#ff8000" } : StatusCase === Returned ? { color: "red" } : StatusCase === Complete ? { color: "#00FF66" } : StatusCase === All ? { color: "#32358a" } : StatusCase === Canceled ? { color: "gray" } : null} className="status-label">
                                            {" "}
                                            {StatusCase}{" "}
                                        </p>
                                        <i style={StatusCase === OnTheWay ? { color: "#000957" } : StatusCase === Arraived ? { color: "#B983FF" } : StatusCase === Accepted ? { color: "#116530" } : StatusCase === Pending ? { color: "#ff8000" } : StatusCase === Returned ? { color: "red" } : StatusCase === Complete ? { color: "#00FF66" } : StatusCase === Canceled ? { color: "gray" } : StatusCase === All ? { color: "#32358a" } : null} className="fa fa-circle circle-icon"></i>
                                        <i className="fa fa-chevron-down chevron-icon"></i>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        className="All"
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    StatusCase: All,
                                                    loader: true,
                                                    fields: {
                                                        ...fields,
                                                        loader: true,
                                                        status: "",
                                                    },
                                                },
                                                () => this.getUserOrders(this.state.currentPage)
                                            )
                                        }
                                    >
                                        {All} <i className="fa fa-circle circle-icon"></i>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className="pending"
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    StatusCase: Pending,
                                                    loader: true,
                                                    fields: {
                                                        ...fields,
                                                        status: 1,
                                                    },
                                                },
                                                () => this.getUserOrders(this.state.currentPage)
                                            )
                                        }
                                    >
                                        {Pending} <i className="fa fa-circle circle-icon"></i>
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item
                                        className="returned"
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    StatusCase: Returned,
                                                    fields: {
                                                        ...fields,
                                                        status: Returned,
                                                    },
                                                },
                                                () => this.getUserOrders(this.state.currentPage)
                                            )
                                        }
                                    >
                                        {Returned} <i className="fa fa-circle circle-icon"></i>
                                    </Dropdown.Item> */}
                                    <Dropdown.Item
                                        className="complete"
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    StatusCase: Complete,
                                                    loader: true,
                                                    fields: {
                                                        ...fields,
                                                        status: 5,
                                                    },
                                                },
                                                () => this.getUserOrders(this.state.currentPage)
                                            )
                                        }
                                    >
                                        {Complete} <i className="fa fa-circle circle-icon"></i>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className="accepted"
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    StatusCase: Accepted,
                                                    loader: true,
                                                    fields: {
                                                        ...fields,
                                                        status: 2,
                                                    },
                                                },
                                                () => this.getUserOrders(this.state.currentPage)
                                            )
                                        }
                                    >
                                        {Accepted} <i className="fa fa-circle circle-icon"></i>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className="on_the_way"
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    StatusCase: OnTheWay,
                                                    loader: true,
                                                    fields: {
                                                        ...fields,
                                                        status: 3,
                                                    },
                                                },
                                                () => this.getUserOrders(this.state.currentPage)
                                            )
                                        }
                                    >
                                        {OnTheWay} <i className="fa fa-circle circle-icon"></i>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className="arraived"
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    StatusCase: Arraived,
                                                    loader: true,
                                                    fields: {
                                                        ...fields,
                                                        status: 4,
                                                    },
                                                },
                                                () => this.getUserOrders(this.state.currentPage)
                                            )
                                        }
                                    >
                                        {Arraived} <i className="fa fa-circle circle-icon"></i>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className="canceled"
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    StatusCase: Canceled,
                                                    loader: true,
                                                    fields: {
                                                        ...fields,
                                                        status: -2,
                                                    },
                                                },
                                                () => this.getUserOrders(this.state.currentPage)
                                            )
                                        }
                                    >
                                        {Canceled} <i className="fa fa-circle circle-icon"></i>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {/* Select Section Start */}
                    </div>
                    {/* Filter-Bar End */}
                    {/* Order start */}
                    {this.state.loader ? (
                        <>
                            <Skeleton height={200} className="mt-3" />
                            <Skeleton height={200} className="mt-3" />
                            <Skeleton height={200} className="mt-3" />
                            <Skeleton height={200} className="mt-3" />
                            <Skeleton height={200} className="mt-3" />
                        </>
                    ) : orders.length > 0 ? (
                        orders?.map((item, index) => {
                            return <OrderCard key={index} data={item} />;
                        })
                    ) : (
                        <Col md={12}>
                            <div className="empty-products">
                                <img src="/images/Artboard 6.svg" alt="k" />
                                <p> {ordersEmpty} </p>
                            </div>
                        </Col>
                    )}
                    {/* Order End */}
                </div>
                {/** Pagination Start */}
                {/* {meta.last_page > 1 && (
                    <Row>
                        <div className="Pagination">
                            <Pagination>
                                {[...Array(meta?.last_page).keys()].map(
                                    (_, index) => {
                                        return (
                                            <Link
                                                to={`/orders/${index + 1}`}
                                                className={`main-link ${
                                                    this.state.currentPage ===
                                                        index + 1 && "active"
                                                }`}
                                                onClick={() =>
                                                    this.nextPage(index + 1)
                                                }
                                            >
                                                {index + 1}
                                                <Pagination.Item
                                                    // options_values, brands, rate, price_from, price_to, free_shipping, new_arrivals
                                                    className="sub-link"
                                                ></Pagination.Item>
                                            </Link>
                                        );
                                    }
                                )}
                            </Pagination>
                        </div>
                    </Row>
                )} */}

                {/** Pagination Start */}
                {
                    (console.log(this.state.loader),
                    this.state.loader ? null : meta.last_page > 1 ? (
                        <Row>
                            <Col md={12}>
                                <div className="Pagination">
                                    <PaginatedItems items={[...Array(meta?.total).keys()]} itemsPerPage={meta?.per_page} changePage={(page) => this.changePage(page)} />
                                </div>
                            </Col>
                        </Row>
                    ) : null)
                }

                {/** Pagination End */}
            </Container>
        );
    }

    // onFieldChange = ({ target: { name, value } }) => {
    //     const { fields } = this.state;
    //     this.setState({
    //         fields: { ...fields, [name]: value },
    //     });
    // };

    toTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    changePage(page) {
        this.toTop();
        this.props.history.push(`/orders/${page + 1}`);
        // window.location.href = `/Search/${this.props.match.params.key}/${
        //     this.props.match.params.catId
        // }/${page + 1}`;
        // to={`/Search/${this.props.match.params.key}/${this.props.match.params.catId}/${index + 1}`}
        this.setState(
            {
                pageLoader: true,
            },
            () => {
                this.getUserOrders();
            }
        );
    }

    nextPage = (index) => {
        this.setState(
            {
                currentPage: index,
                loader: true,
            },
            () => this.getUserOrders(index)
        );
    };
    onFieldChange = (name, value) => {
        const { fields } = this.state;
        this.setState(
            {
                fields: { ...fields, [name]: value },
            },
            () => {
                this.getUserOrders(this.state.currentPage);
            }
        );
    };
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(withRouter(Orders));
