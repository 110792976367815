import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";

class SubCategories extends Component {
    render() {
        const {
            language,
            subCats,
            i18n: { subCategories, Orders },
        } = this.props;
        console.log("cats sub", subCats);
        return (
            <>
                <div className={`subCategories ${language === "rtl" && "subCategories-arabic"}`}>
                    <div className="subCategories__accordion">
                        <Accordion accordion={true} defaultActiveKey={"1"} expandMode={"multiple"} flush>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>{subCategories}</Accordion.Header>
                                <Accordion.Body>
                                    <ul className="">
                                        {subCats?.map((sub) => {
                                            return (
                                                <a href={"/productsCategory/" + sub.machine_name + "/1"}>
                                                    <li>
                                                        {language === "rtl" ? <i class="fa fa-long-arrow-left ms-2"></i> : <i class="fa fa-long-arrow-right me-2"></i>}

                                                        {sub.name}
                                                    </li>
                                                </a>
                                            );
                                        })}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});

export default connect(mapStateToProps)(SubCategories);
