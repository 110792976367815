import React, { Component } from "react";
import { Carousel, Container } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import { apiService } from "../../../services/ApiService";
import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../../utils/misc";

class HomeCarousel extends Component {
    render() {
        const {
            sliders = [],
            sliderLoader,
            // i18n: { DiscoverNow },
            language,
        } = this.props;
        return (
            <div className="home-carousel">
                {sliderLoader ? (
                    <Skeleton height={tabletScreen ? 100 : 500} />
                ) : sliders.length > 0 && (
                    <Carousel interval={null} controls={sliders?.length < 2 ? false : true} indicators={sliders?.length < 2 ? false : true}>
                        {sliders?.map(({ image, link, button_text, text, title, mobile_image, alignments }, index) => {
                            return (
                                <Carousel.Item
                                    key={index}
                                    onClick={() => {
                                        if (link) {
                                            window.open(link, "_blank");
                                        }
                                    }}
                                >
                                    {/* <Link to={"/"}> */}
                                    <img className="d-block w-100" src={tabletScreen ? apiService.imageLink + mobile_image : apiService.imageLink + image} alt="First slide" />
                                    {/* </Link> */}
                                    <Container>
                                        {/* <Carousel.Caption style={{ left: alignments == "left" ? "15%" : "auto", right: alignments == "right" ? "15%" : "auto" }}> */}

                                        <Carousel.Caption style={language === "rtl" ? (alignments === "left" ? { marginRight: 0, marginLeft: "auto" } : alignments === "right" ? { marginLeft: 0, marginRight: "auto" } : alignments === "center" ? { margin: "auto" } : null) : alignments === "left" ? { marginLeft: 0, marginRight: "auto" } : alignments === "right" ? { marginRight: 0, marginLeft: "auto" } : alignments === "center" ? { margin: "auto" } : null}>
                                            {title && <h3>{title}</h3>}
                                            {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
                                            {button_text && <button className="submit-button">{button_text}</button>}
                                        </Carousel.Caption>
                                    </Container>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(HomeCarousel);
