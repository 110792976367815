import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Global from "../../../language/Global.json";
import { statusTypesColor } from "../../../utils/misc";
function OrderCard({ data }) {
    // const currentUser = useSelector(({ currentUser }) => currentUser);
    const language = useSelector(({ language }) => language);
    const country = useSelector(({ country }) => country);

    const i18n = language.langCode === "ltr" ? Global.en : Global.ar;
    const { OrderNo, Status, Total, DeliveredAt, DeliverTo, Items, ReOrder } = i18n;
    const [StatusCase] = useState(data.status);

    // console.log(data.created_at, "data orders");

    return (
        <div className="order">
            {/* Order first section start */}
            <div className="first">
                <a href={`/invoice/${data?.order_reference}`} className="order__first__number">
                    {OrderNo} #{data?.id}
                </a>
                <h6 className="order__first__date">{moment(data?.created_at).format("yy-MM-DD")}</h6>
            </div>
            {/* Order first section End */}
            {/* Order second section start */}
            <div className="second">
                <div className="second__total-item">
                    {" "}
                    <div className="items">
                        <span>{data?.items_count}</span> <span>{Items}</span>
                    </div>
                    <div>
                        {Total} : {country.currency} {data?.total_amount}
                    </div>
                </div>
                {data?.complete_date_time && (
                    <div className="second__date">
                        {DeliveredAt} :{data?.complete_date_time}
                    </div>
                )}
                <div className="second__deliver-location">
                    <span> {DeliverTo} : </span>
                    <span>{data?.receiver_details?.address}</span>
                </div>
            </div>
            {/* Order second section End */}
            {/* Order third section start */}
            <div className="third">
                <div className="order__third__status">
                    <span> {Status} </span>
                    <span style={{ color: data.statusColor }}>
                        {language.langCode === "ltr" ? data?.statusText : data?.statusText == "Pending" ? "قيد الانتظار" : data?.statusText == "Cacnelled" ? "ملغي" : data?.statusText == "Accepted" ? "تم القبول" : data?.statusText == "On the Way" ? "في الطريق" : data?.statusText == "Arrived" ? "تم التوصيل" : data?.statusText == "Completed" ? "مكتمل" : null}

                        <i className="fa fa-circle"></i>
                    </span>
                </div>

                <Link className="order__third__invoice-reOrder" to={`/invoice/${data?.order_reference}`}>
                    <img className="showInvoice-img" title="invoice" src="/images/invoice.svg" alt="search" />
                </Link>
                {data?.can_reorder && (
                    <button className="submit-button2">
                        <i className="fa fa-undo"></i>
                        {ReOrder}
                    </button>
                )}
            </div>
            {/* Order third section End */}
        </div>
    );
}

export default OrderCard;
