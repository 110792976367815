import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
    name: "language",
    initialState: { lang: "English", isLoading: false, langCode: "ltr" },

    reducers: {
        setLanguage: (language, action) => {
            language.langCode = action.payload.langCode;
            language.lang = action.payload.lang;
        },
    },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
