import React, { useState } from "react";

const AccordionLocation = ({ changeAccordion, selectedLocation, data: { id, nickname, address, latitude, longitude, phone, is_default } }) => {
    const [Check, setCheck] = useState(false);
    return (
        <li key={id}>
            <div className="Locations">
                <div className="LocationInfo">
                    <div className="LocationIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="31.336" height="38.284" viewBox="0 0 31.336 38.284">
                            <g id="Bold-Outline" transform="translate(-2.5 -0.5)">
                                <g id="location" transform="translate(2.5 0.5)">
                                    <path
                                        id="shape"
                                        d="M20.267,38.03a3.3,3.3,0,0,1-4.2,0C7.08,30.613,2.5,23.341,2.5,16.168a15.668,15.668,0,1,1,31.336,0C33.836,23.341,29.256,30.613,20.267,38.03Zm10.27-21.862a12.369,12.369,0,0,0-24.739,0c0,6.011,4.066,12.466,12.369,19.318C26.471,28.634,30.537,22.179,30.537,16.168ZM18.168,21.116a6.6,6.6,0,1,1,6.6-6.6A6.6,6.6,0,0,1,18.168,21.116Zm0-3.3a3.3,3.3,0,1,0-3.3-3.3A3.3,3.3,0,0,0,18.168,17.817Z"
                                        transform="translate(-2.5 -0.5)"
                                        fill={selectedLocation.id == id ? "#32358a" : "#cecece"}
                                    />
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div>
                        <h1 style={{ color: selectedLocation.id == id ? "#32358a" : "#cecece" }}>{nickname}</h1>
                        <p>{address}</p>
                        <div className="LocationUserEmailPhone">
                            {/* <h5>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.823" height="12.945" viewBox="0 0 15.823 12.945">
                                    <g id="Bold-Outline" transform="translate(0)">
                                        <g id="mail">
                                            <path
                                                id="shape"
                                                d="M16.822,7.3q0,.016,0,.033v5.737a2.877,2.877,0,0,1-2.877,2.877H3.877A2.877,2.877,0,0,1,1,13.069V7.332Q1,7.315,1,7.3V5.877A2.877,2.877,0,0,1,3.877,3H13.945a2.877,2.877,0,0,1,2.877,2.877ZM15.384,6.78v-.9a1.438,1.438,0,0,0-1.438-1.438H3.877A1.438,1.438,0,0,0,2.438,5.877v.9L8.7,8.66a.719.719,0,0,0,.413,0Zm0,1.5L9.531,10.038a2.157,2.157,0,0,1-1.24,0L2.438,8.282v4.787a1.438,1.438,0,0,0,1.438,1.438H13.945a1.438,1.438,0,0,0,1.438-1.438Z"
                                                transform="translate(-1 -3)"
                                                fill={Check ? "#32358a" : "#cecece"}
                                            />
                                        </g>
                                    </g>
                                </svg>{" "}
                                &nbsp; moeabahre@gmail.com
                            </h5> */}

                            <h5>
                                <svg xmlns="http://www.w3.org/2000/svg" width="11.973" height="16.462" viewBox="0 0 11.973 16.462">
                                    <g id="Bold-Outline" transform="translate(0 0)">
                                        <g id="phone">
                                            <path
                                                id="shape"
                                                d="M14.476,11.476V3.993a1.5,1.5,0,0,0-1.5-1.5H6.993a1.5,1.5,0,0,0-1.5,1.5v7.483Zm0,1.5H5.5v1.5a1.5,1.5,0,0,0,1.5,1.5h5.986a1.5,1.5,0,0,0,1.5-1.5ZM6.993,1h5.986a2.993,2.993,0,0,1,2.993,2.993V14.469a2.993,2.993,0,0,1-2.993,2.993H6.993A2.993,2.993,0,0,1,4,14.469V3.993A2.993,2.993,0,0,1,6.993,1ZM9.986,15.592a1.122,1.122,0,1,1,1.122-1.122A1.122,1.122,0,0,1,9.986,15.592Z"
                                                transform="translate(-4 -1)"
                                                fill={selectedLocation.id == id ? "#32358a" : "#cecece"}
                                            />
                                        </g>
                                    </g>
                                </svg>{" "}
                                &nbsp; {phone}
                            </h5>
                        </div>
                    </div>
                </div>

                <div className="LocationOnOff">
                    {/** <!--Location On Off  switch --> */}
                    <div className="SwitchButt">
                        <label className="switch">
                            <input
                                checked={selectedLocation.id == id ? true : false}
                                type="checkbox"
                                onClick={() => {
                                    // console.log(Check, id);
                                    // if (Check == id) {
                                    //     setCheck(false);
                                    // } else {
                                    //     setCheck(id);
                                    // }

                                    changeAccordion(id);
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default AccordionLocation;
