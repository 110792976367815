import React, { Component } from "react";
import { Col, InputGroup, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../language/Global.json";
import { locationService } from "../../services/LocationService";
import { displayAlert, getResponseErrors, mapSelectData } from "../../utils/misc";
import LocationPicker from "react-location-picker";
import SelectInput from "../../components/blocks/SelectInput";
import TextInput from "../../components/blocks/TextInput";
import { generalServices } from "../../services/GeneralService";
import Label from "./Label";
class UserAddressesBox extends Component {
    state = {
        openPopup: false,
        addAddress: false,
        placesArray: [],
        /* Default position */
        defaultPosition: {
            lat: 31.963158,
            lng: 35.930359,
        },
        position: {
            lat: 31.963158,
            lng: 35.930359,
        },
        fields: {
            nickname: this.props.data.nickname ?? "",
            address: this.props.data.address ?? "",
            building: this.props.data.building ?? "",
            phone: this.props.data.phone ?? "",
            floor: this.props.data.floor ?? "",
            apartment: this.props.data.appartment ?? "",
            place_id: this.props.data.place.id ?? "",
        },
        errors: {
            nickname: "",
            address: "",
            building: "",
            phone: "",
            floor: "",
            apartment: "",
        },
        show: false,
        place: this.props.data.place,
        showSelect: false,
        placesIds: [],
    };
    // componentDidMount() {
    //     this.getPlaces(`places${0}`, this.props.countryId);
    // }
    // handle location change ////
    handleLocationChange = ({ position, address }) => {
        // Set new location
        this.setState({ position, fields: { ...this.state.fields, address } });
    };
    closeModal = () => {
        this.setState({
            show: false,
            showSelect: false,
            placesArray: [],
        });
    };
    render() {
        const {
            language,

            data,
            i18n: { UpdateLocation, ApartmentNumber, FloorNumber, BuildingNumber, phone, Name, City, DetailedDescription, PlacesText },
        } = this.props;
        const { fields, defaultPosition, errors, placesArray, show, showSelect, placesIds } = this.state;
        return (
            <>
                <div className="addresses__block mb-4">
                    <div className="head">
                        {console.log(this.props.data, "this.props.data")}
                        <h5>{data.nickname}</h5>
                        <img
                            onClick={() => {
                                this.setState({
                                    show: true,
                                    fields: {
                                        ...fields,
                                        nickname: data.nickname ?? "",
                                        address: data.address ?? "",
                                        building: data.building ?? "",
                                        phone: data.phone ?? "",
                                        floor: data.floor ?? "",
                                        apartment: data.appartment ?? "",
                                    },
                                });
                            }}
                            src="/images/pen.png"
                            alt="addAddress"
                        />
                    </div>
                    <div className="body">
                        {/* <div className="email">
                        <img src="/images/mail.png" alt="1" />
                         <h6>moh_ajore@hotmail.com</h6> 
                    </div>*/}
                        <div className="phone">
                            <img src="/images/phone.svg" alt="1" />
                            <h6> {` ${data.phone ? data.phone : ""} `}</h6>
                        </div>
                        <p>
                            {` ${data.address} `}
                            Floor
                            {` ( ${data.floor ? data.floor : ""} ) `}
                        </p>
                        <div className="delete-btn">
                            <img onClick={() => this.deleteLocation(data.id)} src="/images/trashcan.svg" alt="delete" />
                        </div>
                    </div>
                </div>
                {/* // Modal start  */}

                <Modal show={show} size="lg" effect="fadeInUp" onHide={this.closeModal} className={`location-modal ${language === "rtl" && "location-modal-arabic"}`}>
                    <div className="modal-title flex">
                        <h5>{UpdateLocation}</h5>
                        <i onClick={this.closeModal} className="fa fa-times"></i>
                    </div>
                    <div className="address-inputs">
                        <Row>
                            <Col lg={6} md={6} sm={6} className="mb-0">
                                <TextInput name="nickname" label={Name} placeholder={Name} maxLength={12} value={fields.nickname} onFieldChange={this.onFieldChange} validate={errors.nickname} />
                            </Col>
                            <Col lg={6} md={6} sm={6} className="mb-0">
                                <div className={`text-input-block phone-input ${language === "rtl" && "phone-input-arabic"}`}>
                                    <Label label={phone} tooltip={""} isRequired={false} />
                                    <div className="phone-input__content">
                                        <div className="phone-input__content__country--code">
                                            {/* +962 */}
                                            {this.props.country.zipcode ?? "+962"}
                                        </div>
                                        <InputGroup className="phone-input__content__container">
                                            <input
                                                className="phone-input__content__container__field"
                                                type="number"
                                                placeholder={phone}
                                                name="phone"
                                                onInput={({ target }) => (target.value = Math.max(0, parseInt(target.value)).toString().slice(0, this.props.country.digits))}
                                                onChange={({ target: { value } }) =>
                                                    this.setState({
                                                        fields: {
                                                            ...fields,
                                                            phone: value,
                                                        },
                                                    })
                                                }
                                                value={fields.phone}
                                                validate={errors.phone}
                                            />
                                        </InputGroup>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={4} className="mb-0">
                                <TextInput type="number" name="building" label={BuildingNumber} placeholder={BuildingNumber} value={fields.building} onFieldChange={this.onFieldChange} validate={errors.building} />
                            </Col>
                            <Col lg={4} md={4} sm={4} className="mb-0">
                                <TextInput type="number" name="floor" label={FloorNumber} placeholder={FloorNumber} value={fields.floor} onFieldChange={this.onFieldChange} validate={errors.floor} />
                            </Col>
                            <Col lg={4} md={4} sm={4} className="mb-0">
                                <TextInput type="number" name="apartment" label={ApartmentNumber} placeholder={ApartmentNumber} value={fields.apartment} onFieldChange={this.onFieldChange} validate={errors.apartment} />
                            </Col>

                            <Col lg={12} md={12} sm={12} className="mb-0">
                                <TextInput name="address" label={DetailedDescription} placeholder={DetailedDescription} value={fields.address} onFieldChange={this.onFieldChange} validate={errors.address} />
                            </Col>
                            {placesArray.length < 1 && !showSelect && (
                                <Col lg={12} md={12} sm={12} className="mb-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p>{PlacesText}</p>
                                        <button
                                            className="places-delete-btn"
                                            onClick={() => {
                                                this.setState(
                                                    {
                                                        showSelect: true,
                                                        placesArray: [],
                                                    },
                                                    () => {
                                                        this.getPlacesFunction(this.props.countryId, placesArray);
                                                    }
                                                );
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                    <TextInput name={PlacesText} value={data.place?.name} disabled={true} />
                                </Col>
                            )}

                            {placesArray > 0 ||
                                (showSelect &&
                                    placesArray.map((item, index) => {
                                        return (
                                            <Col sm={12} className="mb-4">
                                                <SelectInput
                                                    name={item.filter(({ value }) => value == placesIds[index]).label}
                                                    label={index === 0 ? PlacesText : " "}
                                                    placeholder={City}
                                                    value={item.filter(({ value }) => value == placesIds[index]).value}
                                                    onFieldChange={(name, value) => this.selectChange(value, index)}
                                                    data={item}
                                                    validate={index === placesArray.length - 1 && errors.place_id}
                                                    // isTextArea
                                                />
                                            </Col>
                                        );
                                    }))}

                            <LocationPicker containerElement={<div style={{ height: "100%" }} />} mapElement={<div style={{ height: "400px" }} />} onChange={this.handleLocationChange} defaultPosition={defaultPosition} circleOptions={{ visible: false }} />

                            <Col lg={12} md={12} sm={12} className="locationButton">
                                <button className="submit-button2" onClick={() => this.UpdateLocation(data.id)}>
                                    {UpdateLocation}
                                </button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                {/* // Modal end  */}
            </>
        );
    }
    deleteLocation = async (id) => {
        const { error: errorTag, success: successText, LocationDeletedSuccessfully, AreYouSureToDeleteThisLocation, no, yes } = this.props.i18n;
        displayAlert(errorTag, AreYouSureToDeleteThisLocation, "warning", [no, yes]).then(async (confirm) => {
            if (confirm) {
                const { success } = await locationService.deleteUserLocation({
                    user_location_id: id,
                });
                if (!success) return;

                displayAlert(successText, LocationDeletedSuccessfully, "success");
                this.props.reFetchData();
            }
        });
    };

    // selectChange = (name, value, index) => {
    //     const { fields } = this.state;
    //     if (fields[name]) return;

    //     this.setState({
    //         fields: { ...fields, [name]: value },
    //         errors: {
    //             place_id: "",
    //         },
    //     });
    //     this.getPlaces(`places${index}`, value.value);
    // };

    selectChange = (value, index) => {
        const { fields, placesArray, placesIds } = this.state;

        if (placesArray[index]) {
            let arr = placesArray.slice(0, index + 1);
            this.setState({
                placesArray: arr,
            });
            this.getPlacesFunction(value.value, arr);
        } else {
            this.getPlacesFunction(value.value, placesArray);
        }
        // setValue(fieldData.fieldName, target.value);

        if (placesIds[index]) {
            let arr = placesIds;
            arr[index] = value.value;
            this.setState({
                placesIds: arr,
            });
        } else {
            this.setState({
                placesIds: [...placesIds, value.value],
            });
        }

        this.setState({
            fields: { ...fields, place_id: value.value },
            errors: {
                place_id: "",
            },
        });
    };

    getPlacesFunction = async (id, placesArrayParameter) => {
        const { data, success } = await generalServices.getAllCountries(id);
        if (!success) return;
        if (data?.length > 0 && !this.state.showSelect) {
            this.setState({
                placesArray: [
                    data?.map((item, index) => ({
                        value: item.id,
                        label: item.name,
                    })),
                ],
            });
        }
        if (data?.length > 0 && this.state.showSelect) {
            this.setState({
                placesArray: [
                    ...placesArrayParameter,
                    data?.map((item, index) => ({
                        value: item.id,
                        label: item.name,
                    })),
                ],
            });
        }
    };
    onFieldChange = (name, value) => {
        const { fields } = this.state;
        this.setState({
            fields: { ...fields, [name]: value },
            errors: {
                nickname: "",
                address: "",
                building: "",
                phone: "",
                floor: "",
                apartment: "",
            },
        });
    };
    getPlaces = async (name, id) => {
        const { data, success } = await generalServices.getAllCountries(id);
        if (!success) return;

        data.length > 0 &&
            this.setState({
                placesArray: [...this.state.placesArray, { [name]: mapSelectData(data) }],
            });
    };
    UpdateLocation = async (id) => {
        const { placesArray } = this.state;
        const { fields, position } = this.state;
        const { error: errorTag, success: successText, FillAllFields, LocationEdited } = this.props.i18n;
        const { success, message, errors } = await locationService.editUserLocation({
            user_location_id: id,
            nickname: fields.nickname,
            address: fields.address,
            latitude: position.lat,
            longitude: position.lng,
            building: fields.building,
            phone: fields.phone,
            floor: fields.floor,
            appartment: fields.apartment,
            place_id: fields.place_id,
        });
        if (!success) {
            if (errors) {
                const handelErrors = getResponseErrors(errors);
                this.setState({
                    errors: handelErrors,
                });
                return displayAlert(errorTag, FillAllFields, "error");
            } else {
                return displayAlert("Error", message, "error");
            }
        }
        displayAlert(successText, LocationEdited, "success");
        this.props.reFetchData();
        this.closeModal();

        console.log(fields, "fieldsfields");
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    countryId: country.id,
    country,
});
export default connect(mapStateToProps)(UserAddressesBox);
