import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import UserAddressesBox from "../../blocks/UserAddressesBox";
import Skeleton from "react-loading-skeleton";
import { locationService } from "../../../services/LocationService";
import LocationModal from "../addressesPage/LocationModal";

class Addresses extends Component {
    state = {
        addAddress: false,
        currentState: "",
        userAddressesData: [],
        loader: true,
    };

    componentDidMount() {
        Promise.all([this.getUserLocation()]);
    }

    getUserLocation = async () => {
        const { data, success } = await locationService.getUserLocation();
        if (!success) return;
        this.setState({
            userAddressesData: data,
            loader: false,
        });
    };

    render() {
        const {
            language,
            i18n: { Addresses, AddNew },
        } = this.props;
        const {
            addAddress,
            userAddressesData,
            currentState,
            selectedLocation,
        } = this.state;

        return (
            <Container>
                {/* title start  */}
                <div
                    className={`addresses ${
                        language === "rtl" && "addresses-arabic"
                    }`}
                >
                    <div className="account-page__title">
                        <h3>{Addresses}</h3>
                        <h4>{Addresses}</h4>
                    </div>
                    {/* title start */}
                    <Row>
                        {this.state.loader ? (
                            <>
                                <Col lg={6} md={12}>
                                    <Skeleton height={160} />
                                </Col>
                                <Col lg={6} md={12}>
                                    <Skeleton height={160} />
                                </Col>
                            </>
                        ) : (
                            userAddressesData.map((item) => {
                                return (
                                    <Col lg={6} md={12} key={item.id}>
                                        <UserAddressesBox
                                            data={item}
                                            addAddress={(data) =>
                                                this.setState({
                                                    addAddress: true,
                                                    currentState: "Edit",
                                                    fields: {
                                                        nickname:
                                                            data.nickname ?? "",
                                                        address:
                                                            data.address ?? "",
                                                        building:
                                                            data.building ?? "",
                                                        phone: data.phone ?? "",
                                                        floor: data.floor ?? "",
                                                        apartment:
                                                            data.apartment ??
                                                            "",
                                                    },
                                                    selectedLocation: data,
                                                })
                                            }
                                            loader={this.state.loader}
                                            reFetchData={() =>
                                                this.getUserLocation()
                                            }
                                        />
                                    </Col>
                                );
                            })
                        )}

                        <div className="button-container">
                            <button
                                onClick={() =>
                                    this.setState({
                                        addAddress: true,
                                        currentState: "Add",
                                    })
                                }
                                className="submit-button2"
                            >
                                {AddNew}
                            </button>
                        </div>
                    </Row>
                </div>
                {/* add/edit address modal */}
                <LocationModal
                    show={addAddress}
                    closeModal={this.closeModal}
                    reFetchData={() => this.getUserLocation()}
                    currentState={currentState}
                    data={selectedLocation}
                />

                {/* end add/edit address modal */}
            </Container>
        );
    }
    closeModal = () => {
        this.setState({ addAddress: false });
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    countryId: country.id,
});

export default connect(mapStateToProps, { setLanguage })(Addresses);
