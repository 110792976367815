import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CartProductBlock from "../Cart/CartProductBlock";
import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setUser } from "../../../redux/actions-reducers/user";
import { cartService } from "../../../services/CartService";
import FavouriteProductBlock from "./../Favourite/FavoriteProductBlock";
import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../../utils/misc";
import { generalServices } from "../../../services/GeneralService";
import { apiService } from "../../../services/ApiService";

class Cart extends Component {
    state = {
        fullView: false,
        cart: [],
        favorites: [],
        payment_methods: [],
        itemsPriceBeforeDiscount: 0,
        itemsPriceAfterDiscount: 0,
        itemsCount: [],
        customerData: [],
        loaderPage: true,
        cartAmount: {},
        adsData: {},
        adsLoader: true,
    };

    componentDidMount() {
        Promise.all([this.getUserCart(), this.getCheckoutAmounts(), this.getUserFavorites(), this.getSingleAds()]);
    }

    getSingleAds = async () => {
        const { data, success } = await generalServices.getSingleAds("home_under_categories_block_left_space", 0, this.props.country.id);
        if (!success) return;
        this.setState({
            adsData: data,
            adsLoader: false,
        });
    };

    getUserFavorites = async () => {
        const { data, success } = await cartService.getUserFavorites();
        if (!success) return;
        this.setState({
            favorites: data,
        });
    };

    getUserFavorites = async () => {
        const { data, success } = await cartService.getUserFavorites();
        if (!success) return;
        this.setState({
            favorites: data,
        });
    };

    getUserCart = async () => {
        const { data, success } = await cartService.getUserCart();
        const itemsCount = data?.map(({ qty }) => qty);
        if (!success) return;
        this.setState({
            cart: data,
            itemsCount,
            payment_methods: data.payment_methods,
            loaderPage: false,
        });
    };
    getCheckoutAmounts = async () => {
        const { data, success } = await cartService.getCheckoutAmounts({
            payment_id: 0,
            delivery_company_id: 0,
            // place_id: this.props.country.id,
            place_id: "",
            coupons: [],
        });
        if (!success) return;
        this.setState({
            cartAmount: data,
        });
    };
    getProductsCustomersViewed = async () => {
        // const { data, success } = await productService.getProductsCustomersViewed(0);
        // if (!success) return;
        // this.setState({
        //     customerData: data,
        // });
    };

    render() {
        const { adsData, favorites, cart, cartAmount, adsLoader, loaderPage } = this.state;
        const {
            language,
            i18n: { Wishlist, cartSummary, Cart, Items, SubTotal, Discount, Total, taxesAmount, ProcerdtoCheckout, ContinueSopping, feesAmount, deliveryAmount, VIEWALL, cartEmpty },
        } = this.props;

        return (
            <div className={`Cart ${language === "rtl" && "Cart-Arabic"}`}>
                {/** Title */}
                <div className="CartTitle">
                    <Container>
                        <Row>
                            <Col>
                                <h2>
                                    {Cart}
                                    <h4>
                                        {" "}
                                        {/* {this.totalItemsCount()} {Items} */}
                                        {cart.length}
                                        {Items}
                                    </h4>
                                </h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/** Title End */}

                {/** Cart Products And Amount */}
                <Container>
                    <Row>
                        <Col xl={cart.length > 0 ? 9 : 12} lg={9} md={12} sm={12} xs={12}>
                            {/* ads section start  */}
                            <div className="CartAds">
                                {adsLoader ? (
                                    <Row>
                                        <Col>
                                            <Skeleton height={tabletScreen ? 110 : 200} />
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row className="no-margin">
                                        {/* {adsData?.ad_image && (
                                            <Col xs={12} className=" no-padd">
                                                <a href={adsData?.link}>
                                                    <img src={adsData?.ad_image ? apiService.imageLink + adsData?.ad_image : "/images/placeholder.png"} alt="l" />
                                                </a>
                                            </Col>
                                        )} */}
                                    </Row>
                                )}
                            </div>
                            {/* ads section end  */}
                            {loaderPage ? (
                                <Row>
                                    <Col>
                                        <Skeleton height={tabletScreen ? 100 : 170} />
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    {cart.length < 1 && (
                                        <div className="empty-products">
                                            <img src="/images/Artboard 7.svg" alt="k" />
                                            <p> {cartEmpty} </p>
                                        </div>
                                    )}
                                    {cart?.map((item, index) => {
                                        return <CartProductBlock data={item} key={index} reFetchData={() => Promise.all([this.getUserCart(), this.getCheckoutAmounts(), this.getUserFavorites()])} />;
                                    })}
                                </>
                            )}
                        </Col>
                        {cart.length > 0 && (
                            <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                                <div className="TotalCart">
                                    <h3>{cartSummary}</h3>
                                    {/** Sub Total Start  */}
                                    <div>
                                        <div className="TotalsCart">
                                            <span className="SubTotalSpan">
                                                {SubTotal}
                                                <span className="SubTotalSpanCount">
                                                    {" "}
                                                    {this.totalItemsCount()}
                                                    {/* {cart.length} */}
                                                    {Items}
                                                </span>
                                            </span>

                                            <span className="PriceSpan">
                                                <sup>{this.props.country.currency}</sup>
                                                {cartAmount?.items_amount}
                                            </span>
                                        </div>
                                    </div>
                                    {/** Sub Total End  */}
                                    {/** Items  */}
                                    <ol>
                                        {cart?.map(({ product, qty }, index) => {
                                            return (
                                                <li>
                                                    <p>
                                                        {/* <strong> 0{index + 1}</strong> */}
                                                        {product?.title}
                                                    </p>
                                                    <span> X {qty}</span>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                    {/** Items End */}

                                    {/** Discount Start  */}
                                    {cartAmount?.discount_amount != 0 && (
                                        <div>
                                            <div className="TotalsCart">
                                                <span className="SubTotalSpan"> {Discount}</span>

                                                <span className="PriceSpan">
                                                    <sup>{this.props.country.currency}</sup> {cartAmount?.discount_amount}
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    {/** Discount End  */}

                                    {/** taxes Amount Start  */}
                                    {+cartAmount?.taxes_amount != 0 && (
                                        <div>
                                            <div className="TotalsCart">
                                                <span className="SubTotalSpan"> {taxesAmount}</span>

                                                <span className="PriceSpan">
                                                    <sup>{this.props.country.currency}</sup> {cartAmount?.taxes_amount}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {/** taxes Amount End  */}

                                    {/** feesAmount Start  */}
                                    {cartAmount?.fees_amount != 0 && (
                                        <div>
                                            <div className="TotalsCart">
                                                <span className="SubTotalSpan"> {feesAmount}</span>

                                                <span className="PriceSpan">
                                                    <sup>{this.props.country.currency}</sup> {cartAmount?.fees_amount}
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    {/** feesAmount End  */}
                                    {/** delivary Start  */}
                                    {+cartAmount?.delivery_amount != 0 && (
                                        <div>
                                            <div className="TotalsCart">
                                                <span className="SubTotalSpan">{deliveryAmount}</span>

                                                <span className="PriceSpan">
                                                    <sup>{this.props.country.currency}</sup>
                                                    {cartAmount?.delivery_amount}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {/** delivary End  */}
                                    <hr></hr>
                                    {/** Total Start  */}
                                    <div>
                                        <div className="TotalsCart">
                                            <span className="SubTotalSpan"> {Total}</span> <br />
                                            <br />
                                            <span className="PriceSpan">
                                                <sup>{this.props.country.currency}</sup>
                                                {cartAmount?.total_amount}
                                            </span>
                                        </div>
                                    </div>
                                    {/** Total End  */}

                                    {/** Delivery Start  */}
                                    {/* <div>
                                    <div className="TotalsCart">
                                        <span className="SubTotalSpan"> {Delivery}</span> <br />
                                        <br />
                                        <span className="PriceSpan">
                                            <sup>{this.props.country.currency}</sup> 5.<sup>3 </sup>
                                        </span>
                                    </div>
                                </div> */}
                                    {/** Delivery End  */}

                                    <div className="CheckoutButt">
                                        {/** Checkout Button */}
                                        <a
                                            // onClick={async () => {
                                            //     await this.props.setUser({
                                            //         itemsPriceBeforeDiscount: this.itemsPriceBeforeDiscount(),
                                            //         itemsPriceAfterDiscount: this.itemsPriceAfterDiscount(),
                                            //         discountAmount: this.discountAmount(),
                                            //     });
                                            //     window.location.href = cart.length > 0 ? "/Checkout" : "#";
                                            // this.props.history.push("/Checkout");
                                            // }}
                                            href={cart.length > 0 ? "/Checkout" : "/#"}
                                        >
                                            {ProcerdtoCheckout}
                                        </a>
                                        {/** Checkoout end */}

                                        {/** Checkout Button */}
                                        <a href="/">{ContinueSopping}</a>
                                        {/** Checkoout end */}
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>
                {/** Cart Products And Amount End */}

                {/** Wishlist */}
                {favorites?.length > 0 && (
                    <div className="CartFav">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="categories-block__header">
                                        <h3>{Wishlist}</h3>
                                        <div className="hr" />
                                        <a href="/Favourite" className="view-all">
                                            <span>{VIEWALL}</span>
                                            <img src="/images/arrow-small-right.svg" alt="l" />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {favorites.map((item, index) => {
                                    return (
                                        <Col md={6}>
                                            <FavouriteProductBlock data={item} key={index} wishList reFetchData={() => Promise.all([this.getUserCart(), this.getCheckoutAmounts(), this.getUserFavorites()])} />
                                        </Col>
                                    );
                                })}
                                {/* <CartProductBlock addToCart={MoveToCart} /> */}
                            </Row>
                        </Container>
                    </div>
                )}

                {/** Wishlist */}

                {/** Customers Also Viewed */}
                <div className="CartFav">
                    <Container>
                        <Row>
                            <Col>
                                {/* <HomeCategories data={customerData} title={CustomersAlsoViewed} /> */}
                                <br />
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/** Customers Also Viewed */}
            </div>
        );
    }

    newPrice = (price, discount_percentage, fixed_amount) => {
        let discountAmount = (price * discount_percentage) / 100;
        let priceAfterDiscount = 0;
        if (discountAmount > fixed_amount) {
            priceAfterDiscount = price - fixed_amount;
            return priceAfterDiscount;
        } else {
            priceAfterDiscount = price - discountAmount;
            return priceAfterDiscount;
        }
    };

    // changeQty = (qty, index) => {

    //     const { itemsCount } = this.state;
    //     const newItemCount = itemsCount;
    //     newItemCount[index] = qty;
    //     this.setState({
    //         itemsCount: newItemCount,
    //         qtyChangeIndex: index,
    //     });
    // };
    totalItemsCount = () =>
        this.state.itemsCount.reduce((acc, currentValue) => {
            return currentValue + acc;
        }, 0);

    itemsPriceBeforeDiscount = () => {
        // const itemsPriceBeforeDiscount = this.state.cart.reduce((acc, currentValue, currentIndex) => {
        //     return currentValue?.productOption?.price * this.state.itemsCount[currentIndex] + acc;
        // }, 0);
        // return itemsPriceBeforeDiscount;
    };

    itemsPriceAfterDiscount = () => {
        // const itemsPriceBeforeDiscount = this.state.cart.reduce((acc, currentValue, currentIndex) => {
        //     return (currentValue?.product_details?.campaign ? this.newPrice(currentValue?.productOption?.price, currentValue?.product_details?.campaign.discount_percentage, currentValue?.product_details?.campaign.fixed_amount) : currentValue?.productOption?.price) * this.state.itemsCount[currentIndex] + acc;
        // }, 0);
        // return itemsPriceBeforeDiscount;
    };

    discountAmount = () => {
        // return this.itemsPriceBeforeDiscount() - this.itemsPriceAfterDiscount();
    };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
    country,
});

export default connect(mapStateToProps, { setUser })(Cart);
