import swal from "sweetalert";

export const handleResponse = ({ success, ...rest }) => ({ success, ...rest });

export const displayAlert = (title, text, icon, className = "", timer, buttons) => swal({ title, text, icon, buttons, className, timer });

export const mapSelectData = (data) =>
    Array.isArray(data)
        ? data.map(({ name: label, id: value, ...rest }) => ({
              value,
              label,
              ...rest,
          }))
        : [];

export const getResponseErrors = (responseErrors) => {
    const errors = Object.entries(responseErrors).map(([fieldName, fieldValue]) => [fieldName, fieldValue[0]]);

    return Object.fromEntries(errors);
};

export const tabletScreen = window.innerWidth < 768;
export const phoneScreen = window.innerWidth < 576;

export const statusTypes = {
    0: "Closed",
    1: "Pending",
    2: "Pickup",
    3: "In Process",
    4: "Delivered",
    5: "Completed",
    "-2": "Canceled",
};

export const statusTypesColor = {
    0: "red",
    1: "#ff8000",
    2: "#000",
    3: "#000",
    4: "Delivered",
    5: "#00FF66",
    "-2": "gray",
};
