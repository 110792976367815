import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService";

class HomeService {
    get guestId() {
        return localStorage.getItem("guestId");
    }

    async getHomePageData() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.home.getHomePageData)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getHomeTDRDeals(type) {
        try {
            const data = await apiService
                .authenticated()
                .get(`${apiEndPoints.home.getHomeTDRDeals}?type=${type}&guest_id=${apiService.accessToken === 0 ? this.guestId : 0}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getSingleBrandProducts(page, machine_name) {
        try {
            const data = await apiService
                .authenticated()
                .post(`${apiEndPoints.home.getSingleBrandProducts}?page=${page}`, machine_name)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getCollections(position) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.home.getCollections}?position=${position}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getSingleCollectionProducts(machine_name) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.home.getSingleCollectionProducts}?machine_name=${machine_name}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getHomeCategoriesWithProducts() {
        try {
            const data = await apiService
                .unauthenticated()
                .get(apiEndPoints.home.getHomeCategoriesWithProducts)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async visitAdsLink(id) {
        try {
            const data = await apiService
                .unauthenticated()
                .post(`${apiEndPoints.home.visitAdsLink}`, { ad_id: id })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
}

export const homeService = new HomeService();
