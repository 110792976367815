import React, { Component } from "react";
import { connect } from "react-redux";
import Global from "../../language/Global.json";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { apiService } from "../../services/ApiService";

class AccountPageList extends Component {
    state = {
        menuOpen: false,
    };

    render() {
        const pathname = window.location.pathname.toLowerCase();
        const {
            language,
            i18n: { Profile: profileText, MyOrders, Addresses, TrackYourOrder },
        } = this.props;
        const { menuOpen } = this.state;
        return (
            <div className="mobile-push-menu">
                <Menu
                    right={language === "rtl" && "account-page-arabic"}
                    isOpen={this.state.menuOpen}
                    customBurgerIcon={<i className="fa  fa-cog" />}
                    customCrossIcon={<i className="fa fa-times croosIconMenu" />}
                    onOpen={() =>
                        this.setState({
                            menuOpen: !this.state.menuOpen,
                        })
                    }
                >
                    <ul>
                        {apiService.accessToken !== 0 && (
                            <li
                                onClick={() => {
                                    this.setState({
                                        menuOpen: false,
                                    });
                                }}
                                style={{
                                    background: `${pathname === "/profile" && "white"}`,
                                }}
                            >
                                <Link to="/profile">
                                    <img
                                        style={{
                                            filter: `${pathname === "/profile" && "none"}`,
                                        }}
                                        src="/images/user.svg"
                                        alt="l"
                                    />
                                    <h6
                                        style={{
                                            color: `${pathname === "/profile" && "#32358a"}`,
                                        }}
                                        href="/profile"
                                    >
                                        {profileText}
                                    </h6>
                                </Link>
                            </li>
                        )}

                        <li
                            style={{
                                background: `${(pathname === "/orders/:page" || pathname === "/invoice") && "white"}`,
                            }}
                            onClick={() => {
                                this.setState({
                                    menuOpen: false,
                                });
                            }}
                        >
                            <Link to="/orders/1">
                                <img
                                    style={{
                                        filter: `${(pathname === "/orders/:page" || pathname === "/invoice") && "none"}`,
                                    }}
                                    src="/images/sent.svg"
                                    alt="l"
                                />
                                <h6
                                    style={{
                                        color: `${(pathname === "/orders/:page" || pathname === "/invoice") && "#32358a"}`,
                                    }}
                                >
                                    {MyOrders}
                                </h6>
                            </Link>
                        </li>
                        {apiService.accessToken !== 0 && (
                            <li
                                style={{
                                    background: `${pathname === "/addresses" && "white"}`,
                                }}
                                onClick={() => {
                                    this.setState({
                                        menuOpen: false,
                                    });
                                }}
                            >
                                <Link to="/addresses">
                                    <img
                                        style={{
                                            filter: `${pathname === "/addresses" && "none"}`,
                                        }}
                                        src="/images/map-book.svg"
                                        // locationDropdown
                                        alt="l"
                                    />
                                    <h6
                                        style={{
                                            color: `${pathname === "/addresses" && "#32358a"}`,
                                        }}
                                    >
                                        {Addresses}
                                    </h6>
                                </Link>
                            </li>
                        )}

                        {/* <li
              style={{
                background: `${pathname === "/returns" && "white"}`,
              }}
            >
              <Link to="/returns">
                <img
                  style={{
                    filter: `${pathname === "/returns" && "none"}`,
                  }}
                  src="/images/return.svg"
                  alt="l"
                />
                <h6
                  style={{
                    color: `${pathname === "/returns" && "#32358a"}`,
                  }}
                >
                  {Returns}
                </h6>
              </Link>
            </li> */}
                        <li
                            onClick={() => {
                                this.setState({
                                    menuOpen: false,
                                });
                            }}
                            style={{
                                background: `${pathname === "/trackyourorder/:page" && "white"}`,
                            }}
                        >
                            <Link to="/trackYourOrder/1">
                                <img
                                    style={{
                                        filter: `${pathname === "/trackyourorder/:page" && "none"}`,
                                    }}
                                    src="/images/delivery-truck.svg"
                                    alt="l"
                                />
                                <h6
                                    style={{
                                        color: `${pathname === "/trackyourorder/:page" && "#32358a"}`,
                                    }}
                                >
                                    {TrackYourOrder}
                                </h6>
                            </Link>
                        </li>
                    </ul>
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps)(AccountPageList);
